import axios, { AxiosError } from 'axios';

import LocalDataService from '@/service/LocalDataServices';
import { when } from '@/service/Switch';
import Swal from 'sweetalert2';
import querystring from 'query-string';
import { auth0Module } from '@/store/entityModules/auth0';

const baseUrl = process.env.VUE_APP_API_URL;

const client = axios.create();

interface Response {
  status: number;
  config: {
    url: string;
  };
  data: {
    [key: string]: string;
  };
}

interface ErrorResponse {
  status: number;
  config: {
    url: string;
  };
  data: {
    data: {
      message: string;
    };
    status: string;
  };
}


export enum ErrorType {
  UNAUTHORIZED = 'unauthorized',
  FIRSTLOGIN = 'firstLogin',
  NO_VERIFIED = 'no_verified',
  NO_TOKEN = 'no_token',
}

client.interceptors.response.use(
  async (response) => {
    try {
      const r: Response = response as Response;
      // return response
      if (r.config.url) {
        if (r.status !== 200) {
          throw Promise.reject(response);
        }
        if (r.data.error) {
          if (r.data.error === 'E003') {
            throw ErrorType.FIRSTLOGIN;
          } else if (r.data.error === 'E180') {
            Swal.fire('', `Eメール認証がされていません。\nEメールを確認してください。`, 'warning');
            throw ErrorType.NO_VERIFIED;
          } else {
            // Swal.fire(r.data.error, r.data.message, 'warning');
            const errorCode = String(r.data.error);
            const message = getErrorMessage(errorCode);
            Swal.fire(errorCode, `${message}`, 'warning');
            throw Promise.reject(response);
          }
        }
        return response;
      } else {
        if (r.data.status !== '0') { // status != 0の時は、何かしらのエラー
          throw Promise.reject(response);
        }
        return response;
      }
    } catch (error) {
      throw error;
    }
  },
  async (error) => {
    try {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        if (axiosError.response.status === 500) {
          const status = String(axiosError.response.status);
          Swal.fire(status, 'このメールアドレスは使用できません', 'error');
          throw Promise.reject(error);
        }
        if (axiosError.response.status >= 500) { // 認証エラーの時は、サインイン画面に飛ばす
          const status = String(axiosError.response.status);
          // const message = String(axiosError.response.data.message);
          Swal.fire(status, 'Internal Server Error', 'error');
          throw Promise.reject(error);
        }
        if (axiosError.response.status === 401) {
          if (process.env) {
            // window.location.href = '/logout';
            throw Promise.reject(ErrorType.UNAUTHORIZED);
          }
        }
        if (axiosError.response.status === 400) { // 認証エラーの時は、サインイン画面に飛ばす
          const status = String(axiosError.response.status);
          const message = getErrorMessage(axiosError.response.data.error);
          Swal.fire(status, message, 'error');
          throw Promise.reject(error);
        }
      }
    } catch (error) {
      throw Promise.reject(error);
    }
  },
);

const getErrorMessage = (errorCode): string => {
  return when(errorCode)
    .on((v) => v === 'E000', () => 'システムに予期せぬエラーが発生しました。しばらく時間をおいてアクセス頂くかカスタマーサポートにお問い合わせください。')
    .on((v) => v === 'E001', () => 'API仕様書で指定されているのとパラメータが違います。')
    .on((v) => v === 'E002', () => 'トークンが失効しています。')
    .on((v) => v === 'E003', () => 'ユーザーが存在しません')
    .on((v) => v === 'E004', () => 'ユーザーが既に存在しています。')
    .on((v) => v === 'E005', () => '指定されたObjectTypeが不正です。')
    .on((v) => v === 'E006', () => '朝食の記録が存在しません。')
    .on((v) => v === 'E007', () => '朝食の記録が既に存在します。')
    .on((v) => v === 'E008', () => '夕食の記録が存在しません。')
    .on((v) => v === 'E009', () => '夕食の記録が既に存在します。')
    .on((v) => v === 'E010', () => '排便の記録が存在しません。')
    .on((v) => v === 'E011', () => 'ミルクの記録が存在しません。')
    .on((v) => v === 'E012', () => '睡眠の記録が存在しません。')
    .on((v) => v === 'E013', () => '体温の記録が存在しません。')
    .on((v) => v === 'E014', () => '保育日誌の記録が存在しません。')
    .on((v) => v === 'E015', () => '保育日誌の記録が既に存在します。')
    .on((v) => v === 'E016', () => '喫食の記録が存在しません。')
    .on((v) => v === 'E017', () => '喫食の記録が既に存在します。')
    .on((v) => v === 'E018', () => '欠席期間に重複があるため登録できません。')
    .on((v) => v === 'E019', () => '選択された理由が不正です。')
    .on((v) => v === 'E020', () => '選択された送迎者が不正です。')
    .on((v) => v === 'E021', () => 'メッセージが見つかりません。')
    .on((v) => v === 'E022', () => '送迎のデータが見つかりません。')
    .on((v) => v === 'E023', () => 'クラスが見つかりません。')
    .on((v) => v === 'E024', () => '指定の園児が見つかりません。お子様のID・パスワードを確認し、再度入力をお願いします。')
    .on((v) => v === 'E025', () => '指定した園児は既に自分の家族として登録されています。')
    .on((v) => v === 'E026', () => '指定した家族が見つかりません。')
    .on((v) => v === 'E027', () => 'ユーザーが指定された家族に所属していません。')
    .on((v) => v === 'E028', () => '指定されたユーザーは既に指定された家族に招待されています')
    .on((v) => v === 'E029', () => '指定のフォーマットが見つかりません')
    .on((v) => v === 'E030', () => '園児IDが指定されていません')
    .on((v) => v === 'E031', () => '指定されたフィード情報が存在しません。')
    .on((v) => v === 'E032', () => '指定されたフィードの詳細情報が存在しません。')
    .on((v) => v === 'E033', () => '指定されたレポートはユーザーのものではありません。')
    .on((v) => v === 'E034', () => '指定された日付の登園・降園の記録が既に存在します。')
    .on((v) => v === 'E035', () => '登園・降園の記録が存在しません。')
    .on((v) => v === 'E036', () => '指定された日付の登園記録がありません。')
    .on((v) => v === 'E037', () => '指定された日付の登園記録が既に存在します。')
    .on((v) => v === 'E038', () => '指定された日付の降園記録が既に存在します。')
    .on((v) => v === 'E039', () => '指定された登園・降園の記録はこの園児のものではありません。')
    .on((v) => v === 'E040', () => '不正な保育園が指定されました。')
    .on((v) => v === 'E041', () => '平仮名の判定に失敗しました。恐れ入りますがお問い合わせください。')
    .on((v) => v === 'E042', () => '内部的に使用されているIDが不正です。')
    .on((v) => v === 'E043', () => 'uqidが不正です。')
    .on((v) => v === 'E044', () => 'CheckSumが不正です。')
    .on((v) => v === 'E045', () => 'ユーザーは指定した保育園に所属していません。')
    .on((v) => v === 'E046', () => '管理者ユーザーのみが許可された操作です。')
    .on((v) => v === 'E047', () => '日誌の承認ステータスが不正です。')
    .on((v) => v === 'E048', () => '園日誌の記録が存在しません。')
    .on((v) => v === 'E049', () => '指定した献立は指定した保育園の物ではありません。')
    .on((v) => v === 'E050', () => 'ユーザーは指定した保育園に所属していません。')
    .on((v) => v === 'E051', () => '管理者ユーザーのみが許可された操作です。')
    .on((v) => v === 'E052', () => '日誌の承認ステータスが不正です。')
    .on((v) => v === 'E053', () => '園日誌の記録が存在しません。')
    .on((v) => v === 'E054', () => 'このユーザーには承認権限がありません。')
    .on((v) => v === 'E055', () => 'この日誌の申請者はこのユーザーではありません。')
    .on((v) => v === 'E056', () => 'この日誌は申請中ではありません。')
    .on((v) => v === 'E057', () => '同一の権限で、他のユーザーが既に承認しています。')
    .on((v) => v === 'E058', () => '日誌の初回作成時の状態は、申請・下書きのいずれかである必要があります。')
    .on((v) => v === 'E059', () => 'おたよりの記録が存在しません。')
    .on((v) => v === 'E060', () => 'このユーザーは指定した献立の保育園に所属していません。')
    .on((v) => v === 'E061', () => '指定した献立に対する検食記録は既に存在しています。')
    .on((v) => v === 'E062', () => '指定された検食記録は存在しません。')
    .on((v) => v === 'E063', () => '指定した年間行事は存在しません。')
    .on((v) => v === 'E064', () => '指定したクラスIDは指定した保育園の物ではありません。')
    .on((v) => v === 'E065', () => 'socketIdの形式が不正です。恐れ入りますがシステム管理者にお問い合わせください。')
    .on((v) => v === 'E066', () => '午睡チェックパターンが存在しません。')
    .on((v) => v === 'E067', () => '担当スタッフが重複しています。')
    .on((v) => v === 'E068', () => '指定したスタッフは指定した保育園に所属していません。')
    .on((v) => v === 'E069', () => 'この午睡チェックパターンはこの園のものではありません。')
    .on((v) => v === 'E070', () => 'この園児はこの保育園に所属していません。')
    .on((v) => v === 'E071', () => '午睡チェックが存在しません。')
    .on((v) => v === 'E072', () => 'この午睡チェックはこの園のものではありません。')
    .on((v) => v === 'E073', () => 'この午睡チェックの温度・湿度データが存在しません。')
    .on((v) => v === 'E074', () => 'この午睡チェック記録は申請中ではありません。')
    .on((v) => v === 'E075', () => 'すでにこの午睡チェック記録に存在する園児は指定できません。')
    .on((v) => v === 'E076', () => 'この午睡チェック記録に存在しない園児は指定できません。')
    .on((v) => v === 'E077', () => 'すでにこの午睡チェック記録に存在するスタッフは指定できません。')
    .on((v) => v === 'E078', () => '終了もしくは申請中の状態の午睡チェック記録は編集できません。')
    .on((v) => v === 'E079', () => '指定した午睡チェックの個別記録は存在しません。')
    .on((v) => v === 'E080', () => '指定した午睡チェックの個別記録はこの園のものではありません。')
    .on((v) => v === 'E081', () => '指定した午睡チェックの個別記録は既に存在しています。')
    .on((v) => v === 'E082', () => '指定した午睡チェックに指定した児童は含まれておりません。')
    .on((v) => v === 'E083', () => '行事の時間指定が不正です。')
    .on((v) => v === 'E084', () => 'リレーションがありません。')
    .on((v) => v === 'E085', () => '午睡センサーが存在しません。')
    .on((v) => v === 'E086', () => 'コメントは1000文字までで入力してください。')
    .on((v) => v === 'E087', () => '欠席連絡の確認に失敗しました。')
    .on((v) => v === 'E088', () => '送迎変更の確認に失敗しました。')
    .on((v) => v === 'E089', () => '行事の期間指定が不正です。')
    .on((v) => v === 'E090', () => '保護者が見つかりません。')
    .on((v) => v === 'E091', () => '同名のアレルギー食品が既に登録されています。')
    .on((v) => v === 'E092', () => '指定したアレルギー食品は指定した保育園の物ではありません。')
    .on((v) => v === 'E093', () => 'スタッフアカウント役職名がすでに存在しています。')
    .on((v) => v === 'E094', () => 'スタッフアカウント役職が存在しません。')
    .on((v) => v === 'E095', () => 'スタッフアカウント情報がすでに存在しています。')
    .on((v) => v === 'E096', () => 'スタッフアカウント情報が存在しません。')
    .on((v) => v === 'E097', () => 'このスタッフアカウント役職は現在使用されているため、削除できません。')
    .on((v) => v === 'E098', () => '開始日が終了日より後に設定されています。')
    .on((v) => v === 'E099', () => '指定された日時は過去の日付です。')
    .on((v) => v === 'E100', () => 'Excelファイルの生成に失敗しました。恐れ入りますが、システム管理者にお問い合わせください。')
    .on((v) => v === 'E101', () => '午睡センサーが起動されていません。')
    .on((v) => v === 'E102', () => '午睡センサーの記録が見つかりません。')
    .on((v) => v === 'E103', () => '欠席登録が見つかりません。')
    .on((v) => v === 'E104', () => '他人が作成したチャットは閲覧のみ可能です。')
    .on((v) => v === 'E105', () => 'ご指定のメールアドレスは他のユーザーに利用されているため変更できません。')
    .on((v) => v === 'E106', () => '添付ファイルが見つかりません。')
    .on((v) => v === 'E107', () => '認定・保育料の記録が存在しません。')
    .on((v) => v === 'E108', () => '認定期間の終了日を入力してください。')
    .on((v) => v === 'E109', () => '認定・保育料の期間指定が不正です。他の期間との重複がないか確認してください。')
    .on((v) => v === 'E110', () => '対象の勘定科目が見つかりません。')
    .on((v) => v === 'E111', () => '勘定科目が小口現金出納帳で利用されているため削除できません。')
    .on((v) => v === 'E112', () => '小口現金出納帳の詳細レコードが見つかりません。')
    .on((v) => v === 'E113', () => '同名のアレルギー項目がすでに登録されています。')
    .on((v) => v === 'E114', () => 'アレルギー項目が見つかりません。')
    .on((v) => v === 'E115', () => '指定されたアレルギー項目は削除・更新することができません。')
    .on((v) => v === 'E116', () => '献立種別が見つかりません。')
    .on((v) => v === 'E117', () => '献立が見つかりません。')
    .on((v) => v === 'E118', () => '検食が見つかりません。')
    .on((v) => v === 'E119', () => '検食がすでに作成済みです。')
    .on((v) => v === 'E120', () => '延長保育パターンが見つかりません。')
    .on((v) => v === 'E121', () => '時間枠に被りがあるため保存できません。')
    .on((v) => v === 'E122', () => '時間枠が見つかりません。')
    .on((v) => v === 'E123', () => '打刻設定が存在しません。')
    .on((v) => v === 'E124', () => '指定した延長保育日誌は指定した保育園のものではありません。')
    .on((v) => v === 'E125', () => '指定した園提供サービスはマスターに登録されていません。')
    .on((v) => v === 'E126', () => '指定した園提供サービスは既に登録されています。')
    .on((v) => v === 'E127', () => '指定した園提供サービスは利用登録されていません。')
    .on((v) => v === 'E128', () => '月額制の施設提供サービスの場合は、1日 日付を利用日として設定してください。')
    .on((v) => v === 'E129', () => '指定した施設提供サービスは利用されているため削除できません。')
    .on((v) => v === 'E130', () => '施設提供サービスのserviceTypeの形式が不正です。')
    .on((v) => v === 'E131', () => '施設提供サービスのpaymentMonthTypeの形式が不正です。')
    .on((v) => v === 'E132', () => '施設提供サービスのaccountTypeの形式が不正です。')
    .on((v) => v === 'E133', () => 'ユーザーは指定した運営企業に所属していません。')
    .on((v) => v === 'E134', () => '運営会社が見つかりません。')
    .on((v) => v === 'E135', () => '指定された請求書が存在しません。')
    .on((v) => v === 'E136', () => '請求書のステータスが不正です。')
    .on((v) => v === 'E137', () => '請求書がまだ確定されていません。')
    .on((v) => v === 'E138', () => 'ファイルの読み込みに失敗しました。CSVファイルの形式を確認してください。')
    .on((v) => v === 'E139', () => 'CSVファイルの園児コードが不正です。')
    .on((v) => v === 'E140', () => 'CSVファイルの売掛金の値が不正です。')
    .on((v) => v === 'E141', () => 'CSVファイルの売掛金の値が不正です。')
    .on((v) => v === 'E142', () => '売掛金残高のインポート状況が存在しません。')
    .on((v) => v === 'E143', () => 'この月の売上情報が送信されているため, この登園・降園記録は編集できません。')
    .on((v) => v === 'E144', () => '確定ステータスの小口現金出納帳は変更できません。')
    .on((v) => v === 'E145', () => 'この月の売上情報が確定しているため, 対象期間を含む保育料・認定情報は編集できません。')
    .on((v) => v === 'E146', () => '指定されたその他サービス詳細が存在しません。')
    .on((v) => v === 'E147', () => '保育料・認定情報が登録されていない園児がいます。設定してから送信してください。')
    .on((v) => v === 'E148', () => '運営企業のサービス設定が存在しません。')
    .on((v) => v === 'E149', () => '権限不足のため小口現金出納帳の確定ステータスを解除することができません。')
    .on((v) => v === 'E150', () => '下書きステータスではないため操作することができません。')
    .on((v) => v === 'E151', () => 'この月の売上情報が確定しているため, 対象期間を含む園提供サービスは編集できません。')
    .on((v) => v === 'E152', () => '園の振込口座の設定が見つかりません。')
    .on((v) => v === 'E153', () => '既に退園済みの園児です。')
    .on((v) => v === 'E154', () => '指定のディスカッションが見つかりません。')
    .on((v) => v === 'E155', () => 'GMOのカードのトークンが不正です。')
    .on((v) => v === 'E156', () => 'GMOのカード登録でエラーが発生しました。	')
    .on((v) => v === 'E157', () => 'GMOのカード情報が見つかりません。	')
    .on((v) => v === 'E158', () => 'GMOのカード削除でエラーが発生しました。	')
    .otherwise(() => 'システムに予期せぬエラーが発生しました。しばらく時間をおいてアクセス頂くかカスタマーサポートにお問い合わせください。');
};

// REST
export default class ApiClient {
  public static async get(
    endpoint: string,
    params: { [key: string]: any } | null,
    isAuth = true,
  ) {
    const token = await auth0Module.getAuth0Token();
    if (isAuth && !token) {
      throw ErrorType.NO_TOKEN;
    }
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    let headers: { [key: string]: string };
    if (isAuth) {
      headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': xApiKey ? xApiKey : '',
        'Authorization': `Bearer ${token}`,
      };
    } else {
      headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': xApiKey ? xApiKey : '',
      };
    }
    if (params) {
      try {
        const response = await client.get(
          `${baseUrl}${endpoint}?${querystring.stringify(params, { skipNull: true })}`, {
          headers,
          data: {},
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const response = await client.get(
          `${baseUrl}${endpoint}`, {
          headers,
          data: {},
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }

  public static async post(
    endpoint: string,
    params: { [key: string]: any },
    isAuth = true,
  ) {
    const token = await auth0Module.getAuth0Token();
    if (isAuth && !token) {
      throw ErrorType.NO_TOKEN;
    }
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    let headers: { [key: string]: string };
    if (isAuth) {
      headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': xApiKey ? xApiKey : '',
        'Authorization': `Bearer ${token}`,
      };
    } else {
      headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': xApiKey ? xApiKey : '',
      };
    }
    return client.post(
      `${baseUrl}${endpoint}`,
      JSON.stringify(params),
      { headers },
    ).then((response) => response.data);
  }

  public static async put(
    endpoint: string,
    params: { [key: string]: any } | null,
  ) {
    const token = await auth0Module.getAuth0Token();
    if (!token) {
      throw ErrorType.NO_TOKEN;
    }
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': xApiKey ? xApiKey : '',
      'Authorization': `Bearer ${token}`,
    };
    if (params) {
      return client.put(
        `${baseUrl}${endpoint}`,
        JSON.stringify(params),
        { headers },
      ).then((response) => response.data);
    } else {
      return client.put(
        `${baseUrl}${endpoint}`,
        { headers },
      ).then((response) => response.data);
    }
  }

  public static async delete(
    endpoint: string,
    params: { [key: string]: any } | null,
  ) {
    const token = await auth0Module.getAuth0Token();
    if (!token) {
      throw ErrorType.NO_TOKEN;
    }
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': xApiKey ? xApiKey : '',
      'Authorization': `Bearer ${token}`,
    };
    if (params) {
      return client.delete(
        `${baseUrl}${endpoint}?${querystring.stringify(params, { skipNull: true })}`, {
        headers,
        data: {},
      },
      ).then((response) => response.data);
    } else {
      return client.delete(
        `${baseUrl}${endpoint}`, {
        headers,
        data: {},
      },
      ).then((response) => response.data);
    }
  }

}
