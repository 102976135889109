import ApiClient from '@/api/ApiClient';
import {
  HomeResourceResponse,
  HomePreSignedUrlResponse,
} from '@API/src/component/home/resource/types';
import {
  PostResourceRequest,
} from '@/model/Resource';
import {
  isTypeHomeResourceResponse,
  isTypeHomePreSignedUrlResponse,
} from '@/model/TypeGuard/Resource';

export default class ApiResource {

  public static async postResource(
    request: {
      imageType: string;
      filename: string;
      childId: string | null;
    },
  ): Promise<HomeResourceResponse> {
    try {
      const response = await ApiClient.post(`/resource`, request);
      if (isTypeHomeResourceResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postResourceDownload(
    request: {
      objectKey: string;
    },
  ): Promise<HomePreSignedUrlResponse> {
    try {
      const response = await ApiClient.post(`/resource/download-url`, request);
      if (isTypeHomePreSignedUrlResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
