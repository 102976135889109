import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiMe from '@/api/ApiMe';

import {
  CreateParentMeRequest,
  UpdateParentMeRequest,
  ParentMeResponse,
} from '@API/src/component/home/me/types';
import {
  PostMeRequest,
  PutMeRequest,
  DelMeRequest,
} from '@/model/Me';

export interface MeState {
  me: ParentMeResponse | null;
}

@Module({ dynamic: true, store, name: 'me', namespaced: true })
class MeModule extends VuexModule implements MeState {

  public me: ParentMeResponse | null = null;

  @Action({ rawError: true })
  public async fetchMe() {
    try {
      const response: ParentMeResponse = await ApiMe.fetchMe();
      this.setMe(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setMe(me: ParentMeResponse) {
    this.me = me;
  }

  @Action({ rawError: true })
  public async postMe(request: PostMeRequest) {
    try {
      const response: ParentMeResponse = await ApiMe.postMe(request);
      this.setMe(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putMe(request: PutMeRequest) {
    try {
      const response: ParentMeResponse = await ApiMe.putMe(request);
      this.setMe(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async delMe(request: DelMeRequest) {
    try {
      const response: ParentMeResponse = await ApiMe.delMe(request);
      this.setMe(response);
      return true;
    } catch (error) {
      throw error;
    }
  }
}

export const meModule = getModule(MeModule);
