
import Vue from 'vue';

import { familyModule } from '@/store/entityModules/family';

// model
import {
  SearchChildrenResponse,
  ParentResponse,
  ChildResponse,
  FamilyResponse,
} from '@API/src/component/home/family/types';

export default Vue.extend({

  computed: {
    children(): ChildResponse[] {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
  },

});
