import {
  ChatParentChoiceResponse,
  ChatChoiceResponse,
  HomeChatResponse,
  HomeChatListResponse,
  HomeAbsenceResponse,
  HomePickupResponse,
  HomeGroupChatResponse,
  HomeGroupChatListResponse,
} from '@API/src/component/home/chat/types';

// TypeGuard
export const isChatParentChoiceResponse = (
  arg: any,
): arg is ChatParentChoiceResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.id === 'number' &&
  typeof arg.value === 'string';

export const isChatChoiceResponse = (
  arg: any,
): arg is ChatChoiceResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.parentIds === 'object' &&
  Array.isArray(arg.parentIds);

export const isHomeChatResponse = (
  arg: any,
): arg is HomeChatResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.chatId === 'number' &&
  typeof arg.date === 'string';

export const isHomeChatsResponse = (
  arg: any,
): arg is HomeChatResponse[] => {
  return arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg) &&
  (arg.length === 0 || isHomeChatResponse(arg[0]));
};

export const isHomeChatListResponse = (
  arg: any,
): arg is HomeChatListResponse => {
  return arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.chats) &&
  (arg.chats.length === 0 || isHomeChatResponse(arg.chats[0])) &&
  typeof arg.unReadCount === 'object' &&
  typeof arg.unReadCount.beforeTheDate === 'number' &&
  typeof arg.unReadCount.date === 'number' &&
  typeof arg.unReadCount.afterTheDate === 'number';
};

export const isHomeAbsenceResponse = (
  arg: any,
): arg is HomeAbsenceResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.childId === 'number' &&
  typeof arg.date === 'string';

export const isHomeAbsencesResponse = (
  arg: any,
): arg is HomeAbsenceResponse[] => {
  return arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg) &&
  (arg.length === 0 || isHomeAbsenceResponse(arg[0]));
};

export const isHomePickupResponse = (
  arg: any,
): arg is HomePickupResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.childId === 'number' &&
  typeof arg.date === 'string';

export const isHomePickupsResponse = (
  arg: any,
): arg is HomePickupResponse[] => {
  return arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg) &&
  (arg.length === 0 || isHomePickupResponse(arg[0]));
};

export const isHomeGroupChatResponse = (
  arg: any,
): arg is HomeGroupChatResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.chatId === 'number' &&
  typeof arg.body === 'string' &&
  typeof arg.hasAttachment === 'boolean' &&
  typeof arg.createdAt === 'string';

export const isHomeGroupChatListResponse = (
  arg: any,
): arg is HomeGroupChatListResponse => {
  return arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.items) &&
  (arg.items.length === 0 || isHomeGroupChatResponse(arg.items[0]));
};
