import ApiClient from '@/api/ApiClient';
import {
  FetchBreakfastsRequest,
  PostBreakfastRequest,
  DeleteBreakfastRequest,
  FetchBreakfastRequest,
  PutBreakfastRequest,
  FetchDefecationsRequest,
  PostDefecationRequest,
  DeleteDefecationRequest,
  FetchDefecationRequest,
  PutDefecationRequest,
  FetchDinnersRequest,
  PostDinnerRequest,
  DeleteDinnerRequest,
  FetchDinnerRequest,
  PutDinnerRequest,
  FetchMilksRequest,
  PostMilkRequest,
  DeleteMilkRequest,
  FetchMilkRequest,
  PutMilkRequest,
  FetchReportRequest,
  FetchReportListRequest,
  FetchSleepsRequest,
  PostSleepRequest,
  DeleteSleepRequest,
  FetchSleepRequest,
  PutSleepRequest,
  FetchTemperaturesRequest,
  PostTemperatureRequest,
  DeleteTemperatureRequest,
  FetchTemperatureRequest,
  PutTemperatureRequest,
  PutReportRequest,
} from '@/model/DailyReport';
import { DailyReportResponse } from '@API/src/component/home/dailyReport/types';
import { DefecationResponse } from '@API/src/component/home/defecation/types';
import { TemperatureResponse } from '@API/src/component/home/temperature/types';
import { MilkResponse } from '@API/src/component/home/milk/types';
import { MilkChoicesResponse } from '@API/src/component/nursery/milk/types';
import { SleepResponse } from '@API/src/component/home/sleep/types';
import { DinnerResponse } from '@API/src/component/home/dinner/types';
import { BreakfastResponse } from '@API/src/component/home/breakfast/types';
import {
  isTypeDailyReportResponse,
  isTypeDailyReportsResponse,
  isTypeDefecationResponse,
  isTypeDefecationsResponse,
  isTypeTemperatureResponse,
  isTypeTemperaturesResponse,
  isTypeMilkResponse,
  isTypeMilksResponse,
  isTypeMilkChoicesResponse,
  isTypeSleepResponse,
  isTypeSleepsResponse,
  isTypeDinnerResponse,
  isTypeDinnersResponse,
  isTypeBreakfastResponse,
  isTypeBreakfastsResponse,
} from '@/model/TypeGuard/DailyReport';

export default class ApiDailyReport {

  public static async fetchReport(
    request: FetchReportRequest,
  ): Promise<DailyReportResponse> {
    try {
      const response = await ApiClient.get(`/report`, request);
      if (isTypeDailyReportResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchReportList(
    request: FetchReportListRequest,
  ): Promise<DailyReportResponse[]> {
    try {
      const response = await ApiClient.get(`/report/list`, request);
      if (isTypeDailyReportsResponse(response.reports)) {
        return response.reports;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  // breakfast
  public static async fetchBreakfasts(
    request: FetchBreakfastsRequest,
  ): Promise<BreakfastResponse[]> {
    try {
      const response = await ApiClient.get(`/breakfast`, request);
      if (isTypeBreakfastsResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async postBreakfast(
    request: PostBreakfastRequest,
  ): Promise<BreakfastResponse> {
    try {
      const response = await ApiClient.post(`/breakfast`, request);
      if (isTypeBreakfastResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async deleteBreakfast(
    request: DeleteBreakfastRequest,
  ): Promise<BreakfastResponse> {
    try {
      const response = await ApiClient.delete(`/breakfast/${request.breakfastId}`, request);
      if (response.message === 'ok') {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async fetchBreakfast(
    request: FetchBreakfastRequest,
  ): Promise<BreakfastResponse> {
    try {
      const response = await ApiClient.get(`/breakfast/${request.breakfastId}`, request);
      if (isTypeBreakfastResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async putBreakfast(
    request: PutBreakfastRequest,
  ): Promise<BreakfastResponse> {
    try {
      const response = await ApiClient.put(`/breakfast/${request.breakfastId}`, request);
      if (isTypeBreakfastResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }


  // defecation
  public static async fetchDefecations(
    request: FetchDefecationsRequest,
  ): Promise<DefecationResponse[]> {
    try {
      const response = await ApiClient.get(`/defecation`, request);
      if (isTypeDefecationsResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async postDefecation(
    request: PostDefecationRequest,
  ): Promise<DefecationResponse> {
    try {
      const response = await ApiClient.post(`/defecation`, request);
      if (isTypeDefecationResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async deleteDefecation(
    request: DeleteDefecationRequest,
  ): Promise<DefecationResponse> {
    try {
      const response = await ApiClient.delete(`/defecation/${request.defecationId}`, request);
      if (response.message === 'ok') {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async fetchDefecation(
    request: FetchDefecationRequest,
  ): Promise<DefecationResponse> {
    try {
      const response = await ApiClient.get(`/defecation/${request.defecationId}`, request);
      if (isTypeDefecationResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async putDefecation(
    request: PutDefecationRequest,
  ): Promise<DefecationResponse> {
    try {
      const response = await ApiClient.put(`/defecation/${request.defecationId}`, request);
      if (isTypeDefecationResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }


  // dinner
  public static async fetchDinners(
    request: FetchDinnersRequest,
  ): Promise<DinnerResponse[]> {
    try {
      const response = await ApiClient.get(`/defecation`, request);
      if (isTypeDinnersResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async postDinner(
    request: PostDinnerRequest,
  ): Promise<DinnerResponse> {
    try {
      const response = await ApiClient.post(`/dinner`, request);
      if (isTypeDinnerResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async deleteDinner(
    request: DeleteDinnerRequest,
  ): Promise<DinnerResponse> {
    try {
      const response = await ApiClient.delete(`/dinner/${request.dinnerId}`, request);
      if (response.message === 'ok') {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async fetchDinner(
    request: FetchDinnerRequest,
  ): Promise<DinnerResponse> {
    try {
      const response = await ApiClient.get(`/dinner/${request.dinnerId}`, request);
      if (isTypeDinnerResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async putDinner(
    request: PutDinnerRequest,
  ): Promise<DinnerResponse> {
    try {
      const response = await ApiClient.put(`/dinner/${request.dinnerId}`, request);
      if (isTypeDinnerResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }


  // milk
  public static async fetchMilks(
    request: FetchMilksRequest,
  ): Promise<MilkResponse[]> {
    try {
      const response = await ApiClient.get(`/milk`, request);
      if (isTypeMilksResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async postMilk(
    request: PostMilkRequest,
  ): Promise<MilkResponse> {
    try {
      const response = await ApiClient.post(`/milk`, request);
      if (isTypeMilkResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async fetchMilkChoices(): Promise<MilkChoicesResponse> {
    try {
      const response = await ApiClient.get(`/milk/choices`, null);
      if (isTypeMilkChoicesResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async deleteMilk(
    request: DeleteMilkRequest,
  ): Promise<MilkResponse> {
    try {
      const response = await ApiClient.delete(`/milk/${request.milkId}`, request);
      if (response.message === 'ok') {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async fetchMilk(
    request: FetchMilkRequest,
  ): Promise<MilkResponse> {
    try {
      const response = await ApiClient.get(`/milk/${request.milkId}`, request);
      if (isTypeMilkResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async putMilk(
    request: PutMilkRequest,
  ): Promise<MilkResponse> {
    try {
      const response = await ApiClient.put(`/milk/${request.milkId}`, request);
      if (isTypeMilkResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }


  // sleep
  public static async fetchSleeps(
    request: FetchSleepsRequest,
  ): Promise<SleepResponse[]> {
    try {
      const response = await ApiClient.get(`/sleep`, request);
      if (isTypeSleepsResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async postSleep(
    request: PostSleepRequest,
  ): Promise<SleepResponse> {
    try {
      const response = await ApiClient.post(`/sleep`, request);
      if (isTypeSleepResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async deleteSleep(
    request: DeleteSleepRequest,
  ): Promise<SleepResponse> {
    try {
      const response = await ApiClient.delete(`/sleep/${request.sleepId}`, request);
      if (response.message === 'ok') {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async fetchSleep(
    request: FetchSleepRequest,
  ): Promise<SleepResponse> {
    try {
      const response = await ApiClient.get(`/sleep/${request.sleepId}`, request);
      if (isTypeSleepResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async putSleep(
    request: PutSleepRequest,
  ): Promise<SleepResponse> {
    try {
      const response = await ApiClient.put(`/sleep/${request.sleepId}`, request);
      if (isTypeSleepResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }


  // temperature
  public static async fetchTemperatures(
    request: FetchTemperaturesRequest,
  ): Promise<TemperatureResponse[]> {
    try {
      const response = await ApiClient.get(`/temperature`, request);
      if (isTypeTemperaturesResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async postTemperature(
    request: PostTemperatureRequest,
  ): Promise<TemperatureResponse> {
    try {
      const response = await ApiClient.post(`/temperature`, request);
      if (isTypeTemperatureResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async deleteTemperature(
    request: DeleteTemperatureRequest,
  ): Promise<TemperatureResponse> {
    try {
      const response = await ApiClient.delete(`/temperature/${request.temperatureId}`, request);
      if (response.message === 'ok') {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async fetchTemperature(
    request: FetchTemperatureRequest,
  ): Promise<TemperatureResponse> {
    try {
      const response = await ApiClient.get(`/temperature/${request.temperatureId}`, request);
      if (isTypeTemperatureResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
  public static async putTemperature(
    request: PutTemperatureRequest,
  ): Promise<TemperatureResponse> {
    try {
      const response = await ApiClient.put(`/temperature/${request.temperatureId}`, request);
      if (isTypeTemperatureResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async putReport(
    request: PutReportRequest,
  ): Promise<DailyReportResponse> {
    try {
      const response = await ApiClient.put(`/report`, request);
      if (isTypeDailyReportResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
