import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        primary: {
          base: '#EE6090',
          lighten1: '#538AF8',
        },
        secondary: {
          base: '#5D6B70',
          lighten1: '#5C6B70',
        },
        accent: '#8c9eff',
        error: {
          base: '#FF3F3A',
          lighten1: '#FFE1E0',
        },
      },
    },
  },
});
