
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import { chatModule } from '@/store/entityModules/chat';

// model
import { VForm } from '@/model/Vuetify/VForm';
import { PostHomeAbsenceRequest } from '@/model/Chat';

enum Reason {
  fever = 'fever', // 発熱
  personal = 'personal', // 家庭の事情
  infection = 'infection',
  others = 'others', // その他（コメントの入力が必要）
}

enum Infection {
  infStrep = 'infStrep', // 溶連菌感染症
  infMycoplasma = 'infMycoplasma', // マイコプラズマ肺炎
  infHFMD = 'infHFMD', // 手足口病
  infSlappedCheek = 'infSlappedCheek', // 伝染性紅斑（りんご病）
  infStomach = 'infStomach', // ウイルス性胃腸炎（ノロ、ロタ、アデノ等）
  infHerpangina = 'infHerpangina', // ヘルパンギーナ
  infRS = 'infRS', // RSウィルス感染症
  infZoster = 'infZoster', // 帯状疱疹
  infRoseola = 'infRoseola', // 突発性発しん
  infImpetigo = 'infImpetigo', // 伝染性膿痂疹（とびひ）
  covid19 = 'covid19', // 新型コロナウィルス'
}

interface DataType {
  dates: string[];
  startedAt: string;
  endedAt: string;
  reason: string;
  comment: string | null;
  selectedReason: string;
  infection: string;
  fetchLoading: boolean;
  isDatePicker: boolean;
  btnLoading: boolean;
}

export default Vue.extend({
  name: 'AbsenceModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).post_absence_form;
    },
    allowMinDate(): string {
      return dayjs().format('YYYY-MM-DD');
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    reasonOptions() {
      return [
        {
          text: '発熱',
          value: 'fever',
        },
        {
          text: '家庭の事情',
          value: 'personal',
        },
        {
          text: '感染症',
          value: 'infection',
        },
        {
          text: 'その他（コメントの入力が必要）',
          value: 'others',
        },
      ];
    },
    infectionOptions() {
      return [
        {
          text: '溶連菌感染症',
          value: 'infStrep',
        },
        {
          text: 'マイコプラズマ肺炎',
          value: 'infMycoplasma',
        },
        {
          text: '手足口病',
          value: 'infHFMD',
        },
        {
          text: '伝染性紅斑（りんご病）',
          value: 'infSlappedCheek',
        },
        {
          text: 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）',
          value: 'infStomach',
        },
        {
          text: 'ヘルパンギーナ',
          value: 'infHerpangina',
        },
        {
          text: 'RSウィルス感染症',
          value: 'infRS',
        },
        {
          text: '帯状疱疹',
          value: 'infZoster',
        },
        {
          text: '突発性発しん',
          value: 'infRoseola',
        },
        {
          text: '伝染性膿痂疹（とびひ）',
          value: 'infImpetigo',
        },
        {
          text: '新型コロナウィルス',
          value: 'covid19',
        },
      ];
    },
  },

  watch: {
    isOpen: {
      async handler(val: boolean) {
        if (val) {
          this.dates = [];
          this.startedAt = '';
          this.endedAt = '';
          this.reason = '';
          this.comment = null;
        }
      },
      deep: true,
    },
  },

  data: (): DataType => ({
    dates: [],
    startedAt: '',
    endedAt: '',
    reason: '',
    comment: '',
    selectedReason: '',
    infection: '',
    fetchLoading: false,
    isDatePicker: false,
    btnLoading: false,
  }),

  methods: {
    close() {
      this.$emit('close');
    },
    async clickPost() {
      if (this.form.validate()) {
        if (this.dates.length === 1) {
          this.startedAt = this.dates[0];
          this.endedAt = this.dates[0];
        } else {
          const d1 = dayjs(this.dates[0]);
          const d2 = dayjs(this.dates[1]);
          if (d1.isAfter(d2)) {
            this.startedAt = this.dates[1];
            this.endedAt = this.dates[0];
          } else {
            this.startedAt = this.dates[0];
            this.endedAt = this.dates[1];
          }
        }
        if (this.selectedReason === 'infection') {
          this.reason = this.infection;
          this.comment = null;
        } else if (this.selectedReason === 'others') {
          this.reason = this.selectedReason;
        } else {
          this.reason = this.selectedReason;
          this.comment = null;
        }
        this.btnLoading = true;
        try {
          await chatModule.postHomeAbsence({
            childId: this.childId,
            startedAt: this.startedAt,
            endedAt: this.endedAt,
            reason: this.reason,
            comment: this.comment,
          });
          this.$emit('posted');
          this.$emit('close');
          this.btnLoading = false;
        } catch (error) {
          this.$emit('close');
          this.btnLoading = false;
        }
      }
    },
  },

  async mounted() {},
});
