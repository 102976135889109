
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import Swal from 'sweetalert2';
import { Toast } from '@/service/SweetAlert';
import rules from '@/service/ValidRules';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '../store/entityModules/family';
import { childrenModule } from '@/store/entityModules/children';
import { resourceModule } from '@/store/entityModules/resource';

import { VForm } from '@/model/Vuetify/VForm';
import { ChildInfoFormat } from 'chaild-entity/src/component/nursery/child/types';

interface DataType {
  childId: string;
  imageFile: File | null;
  pickedImg: string;
  isDrag: string;
  firstName: string;
  lastName: string;
  firstNameKana: string | null;
  lastNameKana: string | null;
  imageUrl: string | null;
  btnLoading: boolean;
}

export default Vue.extend({

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).put_children_form;
    },
  },

  watch: {
    isBirthPicker(val) {
      setTimeout(() => ((this.$refs as any).picker.activePicker = 'YEAR'));
    },
  },

  data: (): DataType =>({
    childId: '',
    imageFile: null,
    pickedImg: '',
    isDrag: '',
    firstName: '',
    lastName: '',
    firstNameKana: null,
    lastNameKana: null,
    imageUrl: null,
    btnLoading: false,
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting/family', () => {});
    },
    onImagePicked(uploadImageUrl: File | null) {
      this.imageFile = uploadImageUrl;
    },
    pickFile() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.click();
    },
    checkDrag(event: any, key: string, status: boolean) {
      if (status && event.dataTransfer.types === 'text/plain') {
        return false;
      }
      this.isDrag = status ? key : '';
    },
    async onDrop(event: any, key = '', image = {}) {
      this.isDrag = '';
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      if (fileList.length === 0) {
        return false;
      }
      const file = fileList[0];
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        this.pickedImg = fr.result as string;
      });
      const response = await resourceModule.postResource({
        imageType: 'childProfile',
        contentType: file.contentType,
        file,
        childId: this.childId,
      });
      if (response) {
        const url = response.split('?')[0];
        this.imageUrl = url;
        this.imageFile = file;
      }
    },
    async putChildren() {
      if (this.form.validate()) {
        try {
          this.btnLoading = true;
          const response = await childrenModule.putChildren({
            childId: this.childId,
            firstName: this.firstName,
            lastName: this.lastName,
            firstNameKana: this.firstNameKana,
            lastNameKana: this.lastNameKana,
            imageUrl: this.imageUrl,
          });
          Toast.fire({
            icon: 'success',
            title: '保存が完了しました',
          });
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
        }
      }
    },
  },

  async mounted() {
    if (typeof this.$route.query.childId === 'string') {
      this.childId = String(this.$route.query.childId);
      if (!familyModule.family && meModule.me) {
        familyModule.fetchFamilyById({
          familyId: meModule.me.families[0].familyId,
        });
      }
      if (familyModule.family) {
        familyModule.family.children.map((c) => {
          if (c.childId === Number(this.childId)) {
            this.firstName = c.firstName;
            this.lastName = c.lastName;
            this.firstNameKana = c.firstNameKana;
            this.lastNameKana = c.lastNameKana;
            this.imageUrl = c.imageUrl;
            if (c.imageUrl) {
              this.pickedImg = c.imageUrl;
            }
          }
        });
      }
    }
  },

});
