
import Vue from 'vue';
import { VueLoading } from 'vue-loading-template';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';
import { paymentModule } from '@/store/entityModules/payment';

interface DataType {
  loading: boolean;
}

export default Vue.extend({
  name: 'PaymentSelect',

  components: {
    VueLoading,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    payments() {
      return paymentModule.payments;
    },
    family() {
      return familyModule.family;
    },
    children() {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
  },

  methods: {
    routerBack() {
      this.$router.replace('/setting/various', () => {});
    },
    to(childId: string, childName: string) {
      this.$router.push({
        path: '/payment',
        name: 'Payment',
        query: {
          childId,
          childName,
        },
      });
    },
    getPaymentCategoryStr(category: string) {
      switch (category) {
        case 'card':
          return 'クレジットカード';
          break;

        case 'withdrawal':
          return '口座引き落とし';
          break;

        case 'invoice':
          return '振り込み';
          break;

        default:
          break;
      }
    },
    async fetchPaymentMethod() {
      if (this.family) {
        const familyId = this.family.familyId;
        this.loading = true;
        await paymentModule.fetchPaymentMethod({ familyId });
        this.loading = false;
      } else {
        this.routerBack();
      }
    },
  },

  mounted() {
    if (!this.family) {
      this.routerBack();
    }
    this.fetchPaymentMethod();
  },
});
