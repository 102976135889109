
import Vue from 'vue';
import JwtDecode from 'jwt-decode';

// store
import { auth0Module } from '@/store/entityModules/auth0';
import { meModule } from '@/store/entityModules/me';

// model
import { Authorizer } from '@API/src/common/entity/aws/authorize';
import { PostMeRequest } from '@/model/Me';
import LocalDataService from '../service/LocalDataServices';
import { ErrorType } from '@/api/ApiClient';

interface DataType {
  birthDate: string | null;
  isBirthPicker: boolean;
  imageFile: File | null;
  pickedImg: string;
  isDrag: string;
  tel1: string;
  tel2: string;
  tel3: string;
  postMeRequest: PostMeRequest;
}

export default Vue.extend({
  data: (): DataType => ({
    birthDate: null,
    isBirthPicker: false,
    imageFile: null,
    pickedImg: '',
    isDrag: '',
    tel1: '',
    tel2: '',
    tel3: '',
    postMeRequest: {
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      imageUrl: null,
      birth: null,
      bloodType: null,
      tel: null,
    },
  }),

  computed: {
    // birthPicker (): any {
    //
    //   return (this.$refs as any).birthPickerMenu
    // },
  },

  watch: {
    isBirthPicker(val) {
      setTimeout(() => ((this.$refs as any).picker.activePicker = 'YEAR'));
    },
  },

  methods: {
    onImagePicked(uploadImageUrl: File | null) {
      this.imageFile = uploadImageUrl;
    },
    pickFile() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.click();
    },
    checkDrag(event: any, key: string, status: boolean) {
      if (status && event.dataTransfer.types === 'text/plain') {
        return false;
      }
      this.isDrag = status ? key : '';
    },
    onDrop(event: any, key = '', image = {}) {
      this.isDrag = '';
      const fileList = event.target.files
        ? event.target.files
        : event.dataTransfer.files;
      if (fileList.length === 0) {
        return false;
      }
      const file = fileList[0];
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        this.pickedImg = fr.result as string;
      });
      this.imageFile = file;
    },
    saveBirthDate(date) {
      (this.$refs as any).birthPickerMenu.save(date);
    },
    async postMe() {
      try {
        const response = await meModule.postMe(this.postMeRequest);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
});
