
import Vue from 'vue';

import { familyModule } from '@/store/entityModules/family';

export default Vue.extend({

  computed: {
    children() {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
  },

  data: () => ({
    items: [
      {
        title: '基本情報',
        badgeNum: 0,
        to: 'ChildrenBasic',
        path: '/children/basic',
      },
      {
        title: '生活状況',
        badgeNum: 0,
        to: 'ChildrenCondition',
        path: '/children/condition',
      },
      {
        title: '健康発育状況',
        badgeNum: 0,
        to: 'ChildrenGrowth',
        path: '/children/growth',
      },
    ],
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting', () => {});
    },
    to(path: string, name: string, childId: string, childName: string) {
      this.$router.push({ path, name, query: {
        childId,
        childName,
      } });
    },
  },

});
