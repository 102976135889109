
import Vue from 'vue';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';

export default Vue.extend({

  computed: {
    children() {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
  },

  methods: {
    routerBack() {
      this.$router.replace('/setting', () => {});
    },
    to(childId: string, childName: string) {
      this.$router.push({ path: '/invoice', name: 'Invoice', query: {
        childId,
        childName,
      } });
    },
  },
});
