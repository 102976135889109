import {
  HomeResourceResponse,
  HomePreSignedUrlResponse,
} from '@API/src/component/home/resource/types';

export const isTypeHomeResourceResponse = (
  arg: any,
): arg is HomeResourceResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.url === 'string';

export const isTypeHomePreSignedUrlResponse = (
  arg: any,
): arg is HomePreSignedUrlResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.preSignedUrl === 'string' &&
  typeof arg.preSignedDownloadUrl === 'string';
