import ApiClient from '@/api/ApiClient';

import {
  SearchChildrenResponse,
  ParentResponse,
  ChildResponse,
  FamilyResponse,
} from '@API/src/component/home/family/types';
import {
  FetchChildrenSearchRequest,
  PostFamilyRequest,
  FetchFamilyByIdRequest,
  PostFamilyByIdRequest,
  PutFamilyByIdRequest,
  PostFamilyChildRequest,
} from '@/model/Family';
import {
  isTypeSearchChildrenResponse,
  isTypeParentResponse,
  isTypeChildResponse,
  isTypeFamilyResponse,
} from '@/model/TypeGuard/Family';

export default class ApiFamily {

  public static async fetchChildrenSearch(request: FetchChildrenSearchRequest) {
    try {
      const response = await ApiClient.get(`/children/search`, request);
      if (isTypeSearchChildrenResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postFamily(request: PostFamilyRequest) {
    try {
      const response = await ApiClient.post(`/family`, request);
      if (isTypeFamilyResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchFamilyById(request: FetchFamilyByIdRequest) {
    try {
      const response = await ApiClient.get(`/family/${request.familyId}`, null);
      if (isTypeFamilyResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postFamilyById(request: PostFamilyByIdRequest) {
    try {
      const response = await ApiClient.post(`/family/${request.familyId}`, request);
      if (isTypeFamilyResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async putFamilyById(request: PutFamilyByIdRequest) {
    try {
      const response = await ApiClient.put(`/family/${request.familyId}`, request);
      if (isTypeFamilyResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postFamilyChildRequest(request: PostFamilyChildRequest) {
    try {
      const response = await ApiClient.post(`/family/${request.familyId}/child`, request);
      if (isTypeFamilyResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
