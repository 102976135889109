
import Vue from 'vue';
import Swal from 'sweetalert2';
import rules from '@/service/ValidRules';

import { familyModule } from '@/store/entityModules/family';
import { paymentModule } from '@/store/entityModules/payment';

import { VForm } from '@/model/Vuetify/VForm';
import dayjs from 'dayjs';

interface Window {
  Multipayment?: any;
  gmoPaymentCallback?: any;
}

declare var window: Window;

interface DataType {
  childId: number;
  childName: string;
  fetchLoading: boolean;
  btnLoading: boolean;
  cardno: string;
  expireYear: string;
  expireMonth: string;
  securitycode: string;
  holdername: string;
  tokennumber: string;
}

export default Vue.extend({
  data: (): DataType => ({
    childId: NaN,
    childName: '',
    fetchLoading: false,
    btnLoading: false,
    cardno: '',
    expireYear: '',
    expireMonth: '',
    securitycode: '',
    holdername: '',
    tokennumber: '1',
  }),

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).post_absence_form;
    },
    family() {
      return familyModule.family;
    },
  },

  methods: {
    yearOptions() {
      const options: string[] = [];
      for (let i = 0; i < 12; i++) {
        const year = dayjs().add(i, 'year').format('YYYY');
        options.push(year);
      }
      return options;
    },
    monthOptions() {
      const options: string[] = [];
      for (let i = 1; i <= 12; i++) {
        options.push(String(i));
      }
      return options;
    },
    routerBack() {
      // this.$router.replace('/payment', () => {});
      this.$router.replace({
        path: '/payment',
        name: 'Payment',
        query: {
          childId: String(this.childId),
          childName: this.childName,
        },
      });
    },
    toDoubleDigits(month: string) {
      if (month.length === 1) {
        return `0${month}`;
      }
      return month;
    },
    async postPaymentMethodCardRequest() {
      this.btnLoading = true;
      const gmoKey = process.env.VUE_APP_GMO_KEY ?? '';
      window.Multipayment.init(gmoKey);
      window.gmoPaymentCallback = this.callback;
      window.Multipayment.getToken(
        {
          cardno: this.cardno,
          expire: `${this.expireYear}${this.toDoubleDigits(this.expireMonth)}`,
          securitycode: this.securitycode,
          holdername: this.holdername,
          tokennumber: this.tokennumber,
        },
        'gmoPaymentCallback',
      );
    },
    async callback(response: any) {
      try {
        if (response.resultCode !== '000') {
          Swal.fire({
            icon: 'error',
            title: 'カード情報に誤りがあります',
          }).then(() => {
            this.btnLoading = false;
          });
          return;
        }
        const token = response.tokenObject.token[0];
        if (this.family) {
          const familyId = this.family.familyId;
          const postResponse = await paymentModule.postPaymentCardMethod({
            familyId,
            token,
          });
          if (postResponse) {
            Swal.fire({
              icon: 'success',
              title: '保存が完了しました',
            }).then((r) => {
              this.routerBack();
            });
            this.btnLoading = false;
          } else {
            this.btnLoading = false;
          }
        }
      } catch (e) {
        this.btnLoading = false;
      }
    },
  },

  async mounted() {
    const gmoPayment = document.createElement('script');
    // gmoPayment.setAttribute('src', 'https://static.mul-pay.jp/ext/js/token.js')
    const gmoSrc = process.env.VUE_APP_GMO_SRC ?? '';
    gmoPayment.setAttribute('src', gmoSrc);
    // gmoPayment.setAttribute('src', 'https://p01.mul-pay.jp/ext/js/token.js')
    document.head.appendChild(gmoPayment);
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'number') {
      this.childId = this.$route.query.childId;
      // this.fetchGrowth();
    } else if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
      // this.fetchGrowth();
    } else {
      this.routerBack();
    }

    if (!this.family) {
      this.routerBack();
    }
  },
});
