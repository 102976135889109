import {
  GrowthResponse,
} from '@API/src/component/home/growth/types';
import {
  NurseryGrowthPercentileResponse,
} from '@API/src/component/nursery/growth/types';

export const isTypeGrowthResponse = (
  arg: any,
): arg is GrowthResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.year === 'number' &&
  typeof arg.month === 'number' &&
  typeof arg.createdAt === 'string';

export const isTypeGrowthsResponse = (
  arg: any,
): arg is GrowthResponse[] =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeGrowthResponse(arg[0]));

export const isTypeGrowthPercentileResponse = (
  arg: any,
): arg is NurseryGrowthPercentileResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.month === 'number' &&
  typeof arg.percentile === 'number' &&
  typeof arg.sex === 'string' &&
  typeof arg.valueType === 'string' &&
  typeof arg.value === 'number';

export const isTypeGrowthPercentilesResponse = (
  arg: any,
): arg is NurseryGrowthPercentileResponse[] =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeGrowthPercentileResponse(arg[0]));
