
import Vue from 'vue';

import { VueLoading } from 'vue-loading-template';

import { auth0Module } from '@/store/entityModules/auth0';

// model
import { PostFamilyRequest } from '@/model/Family';

export default Vue.extend({
  methods: {
    async reSignIn() {
      await auth0Module.signinProcess();
    },
    logout() {
      auth0Module.logout();
    },
  },

  mounted() {},
});
