import {
  SearchChildrenResponse,
  ParentResponse,
  ChildResponse,
  FamilyResponse,
} from '@API/src/component/home/family/types';

export const isTypeSearchChildrenResponse = (
  arg: any,
): arg is SearchChildrenResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.firstName === 'string' &&
  typeof arg.lastName === 'string';

export const isTypeParentResponse = (
  arg: any,
): arg is ParentResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.parentId === 'number' &&
  typeof arg.firstName === 'string' &&
  typeof arg.lastName === 'string' &&
  typeof arg.isLoginUser === 'boolean';

export const isTypeChildResponse = (
  arg: any,
): arg is ChildResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.childId === 'number' &&
  typeof arg.firstName === 'string' &&
  typeof arg.lastName === 'string' &&
  typeof arg.nurseryName === 'string';

export const isTypeFamilyResponse = (
  arg: any,
): arg is FamilyResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.familyId === 'number';
