import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiChildren from '@/api/ApiChildren';

import { familyModule } from '@/store/entityModules/family';

import {
  HomeChildInfoFormatResponse,
  HomeChildInfoResponse,
} from '@API/src/component/home/child/types';
import {
  ChildInfoFormat,
} from '@API/src/component/nursery/child/types';
import {
  FetchChildrenInfoRequest,
  PostChildrenInfoRequest,
  PutChildrenRequest,
} from '@/model/Children';

export interface ChildrenState {
  // format
  basicInfoFormats: ChildInfoFormat[];
  lifestyleInfoFormats: ChildInfoFormat[];
  healthInfoFormats: ChildInfoFormat[];
  // info
  childInfo: HomeChildInfoResponse | null;
}

@Module({ dynamic: true, store, name: 'children', namespaced: true })
class ChildrenModule extends VuexModule implements ChildrenState {

  // format
  public basicInfoFormats: ChildInfoFormat[] = [];
  public lifestyleInfoFormats: ChildInfoFormat[] = [];
  public healthInfoFormats: ChildInfoFormat[] = [];
  // info
  public childInfo: HomeChildInfoResponse | null = null;

  @Action({ rawError: true })
  public async fetchChildrenInfoFormat() {
    try {
      const response = await ApiChildren.fetchChildrenInfoFormat();
      this.setFormat(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setFormat(formats: HomeChildInfoFormatResponse) {
    this.basicInfoFormats = formats.basicInfoFormats;
    this.lifestyleInfoFormats = formats.lifestyleInfoFormats;
    this.healthInfoFormats = formats.healthInfoFormats;
  }

  @Action({ rawError: true })
  public async fetchChildrenInfo(request: FetchChildrenInfoRequest) {
    try {
      const response = await ApiChildren.fetchChildrenInfo(request);
      this.setInfo(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setInfo(childInfo: HomeChildInfoResponse) {
    this.childInfo = childInfo;
  }

  @Action({ rawError: true })
  public async postChildrenInfo(request: PostChildrenInfoRequest) {
    try {
      const response = await ApiChildren.postChildrenInfo(request);
      this.setInfo(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putChildren(request: PutChildrenRequest) {
    try {
      const response = await ApiChildren.putChildren(request);
      familyModule.editChild(response);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export const childrenModule = getModule(ChildrenModule);
