import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiParent from '@/api/ApiParent';

import { PostParentInfoRequest } from '@/model/Parent';
import {
  HomeParentInfoFormatResponse,
  HomeParentInfoResponse,
  ParentInfoFormat,
} from '@API/src/component/home/parent/types';

export interface ParentState {
  // format
  formats: ParentInfoFormat[];
  // info
  parentInfo: Array<{
    format: ParentInfoFormat; // 指定のフォーマット
    key: string; // キー名（物理名）
    value: unknown; // 回答値
  }>;
}

@Module({ dynamic: true, store, name: 'parent', namespaced: true })
class ParentModule extends VuexModule implements ParentState {

  public formats: ParentInfoFormat[] = [];
  public parentInfo: Array<{
    format: ParentInfoFormat;
    key: string;
    value: unknown;
  }> = [];

  @Action({ rawError: true })
  public async fetchParentInfoFormat() {
    try {
      const response = await ApiParent.fetchParentInfoFormat();
      this.setFormat(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setFormat(formats: HomeParentInfoFormatResponse) {
    this.formats = formats.formats;
  }

  @Action({ rawError: true })
  public async fetchParentInfo() {
    try {
      const response = await ApiParent.fetchParentInfo();
      this.setInfo(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setInfo(parentInfo: HomeParentInfoResponse) {
    this.parentInfo = parentInfo.parentInfo;
  }

  @Action({ rawError: true })
  public async postParentInfo(request: PostParentInfoRequest) {
    try {
      const response = await ApiParent.postParentInfo(request);
      this.setInfo(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

}

export const parentModule = getModule(ParentModule);
