import {
  ParentInfoFormat,
  HomeParentInfoFormatResponse,
  HomeParentInfoResponse,
} from '@API/src/component/home/parent/types';

export const isTypeParentInfoFormat = (
  arg: any,
): arg is ParentInfoFormat =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.key === 'string' &&
  typeof arg.answerType === 'string' &&
  typeof arg.label === 'string' &&
  typeof arg.isRequired === 'boolean' &&
  Array.isArray(arg.options);

export const isTypeHomeParentInfoFormatResponse = (
  arg: any,
): arg is HomeParentInfoFormatResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.formats) &&
  (arg.formats.length === 0 || isTypeParentInfoFormat(arg.formats[0]));

export const isTypeHomeParentInfoResponse = (
  arg: any,
): arg is HomeParentInfoResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.parentInfo);
