import ApiClient from '@/api/ApiClient';
import {
  HomeParentInfoFormatResponse,
  HomeParentInfoResponse,
} from '@API/src/component/home/parent/types';
import { PostParentInfoRequest } from '@/model/Parent';
import {
  isTypeHomeParentInfoFormatResponse,
  isTypeHomeParentInfoResponse,
} from '@/model/TypeGuard/Parent';

export default class ApiParent {

  public static async fetchParentInfoFormat(): Promise<HomeParentInfoFormatResponse> {
    try {
      const response = await ApiClient.get(`/parent-info-format`, null);
      if (isTypeHomeParentInfoFormatResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchParentInfo(): Promise<HomeParentInfoResponse> {
    try {
      const response = await ApiClient.get(`/parent-info`, null);
      if (isTypeHomeParentInfoResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postParentInfo(
    request: PostParentInfoRequest,
  ): Promise<HomeParentInfoResponse> {
    try {
      const response = await ApiClient.post(`/parent-info`, request);
      if (isTypeHomeParentInfoResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
