
import Vue from 'vue';
import { when } from '@/service/Switch';

import { auth0Module } from '@/store/entityModules/auth0';

enum MenuType {
  PASSWORD = 'password',
  NOTIFICATION = 'notification',
  FAMILY = 'family',
  LANGUAGE = 'language',
  TERMS_OF_SERVICE = 'termas_of_service',
  PRIVACY_POLICY = 'privacy_policy',
  TRANSACTIONS_LAW = 'transactions_law',
  PAYMENT = 'payment',
  LOGOUT = 'logout',
}

interface Menu {
  type: MenuType;
  title: string;
  path: string;
}

interface DataType {
  menus: Menu[];
}

export default Vue.extend({

  data: (): DataType => ({
    menus: [
      // {
      //   type: MenuType.PASSWORD,
      //   title: 'パスワード設定',
      //   path: '/setting/password',
      // },
      {
        type: MenuType.FAMILY,
        title: '家族設定',
        path: '/setting/family',
      },
      {
        type: MenuType.PAYMENT,
        title: '支払設定',
        path: '/payment/select',
      },
      {
        type: MenuType.NOTIFICATION,
        title: '通知設定',
        path: '/setting/notification',
      },
      // {
      //   type: MenuType.LANGUAGE,
      //   title: '言語の設定（Language setting）',
      //   path: '/setting/language',
      // },
      // {
      //   type: MenuType.TERMS_OF_SERVICE,
      //   title: '利用規約',
      //   path: '/setting/terms_of_service',
      // },
      // {
      //   type: MenuType.PRIVACY_POLICY,
      //   title: 'お問い合わせ',
      //   path: '/setting/privacy_policy',
      // },
      // {
      //   type: MenuType.TRANSACTIONS_LAW,
      //   title: '特定商取引に基づく表記',
      //   path: '/setting/transactions_law',
      // },
      {
        type: MenuType.LOGOUT,
        title: 'ログアウト',
        path: '/',
      },
    ],
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting', () => {});
    },
    clickMenu(menu: Menu) {
      when(menu.type)
        .on((v) => v === MenuType.LOGOUT, () => {
          auth0Module.logout();
        })
        .otherwise(() => {
          this.$router.push(menu.path);
        });
    },
  },

});
