import {
  InvoiceInfo,
  InvoiceResponse,
} from '@API/src/component/home/invoice/types';


export const isTypeInvoiceInfo = (
  arg: any,
): arg is InvoiceInfo =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.year === 'number' &&
  typeof arg.month === 'number' &&
  typeof arg.monthlyNurseryFee === 'number' &&
  typeof arg.monthlyNurseryFeeDiff === 'number' &&
  typeof arg.tempNurseryFee === 'number' &&
  typeof arg.tempNurseryFeeDiff === 'number' &&
  typeof arg.monthlyAdditionalFee === 'number' &&
  typeof arg.monthlyAdditionalFeeDiff === 'number' &&
  typeof arg.spotAdditionalFee === 'number' &&
  typeof arg.spotAdditionalFeeDiff === 'number' &&
  typeof arg.foodServiceFee === 'number' &&
  typeof arg.foodServiceFeeDiff === 'number' &&
  typeof arg.extraServiceFee === 'number' &&
  typeof arg.extraServiceFeeDiff === 'number' &&
  typeof arg.accountsReceivable === 'number';

export const isTypeInvoiceResponse = (
  arg: any,
): arg is InvoiceResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.invoices) &&
  (arg.invoices.length === 0 || isTypeInvoiceInfo(arg.invoices[0]));
