import ApiClient from '@/api/ApiClient';
import {
  isTypeInvoiceResponse,
} from '@/model/TypeGuard/Invoice';
import {
  InvoiceResponse,
} from '@API/src/component/home/invoice/types';
import {
  FetchInvoicesRequest,
} from '@/model/Invoice';

export default class ApiEvents {

  public static async fetchInvoices(request: FetchInvoicesRequest): Promise<InvoiceResponse> {
    try {
      const response = await ApiClient.get(`/invoice`, request);
      if (isTypeInvoiceResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
