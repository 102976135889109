
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import TimePicker from '@/components/molecules/TimePicker.vue';
import { dailyReportModule } from '@/store/entityModules/dailyReport';

// model
import { VForm } from '@/model/Vuetify/VForm';

interface DataType {
  recordedAt: string;
  amountId: number | null;
  milkCategory: string;
  btnLoading: boolean;
  isRecordetAtPicker: boolean;
}

const MilkCategory = {
  formula: 'formula', // ミルク
  breast: 'breast', // 母乳
};

export default Vue.extend({
  name: 'MilkModal',
  components: {
    TimePicker,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).post_milk_form;
    },
    categories() {
      return [
        {
          text: 'ミルク',
          value: MilkCategory.formula,
        },
        {
          text: '母乳',
          value: MilkCategory.breast,
        },
      ];
    },
    choices() {
      return dailyReportModule.milkChoices;
    },
  },

  watch: {
    isOpen: {
      handler(val: boolean) {
        if (val) {
          this.recordedAt = dayjs().format('HH:00');
          this.amountId = null;
          if (this.choices.length < 1) {
            dailyReportModule.fetchMilkChoices();
          }
        }
      },
      deep: true,
    },
  },

  data: (): DataType =>({
    recordedAt: dayjs().format('HH:00'),
    amountId: null,
    milkCategory: '',
    btnLoading: false,
    isRecordetAtPicker: false,
  }),

  methods: {
    allowedStep: (m) => m % 5 === 0,
    close() {
      this.$emit('close');
    },
    async clickPost() {
      if (this.form.validate() && this.milkCategory) {
        this.btnLoading = true;
        try {
          const recordedAtHour = this.recordedAt.split(':')[0];
          const recordedAtMinutes = this.recordedAt.split(':')[1];
          const recordedAtTime = dayjs().hour(Number(recordedAtHour)).minute(Number(recordedAtMinutes));
          await dailyReportModule.postMilk({
            childId: this.childId,
            date: this.selectedDate,
            recordedAt: dayjs(recordedAtTime).toISOString(),
            amountId: this.amountId,
            milkCategory: this.milkCategory,
          });
          this.$emit('posted');
          this.$emit('close');
          this.btnLoading = false;
        } catch (error) {
          this.$emit('close');
          this.btnLoading = false;
        }
      }
    },
  },

  mounted() {
    dailyReportModule.fetchMilkChoices();
  },
});
