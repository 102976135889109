import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiDailyReport from '@/api/ApiDailyReport';
import { when } from '@/service/Switch';

import { DailyReportResponse } from '@API/src/component/home/dailyReport/types';
import { DefecationResponse } from '@API/src/component/home/defecation/types';
import { TemperatureResponse } from '@API/src/component/home/temperature/types';
import { MilkResponse } from '@API/src/component/home/milk/types';
import { MilkChoicesResponse } from '@API/src/component/nursery/milk/types';
import { SleepResponse } from '@API/src/component/home/sleep/types';
import { DinnerResponse } from '@API/src/component/home/dinner/types';
import { BreakfastResponse } from '@API/src/component/home/breakfast/types';
import { MealResponse } from '@API/src/component/nursery/meal/types';
import {
  FetchBreakfastsRequest,
  PostBreakfastRequest,
  DeleteBreakfastRequest,
  FetchBreakfastRequest,
  PutBreakfastRequest,
  FetchDefecationsRequest,
  PostDefecationRequest,
  DeleteDefecationRequest,
  FetchDefecationRequest,
  PutDefecationRequest,
  FetchDinnersRequest,
  PostDinnerRequest,
  DeleteDinnerRequest,
  FetchDinnerRequest,
  PutDinnerRequest,
  FetchMilksRequest,
  PostMilkRequest,
  DeleteMilkRequest,
  FetchMilkRequest,
  PutMilkRequest,
  FetchReportRequest,
  FetchReportListRequest,
  FetchSleepsRequest,
  PostSleepRequest,
  DeleteSleepRequest,
  FetchSleepRequest,
  PutSleepRequest,
  FetchTemperaturesRequest,
  PostTemperatureRequest,
  DeleteTemperatureRequest,
  FetchTemperatureRequest,
  PutTemperatureRequest,
  PutReportRequest,
} from '@/model/DailyReport';
import { Sleep } from '@/model/Others/Sleep';

export interface DailyReportState {
  date: string;
  home: {
    milks: MilkResponse[];
    temperatures: TemperatureResponse[];
    defecations: DefecationResponse[];
    sleeps: SleepResponse[];
    breakfasts: BreakfastResponse[];
    dinners: DinnerResponse[];
  };
  nursery: {
    milks: MilkResponse[];
    temperatures: TemperatureResponse[];
    sleeps: SleepResponse[];
    defecations: DefecationResponse[];
    meal: MealResponse | null;
  };
  reports: DailyReportResponse[];
  milkChoices: Array<{
    id: number | null;
    value: number | string;
  }>;
}

enum MealStatus {
  refill = 'refill', // おかわり
  finished = 'finished', // 完食
  unfinished = 'unfinished', // 少し残した
  half = 'half', // 半分食べた
  little = 'little', // 少し食べた
  notEat = 'notEat', // 食べなかった
  notServed = 'notServed', // 未提供
}

@Module({ dynamic: true, store, name: 'dailyReport', namespaced: true })
class DailyReportModule extends VuexModule implements DailyReportState {

  public date = '';
  public home: {
    milks: MilkResponse[];
    temperatures: TemperatureResponse[];
    defecations: DefecationResponse[];
    sleeps: SleepResponse[];
    breakfasts: BreakfastResponse[];
    dinners: DinnerResponse[];
  } = {
      milks: [],
      temperatures: [],
      defecations: [],
      sleeps: [],
      breakfasts: [],
      dinners: [],
    };
  public nursery: {
    milks: MilkResponse[];
    temperatures: TemperatureResponse[];
    sleeps: SleepResponse[];
    defecations: DefecationResponse[];
    meal: MealResponse | null;
  } = {
      milks: [],
      temperatures: [],
      sleeps: [],
      defecations: [],
      meal: null,
    };
  public reports: DailyReportResponse[] = [];
  public milkChoices: Array<{
    id: number | null;
    value: number | string;
  }> = [];

  public get meals(): Array<{
    key: string;
    text: string | null;
    item: string;
  }> {
    const arr: Array<{
      key: string;
      text: string | null;
      item: string;
    }> = [];
    if (this.nursery.meal !== null) {
      const meal = this.nursery.meal;
      const keys = Object.keys(this.nursery.meal);
      for (const k of keys) {
        when(k)
          .on((v) => v === 'stapleFood', () => {
            arr.push({ key: k, text: '主食', item: meal[k] });
          })
          .on((v) => v === 'mainDish', () => {
            arr.push({ key: k, text: '主菜', item: meal[k] });
          })
          .on((v) => v === 'sideDish', () => {
            arr.push({ key: k, text: '副菜', item: meal[k] });
          })
          .on((v) => v === 'dessert', () => {
            arr.push({ key: k, text: 'デザート', item: meal[k] });
          })
          .on((v) => v === 'soup', () => {
            arr.push({ key: k, text: '汁物', item: meal[k] });
          })
          .on((v) => v === 'milk', () => {
            arr.push({ key: k, text: '牛乳', item: meal[k] });
          })
          .on((v) => v === 'snack', () => {
            arr.push({ key: k, text: 'おやつ', item: meal[k] });
          });
      }
    }
    return arr;
  }

  @Action({ rawError: true })
  public async fetchReport(request: FetchReportRequest) {
    try {
      const response = await ApiDailyReport.fetchReport(request);
      this.setDailyReport(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setDailyReport(dailyReport: DailyReportResponse) {
    this.date = dailyReport.date;
    this.home = dailyReport.home;
    this.nursery = dailyReport.nursery;
  }

  @Action({ rawError: true })
  public async fetchReportList(request: FetchReportListRequest) {
    try {
      const response = await ApiDailyReport.fetchReportList(request);
      this.setDailyReports(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setDailyReports(dailyReports: DailyReportResponse[]) {
    this.reports = dailyReports;
  }

  // breakfast
  @Action({ rawError: true })
  public async fetchBreakfasts(request: FetchBreakfastsRequest) {
    try {
      const response = await ApiDailyReport.fetchBreakfasts(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postBreakfast(request: PostBreakfastRequest) {
    try {
      const response = await ApiDailyReport.postBreakfast(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteBreakfast(request: DeleteBreakfastRequest) {
    try {
      const response = await ApiDailyReport.deleteBreakfast(request);
      this.removeBreakfast(request.breakfastId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeBreakfast(breakfastId: number) {
    this.home.breakfasts = this.home.breakfasts.filter((b) => {
      if (b.breakfastId !== breakfastId) { return b; }
    });
  }

  @Action({ rawError: true })
  public async fetchBreakfast(request: FetchBreakfastRequest) {
    try {
      const response = await ApiDailyReport.fetchBreakfast(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putBreakfast(request: PutBreakfastRequest) {
    try {
      const response = await ApiDailyReport.putBreakfast(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // defecation
  @Action({ rawError: true })
  public async fetchDefecations(request: FetchDefecationsRequest) {
    try {
      const response = await ApiDailyReport.fetchDefecations(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postDefecation(request: PostDefecationRequest) {
    try {
      const response = await ApiDailyReport.postDefecation(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteDefecation(request: DeleteDefecationRequest) {
    try {
      const response = await ApiDailyReport.deleteDefecation(request);
      this.removeDefecation(request.defecationId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeDefecation(defecationId: number) {
    this.home.defecations = this.home.defecations.filter((d) => {
      if (d.defecationId !== defecationId) { return d; }
    });
  }

  @Action({ rawError: true })
  public async fetchDefecation(request: FetchDefecationRequest) {
    try {
      const response = await ApiDailyReport.fetchDefecation(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putDefecation(request: PutDefecationRequest) {
    try {
      const response = await ApiDailyReport.putDefecation(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // dinner
  @Action({ rawError: true })
  public async fetchDinners(request: FetchDinnersRequest) {
    try {
      const response = await ApiDailyReport.fetchDinners(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postDinner(request: PostDinnerRequest) {
    try {
      const response = await ApiDailyReport.postDinner(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteDinner(request: DeleteDinnerRequest) {
    try {
      const response = await ApiDailyReport.deleteDinner(request);
      this.removeDinner(request.dinnerId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeDinner(dinnerId: number) {
    this.home.dinners = this.home.dinners.filter((d) => {
      if (d.dinnerId !== dinnerId) { return d; }
    });
  }

  @Action({ rawError: true })
  public async fetchDinner(request: FetchDinnerRequest) {
    try {
      const response = await ApiDailyReport.fetchDinner(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putDinner(request: PutDinnerRequest) {
    try {
      const response = await ApiDailyReport.putDinner(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // milk
  @Action({ rawError: true })
  public async fetchMilks(request: FetchMilksRequest) {
    try {
      const response = await ApiDailyReport.fetchMilks(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postMilk(request: PostMilkRequest) {
    try {
      const response = await ApiDailyReport.postMilk(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async fetchMilkChoices() {
    try {
      const response = await ApiDailyReport.fetchMilkChoices();
      this.setMilkChoices(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setMilkChoices(choices: MilkChoicesResponse) {
    this.milkChoices = choices.amountIds;
    this.milkChoices.unshift({
      id: null,
      value: '不明',
    });
  }

  @Action({ rawError: true })
  public async deleteMilk(request: DeleteMilkRequest) {
    try {
      const response = await ApiDailyReport.deleteMilk(request);
      this.removeMilk(request.milkId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeMilk(milkId: number) {
    this.home.milks = this.home.milks.filter((m) => {
      if (m.milkId !== milkId) { return m; }
    });
  }

  @Action({ rawError: true })
  public async fetchMilk(request: FetchMilkRequest) {
    try {
      const response = await ApiDailyReport.fetchMilk(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putMilk(request: PutMilkRequest) {
    try {
      const response = await ApiDailyReport.putMilk(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // sleep
  @Action({ rawError: true })
  public async fetchSleeps(request: FetchSleepsRequest) {
    try {
      const response = await ApiDailyReport.fetchSleeps(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postSleep(request: PostSleepRequest) {
    try {
      const response = await ApiDailyReport.postSleep(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteSleep(request: DeleteSleepRequest) {
    try {
      const response = await ApiDailyReport.deleteSleep(request);
      this.removeSleep(request.sleepId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeSleep(sleepId: number) {
    this.home.sleeps = this.home.sleeps.filter((s) => {
      if (s.sleepId !== sleepId) { return s; }
    });
  }

  @Action({ rawError: true })
  public async fetchSleep(request: FetchSleepRequest) {
    try {
      const response = await ApiDailyReport.fetchSleep(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putSleep(request: PutSleepRequest) {
    try {
      const response = await ApiDailyReport.putSleep(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // temperature
  @Action({ rawError: true })
  public async fetchTemperatures(request: FetchTemperaturesRequest) {
    try {
      const response = await ApiDailyReport.fetchTemperatures(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postTemperature(request: PostTemperatureRequest) {
    try {
      const response = await ApiDailyReport.postTemperature(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteTemperature(request: DeleteTemperatureRequest) {
    try {
      const response = await ApiDailyReport.deleteTemperature(request);
      this.removeTemperature(request.temperatureId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeTemperature(temperatureId: number) {
    this.home.temperatures = this.home.temperatures.filter((t) => {
      if (t.temperatureId !== temperatureId) { return t; }
    });
  }

  @Action({ rawError: true })
  public async fetchTemperature(request: FetchTemperatureRequest) {
    try {
      const response = await ApiDailyReport.fetchTemperature(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putTemperature(request: PutTemperatureRequest) {
    try {
      const response = await ApiDailyReport.putTemperature(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putReport(request: PutReportRequest) {
    try {
      const response = await ApiDailyReport.putReport(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

}

export const dailyReportModule = getModule(DailyReportModule);
