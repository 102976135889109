import {
  FeedListResponse,
  FeedResponse,
} from '@API/src/component/home/feed/types';

export const isTypeFeedResponse = (
  arg: any,
): arg is FeedResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.feedId === 'number' &&
  typeof arg.feedType === 'string' &&
  typeof arg.title === 'string' &&
  typeof arg.body === 'string' &&
  typeof arg.unReadCnt === 'number';

export const isTypeFeedListResponse = (
  arg: any,
): arg is FeedListResponse =>
  arg !== null &&
  typeof arg === 'object';
