import {
  PaymentMethodCardResponse,
  PaymentMethodCardListResponse,
  PaymentMethodResponse,
  PaymentMethodListResponse,
} from '@API/src/component/home/payment/types';

export const isTypePaymentMethodResponse = (
  arg: any,
): arg is PaymentMethodResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.child === 'object';

export const isTypePaymentMethodListResponse = (
  arg: any,
): arg is PaymentMethodListResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg) &&
  (arg.length === 0 || isTypePaymentMethodResponse(arg[0]));

export const isTypePaymentMethodCardResponse = (
  arg: any,
): arg is PaymentMethodCardResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.cardSeq === 'string' &&
  typeof arg.cardNo === 'string' &&
  typeof arg.expire === 'string' &&
  typeof arg.holderName === 'string';

export const isTypePaymentMethodCardListResponse = (
  arg: any,
): arg is PaymentMethodCardListResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg) &&
  (arg.length === 0 || isTypePaymentMethodCardResponse(arg[0]));
