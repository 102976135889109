export default {
  required: (value: any) => !!value || '必須入力項目です',
  choice2: (value: any) => value.length === 2 || '2つ選択してください',
  counter: (value: any) => value.length <= 20 || '20文字以内で入力してください',
  counter2: (value: any) => (!!value && value.length === 2) || '2文字で入力してください',
  counter3: (value: any) => (!!value && value.length === 3) || '3文字で入力してください',
  counterMin4: (value: any) => (!!value && value.length <= 4) || '4文字以内で入力してください',
  counter14to16: (value: any) => (!!value && value.length >= 14 && value.length <= 16) || '14〜16文字で入力してください',
  counter100: (value: any) => (!value || typeof(value) === 'string' && value.length <= 100)|| '100文字以内で入力してください',
  counter500: (value: any) => (!value || typeof(value) === 'string' && value.length <= 500) || '500文字以内で入力してください',
  counter1000: (value: any) => (!value || typeof(value) === 'string' && value.length <= 1000) || '1000文字以内で入力してください',
  typeNumber: (value: any) => (!value || typeof(value) === 'number' && value < -2147483648 && value > 2147483647) || '数値で入力してください',
  typeStringNumber: (value: any) => {
    const pattern = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
    return pattern.test(value) || '数値で入力してください';
  },
  email: (value: any) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'メールアドレスの形式で入力してください';
  },
  phoneNumber: (value: any) => {
    if (value === null || value === '') { return true; }
    const pattern = /0\d{1,4}-\d{1,4}-\d{4}/;
    return pattern.test(value) || '電話番号の形式が違います';
  },
  postalCode: (value: any) => {
    if (value === null || value === '') { return true; }
    const pattern = /[0-9]{3}-[0-9]{4}/;
    return pattern.test(value) || '郵便番号の形式が違います';
  },
  integerThan0: (value: any) => {
    const pattern = /^\d*$/;
    return pattern.test(value) || '0以上の整数で入力してください';
  },
  multipleSelectRequired: (value: []) => value.length > 0 || '必須入力項目です',
  typeKana: (value: any) => {
    if (!value) { return true; }
    const pattern = /^[\u30a1-\u30f6]+$/;
    return pattern.test(value) || 'カタカナで入力してください';
  },
  typeAlphabet: (value: any) => {
    if (!value) { return true; }
    const pattern = /^[a-zA-Z\s]*$/;
    return pattern.test(value) || '半角英字で入力してください';
  },
};
