/**
 * セーフエリアがあるディスプレイかどうかを返す
 */
export function isSafeAreaDisplay(): boolean {

  let flag: boolean = false;

  // constant()はiOS 11.2で削除されるのでenv()へのフォールバックが必要
  // Designing Websites for iPhone X | WebKit
  // https://webkit.org/blog/7929/designing-websites-for-iphone-x/
  const isSafeAreaConst = CSS.supports('padding-left: constant(safe-area-inset-left)');
  const isSafeAreaEnv = CSS.supports('padding-left: env(safe-area-inset-left)');

  // どちらかがサポートされているなら実際の値を計算
  if (isSafeAreaConst || isSafeAreaEnv) {

    // safe-area-inset-*をダミーDIVのpaddingに設定する
    const elmDiv = document.createElement('div');
    if (isSafeAreaConst) { // iOS 11.1
      elmDiv.style.paddingTop = 'constant(safe-area-inset-top)';
      elmDiv.style.paddingLeft = 'constant(safe-area-inset-left)';
      elmDiv.style.paddingRight = 'constant(safe-area-inset-right)';
      elmDiv.style.paddingBottom = 'constant(safe-area-inset-bottom)';
    } else { // iOS 11.2
      elmDiv.style.paddingTop = 'env(safe-area-inset-top)';
      elmDiv.style.paddingLeft = 'env(safe-area-inset-left)';
      elmDiv.style.paddingRight = 'env(safe-area-inset-right)';
      elmDiv.style.paddingBottom = 'env(safe-area-inset-bottom)';
    }

    // 非表示にしてドキュメントに追加
    elmDiv.style.display = 'none';
    document.body.appendChild(elmDiv);

    // 計算後のsafe-area-inset-*を取得
    const elmDivStyle = window.getComputedStyle(elmDiv);
    const safeAreaInsetTop = parseInt(elmDivStyle.paddingTop, 10);
    const safeAreaInsetLeft = parseInt(elmDivStyle.paddingLeft, 10);
    const safeAreaInsetRight = parseInt(elmDivStyle.paddingRight, 10);
    const safeAreaInsetBottom = parseInt(elmDivStyle.paddingBottom, 10);
    // console.log(
    //   'safeAreaInsetTop=' + safeAreaInsetTop,
    //   'safeAreaInsetLeft=' + safeAreaInsetLeft,
    //   'safeAreaInsetRight=' + safeAreaInsetRight,
    //   'safeAreaInsetBottom=' + safeAreaInsetBottom,
    // );

    // セーフエリアディスプレイか判断
    if (window.navigator.userAgent.indexOf('iPhone') !== -1) {

      // iOSの場合、iPhoneXでない端末の場合でもステータスバーの20pxがinsetに設定されるので、このケースを除外する必要がある
      const statusBarH = 20;

      // デバイスの回転によってどこにinsetが付くのかわからないので、４辺のどこかにinsetが設定されればセーフエリアディスプレイだとみなす
      if (safeAreaInsetTop > statusBarH || safeAreaInsetLeft > statusBarH || safeAreaInsetRight > statusBarH || safeAreaInsetBottom > statusBarH) {
        flag = true;
      }

    } else {
      if (safeAreaInsetTop > 0 || safeAreaInsetLeft > 0 || safeAreaInsetRight > 0 || safeAreaInsetBottom > 0) {
        flag = true;
      }
    }

    // ドキュメントから削除
    document.body.removeChild(elmDiv);

  }

  // デバッグログ
  console.log('isSafeAreaDisplay=' + flag, 'isSafeAreaConst=' + isSafeAreaConst, 'isSafeAreaEnv=' + isSafeAreaEnv);

  return flag;

}
