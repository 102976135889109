
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';
import { when } from '@/service/Switch';

import { ChildInfoFormat } from 'chaild-entity/src/component/nursery/child/types';
import { isTypeAnswer, Answer } from '../../model/TypeGuard/Children';

interface DataType {
  multiSelected: any[];
  isMonthDatePicker: boolean;
  isDatePicker: boolean;
}

export default Vue.extend({
  name: 'ChildrenField',

  props: {
    format: {
      type: Object,
      default: {},
    },
    answers: {
      type: Array,
      default: () => ([]),
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data:(): DataType => ({
    multiSelected: [],
    isMonthDatePicker: false,
    isDatePicker: false,
  }),

  computed: {
    rules() {
      return rules;
    },
    yearChoices() {
      const choices: Array<{text: string, value: string}> = [];
      for(let i = 1960; i <= Number(dayjs().format('YYYY')); i++) {
        choices.push({
          text: `${i}年`,
          value: `${i}`,
        });
      }
      return choices;
    },
    monthChoices() {
      const choices: Array<{text: string, value: string}> = [];
      for(let i = 1; i <= 12; i++) {
        choices.push({
          text: `${i}月`,
          value: `${i}`,
        });
      }
      return choices;
    },
    dayChoices() {
      const choices: Array<{text: string, value: string}> = [];
      for(let i = 1; i <= 31; i++) {
        choices.push({
          text: `${i}日`,
          value: `${i}`,
        });
      }
      return choices;
    },
  },

  methods: {
    checkRules(isRequired: boolean, answerType: string) {
      const ruleArray: any[] = [];
      if (isRequired) {
        ruleArray.push(this.rules.required);
      }
      when(answerType)
        .on((v) => v === 'shortText', () => ruleArray.push(this.rules.counter100))
        .on((v) => v === 'mediumText', () => ruleArray.push(this.rules.counter500))
        .on((v) => v === 'longText', () => ruleArray.push(this.rules.counter1000))
        .on((v) => v === 'number', () => ruleArray.push(this.rules.typeNumber));
      return ruleArray;
    },
    inputText({ format, value }: {
      format: ChildInfoFormat;
      value: any;
    }) {
      this.$emit('inputValue', {
        format, value,
      });
    },
    changeValue({ format, value }: {
      format: ChildInfoFormat;
      value: any;
    }) {
      this.$emit('inputValue', {
        format, value,
      });
    },
    inputMulti({ format, value }: {
      format: ChildInfoFormat;
      value: any;
    }) {
      this.$emit('inputValue', {
        format, value,
      });
    },
  },

  mounted() {
    const index = this.index;
    if (
      this.answers[index] &&
      typeof this.answers[index] === 'object'
    ) {
      // console.log(this.answers[index]);
      if (isTypeAnswer(this.answers[index])) {
        const answer = this.answers[index] as Answer;
        if (
          answer.value &&
          Array.isArray(answer.value)
        ) {
          this.multiSelected = answer.value as any[];
        }
      }
    }
  },

});
