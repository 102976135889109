import ApiClient from '@/api/ApiClient';
import {
  GrowthResponse,
} from '@API/src/component/home/growth/types';
import {
  NurseryGrowthPercentileResponse,
} from '@API/src/component/nursery/growth/types';
import {
  FetchGrowthRequest,
  FetchGrowthPercentileRequest,
} from '@/model/Growth';
import {
  isTypeGrowthsResponse,
  isTypeGrowthPercentilesResponse,
} from '@/model/TypeGuard/Growth';

export default class ApiGrowth {

  public static async fetchGrowth(
    request: FetchGrowthRequest,
  ): Promise<GrowthResponse[]> {
    try {
      const response = await ApiClient.get(`/growth`, request);
      if (isTypeGrowthsResponse(response.growth)) {
        return response.growth;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchGrowthPercentile(
    request: FetchGrowthPercentileRequest,
  ): Promise<NurseryGrowthPercentileResponse[]> {
    try {
      const response = await ApiClient.get(`/growth/percentile`, null);
      if (isTypeGrowthPercentilesResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
