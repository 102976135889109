
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import TimePicker from '@/components/molecules/TimePicker.vue';
import { dailyReportModule } from '@/store/entityModules/dailyReport';

// model
import { VForm } from '@/model/Vuetify/VForm';

interface DataType {
  recordedAt: string;
  temperature: number | null;
  btnLoading: boolean;
  isRecordetAtPicker: boolean;
}

export default Vue.extend({
  name: 'TemperatureModal',
  components: {
    TimePicker,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).post_temperature_form;
    },
    options() {
      const options: Array<{text: string, value: number}> = [];
      for (let i = 340; i < 430; i++) {
        options.push({
          text: `${(i / 10).toFixed(1)}℃`,
          value: parseFloat((i / 10).toFixed(1)),
        });
      }
      return options;
    },
  },

  watch: {
    isOpen: {
      handler(val: boolean) {
        if (val) {
          this.temperature = null;
          this.recordedAt = dayjs().format('HH:00');
        }
      },
      deep: true,
    },
  },

  data: (): DataType =>({
    recordedAt: dayjs().format('HH:00'),
    temperature: 36.5,
    btnLoading: false,
    isRecordetAtPicker: false,
  }),

  methods: {
    allowedStep: (m) => m % 5 === 0,
    close() {
      this.$emit('close');
    },
    async clickPost() {
      if (this.form.validate() && this.temperature) {
        this.btnLoading = true;
        try {
          const recordedAtHour = this.recordedAt.split(':')[0];
          const recordedAtMinutes = this.recordedAt.split(':')[1];
          const recordedAtTime = dayjs().hour(Number(recordedAtHour)).minute(Number(recordedAtMinutes));
          await dailyReportModule.postTemperature({
            childId: this.childId,
            date: this.selectedDate,
            recordedAt: dayjs(recordedAtTime).toISOString(),
            temperature: this.temperature,
          });
          this.$emit('posted');
          this.$emit('close');
          this.btnLoading = false;
        } catch (error) {
          this.$emit('close');
          this.btnLoading = false;
        }
      }
    },
  },
});
