
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';
import { invoiceModule } from '@/store/entityModules/invoice';

interface DataType {
  childId: number;
  childName: string;
  fetchLoading: boolean;
}

export default Vue.extend({
  computed: {
    selectedChildImg() {
      if (familyModule.family) {
        const children = familyModule.family.children;
        const child = children.find((c) => c.childId === this.childId);
        if (!!child) {
          return child.imageUrl;
        }
      }
      return null;
    },
    selectedInvoice() {
      return invoiceModule.selectedInvoice;
    },
    extraItems() {
      if (invoiceModule.selectedInvoice) {
        const keys = Object.keys(
          invoiceModule.selectedInvoice.extraServiceDetails,
        );
        return keys;
      }
      return [];
    },
  },

  data: (): DataType => ({
    childId: NaN,
    childName: '',
    fetchLoading: false,
  }),

  methods: {
    routerBack() {
      this.$router.back();
    },
    getDateStr(date: string) {
      return dayjs(date).format('YYYY/MM/DD');
    },
    createYearMonth({ year, month }: { year: number; month: number }) {
      if (month < 4) {
        return `${year + 1}年${month}月`;
      } else {
        return `${year}年${month}月`;
      }
    },
    calcMonth(selectedMonth: number) {
      const month = selectedMonth + 1;
      if (month > 12) {
        return 1;
      } else {
        return month;
      }
    },
    getIsFeeStr(isFee: boolean | null) {
      if (isFee === null) {
        return '';
      } else if (isFee) {
        return '課税';
      } else {
        return '非課税';
      }
    },
    getPaymentCategoryStr(category: string) {
      switch (category) {
        case 'card':
          return 'クレジットカード';
          break;

        case 'withdrawal':
          return '口座引き落とし';
          break;

        case 'invoice':
          return '振り込み';
          break;

        default:
          break;
      }
    },
    isGakken(nurseryId: number | null | undefined) {
      if (
        // nurseryId === 109999403999 ||
        nurseryId === 272111111351 ||
        nurseryId === 149999227999 ||
        nurseryId === 271111111451 ||
        nurseryId === 293111111451 ||
        nurseryId === 215111111451 ||
        nurseryId === 169999319999 ||
        nurseryId === 238111111441 ||
        nurseryId === 250111111441 ||
        nurseryId === 189999320999 ||
        nurseryId === 109999322999 ||
        nurseryId === 129999324999 ||
        nurseryId === 218111111331
      ) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    if (!invoiceModule.selectedInvoice) {
      this.$router.replace('/invoice/select', () => {});
    }
  },
});
