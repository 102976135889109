import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import ApiResource from '@/api/ApiResource';

import {
  HomeResourceResponse,
  HomePreSignedUrlResponse,
} from '@API/src/component/home/resource/types';
import {
  PostResourceRequest,
  PostResourceDownloadRequest,
} from '@/model/Resource';

export interface ResourceState {
  url: string | null;
}

@Module({ dynamic: true, store, name: 'resource', namespaced: true })
class ResourceModule extends VuexModule implements ResourceState {

  public url: string | null = null;

  @Action({ rawError: true })
  public async postResource(request: PostResourceRequest) {
    try {
      const filename = request.file.name;
      const response = await ApiResource.postResource({
        imageType: request.imageType,
        filename,
        childId: request.childId,
      });
      const res = await axios({
        method: 'PUT',
        url: response.url,
        headers: {
          'Content-Type': request.contentType,
        },
        data: request.file,
      });
      this.setResource(response.url);
      return res.config.url;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setResource(url: string) {
    this.url = url;
  }

  @Action({ rawError: true })
  public async postResourceDownload(request: PostResourceDownloadRequest) {
    try {
      const response = await ApiResource.postResourceDownload(request);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export const resourceModule = getModule(ResourceModule);
