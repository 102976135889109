import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiAuth from '@/api/ApiAuth';

import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import LocalDataService from '@/service/LocalDataServices';

export interface Auth0State {
  auth0Client: Auth0Client | null;
  accounts: SigninAccountInfo[];
}

interface OpenIdSigninResponse {
  users: SigninAccountInfo[];
  access_token: string;
  refresh_token: string;
}

interface SigninAccountInfo {
  team_id: string;
  team_name: string;
  user_id: string;
}


@Module({ dynamic: true, store, name: 'auth0', namespaced: true })
class Auth0Module extends VuexModule implements Auth0State {
  public auth0Client: Auth0Client | null = null;
  public accounts: SigninAccountInfo[] = [];
  public showSignUp: boolean = false;

  @Mutation
  public setShowSignUp(show: boolean) {
    this.showSignUp = show;
  }

  @Action({ rawError: true })
  public async initClient() {
    if (
      process.env.VUE_APP_AUTH0_DOMAIN &&
      process.env.VUE_APP_AUTH0_CLIENT_ID
    ) {
      try {
        const client = await createAuth0Client({
          domain: process.env.VUE_APP_AUTH0_DOMAIN,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          redirect_uri: window.location.origin + '/signin',
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
        });
        this.setClient(client);
      } catch (e) {
        // if (e && e.error && e.error === 'interaction_required') {
        // }
      }
    }
  }

  @Mutation
  public setClient(client: Auth0Client) {
    this.auth0Client = client;
  }

  @Action({ rawError: true })
  public async signinProcess() {
    if (this.auth0Client) {
      await this.auth0Client.loginWithRedirect({
        redirect_uri:  window.location.origin + '/signin',
      });
    }
  }

  @Action({ rawError: true })
  public async getAuth0User() {
    if (this.auth0Client && process.env.VUE_APP_AUTH0_DOMAIN) {
      const user = await this.auth0Client.getUser();
      return user;
    }
    return null;
  }

  @Action({ rawError: true })
  public async getAuth0ManagementApiToken() {
    const response = await fetch(`${process.env.VUE_APP_AUTH0_ENDPOINT}/oauth/token`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        client_id: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_CLIENT_ID}`,
        client_secret: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_CLIENT_SECRET}`,
        audience: `${process.env.VUE_APP_AUTH0_ENDPOINT}/api/v2/`,
        grant_type: 'client_credentials',
      }),
    });
    const json = await response.json();
    return json.access_token;
  }

  @Action({ rawError: true })
  public async getAuth0IdpAccessToken({ token, userSub}: {
    token: string
    userSub: string,
  }) {
    const response = await fetch(`${process.env.VUE_APP_AUTH0_ENDPOINT}/api/v2/users/${userSub}`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await response.json();
    return json.identities[0].access_token;
  }

  @Action({ rawError: true })
  public async handleAuth0() {
    if (this.auth0Client && process.env.VUE_APP_AUTH0_DOMAIN) {
      await this.auth0Client.handleRedirectCallback();
      const user = await this.auth0Client.getUser();
      const claim = await this.auth0Client.getIdTokenClaims();
      const accessToken = claim?.__raw;
      LocalDataService.setAccessToken(accessToken ?? '');
      try {
        const signinResponse = await ApiAuth.signinWithGoogle() as OpenIdSigninResponse;
        if (signinResponse) {
          if (signinResponse.users.length > 0) {
            this.setAccounts(signinResponse.users);
            return true;
          } else {
            this.setShowSignUp(true);
            return true;
          }
        }
        return user;
      } catch (error) {
        throw error;
      }
    }
    return null;
  }

  @Action({ rawError: true })
  public async isAuthenticated() {
    if (this.auth0Client) {
      const response = await this.auth0Client.isAuthenticated();
      return response;
    }
  }

  @Action({ rawError: true })
  public async getTokenSilently() {
    try {
      if (this.auth0Client) {
        const response = await this.auth0Client.getTokenSilently();
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAccounts(accounts: SigninAccountInfo[]) {
    this.accounts = accounts;
  }

  @Action({ rawError: true })
  public async logout() {
    if (!this.auth0Client) {
      await this.initClient();
    }
    if (this.auth0Client) {
      await this.auth0Client.logout();
    }
  }

  @Action({ rawError: true })
  public async getAuth0Token() {
    if (this.auth0Client) {
      const claims = await this.auth0Client.getIdTokenClaims();
      if (claims) {
        return claims.__raw;
      }
      await this.initClient();
      const claims2 = await this.auth0Client.getIdTokenClaims();
      if (claims2) {
        return claims2.__raw;
      }
      return '';
    } else {
      try {
        await this.initClient();
        const claims = await this.auth0Client!.getIdTokenClaims();
        if (claims) {
          return claims.__raw;
        }
        return '';
      } catch (err) {
        return '';
      }
    }
  }
}

export const auth0Module = getModule(Auth0Module);
