import {
  HomeChildInfoFormatResponse,
  HomeChildInfoResponse,
} from '@API/src/component/home/child/types';
import {
  ChildResponse,
} from '@API/src/component/home/family/types';

export const isTypeHomeChildInfoFormatResponse = (
  arg: any,
): arg is HomeChildInfoFormatResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.basicInfoFormats) &&
  Array.isArray(arg.lifestyleInfoFormats) &&
  Array.isArray(arg.healthInfoFormats);

export const isTypeHomeChildInfoResponse = (
  arg: any,
): arg is HomeChildInfoResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.basicInfo) &&
  Array.isArray(arg.lifestyleInfo) &&
  Array.isArray(arg.healthInfo);

export const isTypeChildResponseResponse = (
  arg: any,
): arg is ChildResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.childId === 'number' &&
  typeof arg.firstName === 'string' &&
  typeof arg.lastName === 'string' &&
  typeof arg.nurseryName === 'string';

export interface Answer {
  key: string;
  value: any | null;
}

export const isTypeAnswer = (
  arg: any,
): arg is Answer =>
  arg !== null &&
  typeof arg === 'object';
