
import Vue from 'vue';
import dayjs from 'dayjs';
import LocalDataService from '@/service/LocalDataServices';
import { when } from '@/service/Switch';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';

// model
enum ParentType {
  MOTHER = 'mother',
  FATHER = 'father',
  GRANDMA = 'grandma',
  GRANPA = 'granpa',
  UNCLE = 'uncle',
  AUNT = 'aunt',
  SISTER = 'sister',
  BROTHER = 'brother',
  OTHER = 'other',
}

interface DataType {}

export default Vue.extend({
  name: 'Family',
  components: {},

  data: (): DataType => ({}),

  computed: {
    family() {
      return familyModule.family;
    },
    parents() {
      if (familyModule.family) {
        return familyModule.family.parents;
      }
      return [];
    },
    children() {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
    today(): string {
      return dayjs().locale('ja').format('M月D日');
    },
  },

  methods: {
    routerBack() {
      this.$router.replace('/setting/various', () => {});
    },
    formatParentType(parentType: ParentType): string {
      return when(parentType)
        .on((v) => v === ParentType.MOTHER, () => '母')
        .on((v) => v === ParentType.FATHER, () => '父')
        .on((v) => v === ParentType.GRANDMA, () => '祖母')
        .on((v) => v === ParentType.GRANPA, () => '祖父')
        .on((v) => v === ParentType.UNCLE, () => '叔父')
        .on((v) => v === ParentType.AUNT, () => '叔母')
        .on((v) => v === ParentType.SISTER, () => '姉妹')
        .on((v) => v === ParentType.BROTHER, () => '兄弟')
        .on((v) => v === ParentType.OTHER, () => 'その他')
        .otherwise(() => '');
    },
    to(path: string, name: string) {
      this.$router.push({ path, name });
    },
    toParent(path: string, name: string, isLoginUser: boolean) {
      if (isLoginUser) {
        this.$router.push({ path, name });
      }
    },
    toChild(path: string, name: string, childId: string, childName: string) {
      this.$router.push({ path, name, query: {
        childId: String(childId),
        childName,
      } });
    },
  },

  mounted() {
    if (this.family) {
      familyModule.fetchFamilyById({familyId: this.family.familyId});
    }
  },
});
