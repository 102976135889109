
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { when } from '@/service/Switch';

import { VueLoading } from 'vue-loading-template';

import EatingStatusItem from '@/components/molecules/EatingStatusItem.vue';

import { VCalendar } from '@/model/Vuetify/VCalendar';
import { meModule } from '@/store/entityModules/me';
import { dailyReportModule } from '@/store/entityModules/dailyReport';
import { MilkResponse } from 'chaild-entity/src/component/home/milk/types';
import { DefecationResponse, DefecationStatus, DefecationStatusType } from 'chaild-entity/src/component/home/defecation/types';
import { MealResponse, MealStatus, MealStatusType } from 'chaild-entity/src/component/nursery/meal/types';
import { TemperatureResponse } from 'chaild-entity/src/component/home/temperature/types';

interface Event {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
}

enum TabType {
  MEAL = 'meal',
  DEFECATION = 'defecation',
  MILK = 'milk',
  TEMPERATURE = 'temperature',
}

interface DataType {
  childId: number;
  childName: string;
  selectedDate: string;
  tab: number | null;
  tabItems: Array<{ tab: string, content: TabType }>;
  events: Event[];
  selectedOpen: boolean;
  selectedEvent: any;
  selectedElement: any | null;
  fetchLoading: boolean;
}

export default Vue.extend({

  components: {
    VueLoading,
    EatingStatusItem,
  },

  data: (): DataType => ({
    childId: NaN,
    childName: '',
    selectedDate: dayjs().format('YYYY-MM-DD'),
    tab: null,
    tabItems: [
      { tab: '喫食', content: TabType.MEAL },
      { tab: '排便', content: TabType.DEFECATION },
      { tab: 'ミルク', content: TabType.MILK },
      { tab: '体温', content: TabType.TEMPERATURE },
    ],
    events: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    fetchLoading: false,
  }),

  computed: {
    calendar(): VCalendar {
      return (this.$refs as any).record_calendar;
    },
    me() {
      return meModule.me;
    },
    meals() {
      return dailyReportModule.meals;
    },
    reports() {
      return dailyReportModule.reports;
    },
    mealReports() {
      const mealReports: MealResponse[] = [];
      const meals = dailyReportModule.reports.map((r) => {
        if (r.nursery.meal) { return r.nursery.meal; }
      });
      return mealReports;
    },
    defecationReports() {
      const defecationReports: DefecationResponse[] = [];
      const defecations = dailyReportModule.reports.map((r) => {
        const array = r.home.defecations.concat(r.nursery.defecations);
        array.map((d) => {
          defecationReports.push(d);
        });
      });
      return defecationReports;
    },
    milkReports() {
      const milkReports: MilkResponse[] = [];
      const milks = dailyReportModule.reports.map((r) => {
        const array = r.home.milks.concat(r.nursery.milks);
        array.map((m) => {
          milkReports.push(m);
        });
      });
      return milkReports;
    },
    temperatureReports() {
      const temperatureReports: TemperatureResponse[] = [];
      const temperatures = dailyReportModule.reports.map((r) => {
        const array = r.home.temperatures.concat(r.nursery.temperatures);
        array.map((t) => {
          temperatureReports.push(t);
        });
      });
      return temperatureReports;
    },
    home() {
      return dailyReportModule.home;
    },
    nursery() {
      return dailyReportModule.nursery;
    },
    year(): string {
      return dayjs(this.selectedDate).format('YYYY');
    },
    today(): string {
      return dayjs(this.selectedDate).format('YYYY-MM-DD');
    },
    formatedToday(): string {
      return dayjs(this.selectedDate).format('M月');
    },
    dayOfTheWeek(): string {
      return dayjs(this.selectedDate).format('ddd');
    },
  },

  methods: {
    routerBack() {
      this.$router.replace('/record', () => {});
    },
    toNextMonth() {
      this.selectedDate = dayjs(this.selectedDate).add(1, 'month').format('YYYY-MM-DD');
      // tslint:disable-next-line
      this.calendar.next;
      this.fetchReportList();
    },
    toPrevMonth() {
      this.selectedDate = dayjs(this.selectedDate).add(-1, 'month').format('YYYY-MM-DD');
      // tslint:disable-next-line
      this.calendar.prev;
      this.fetchReportList();
    },
    async fetchReportList() {
      this.fetchLoading = true;
      const fromDate = dayjs(this.selectedDate).startOf('month');
      const endDate = dayjs(this.selectedDate).endOf('month');
      await dailyReportModule.fetchReportList({
        childId: this.childId,
        from: dayjs(fromDate).format('YYYY-MM-DD'),
        to: dayjs(endDate).format('YYYY-MM-DD'),
      });
      this.updateRange();
      this.fetchLoading = false;
    },
    async showEvent({ nativeEvent, event, date }) {
      if (event) { this.selectedDate = dayjs(event.start).format('YYYY-MM-DD'); }
      if (date) { this.selectedDate = dayjs(date).format('YYYY-MM-DD'); }
      this.fetchLoading = true;
      await dailyReportModule.fetchReport({
        childId: this.childId,
        date: this.selectedDate,
      });
      this.$nextTick(() => {
        this.eventScrollToBottom();
        this.fetchLoading = false;
      });
    },
    eventScrollToBottom() {
      const selectedElement: Element = document.getElementsByClassName('scroll-wrapper')[0];
      const scrollElemnt: Element = document.getElementsByClassName('scroll-item')[0];
      selectedElement.scrollTop = scrollElemnt.clientHeight;
    },
    updateRange() {
      this.events = [];
      const events: Event[] = [];
      when(typeof this.tab === 'number' && this.tabItems[this.tab].content)
        .on((v) => v === TabType.MEAL, () => {
          // for (let i = 0; i < this.mealReports.length; i++) {
          for (const mr of this.mealReports) {
            const date = new Date(dayjs(mr.date).format('YYYY-MM-DD HH:mm:ss'));
            const state = when(mr.stapleFood)
              .on((v) => v === MealStatus.refill, () => 'おかわり')
              .on((v) => v === MealStatus.finished, () => '完食')
              .on((v) => v === MealStatus.unfinished, () => '少し残した')
              .on((v) => v === MealStatus.half, () => '半分食べた')
              .on((v) => v === MealStatus.little, () => '少し食べた')
              .on((v) => v === MealStatus.notEat, () => '食べなかった')
              .on((v) => v === MealStatus.notServed, () => '未提供');
            events.push({
              name: `${state}`,
              start: date,
              end: date,
              color: 'primary',
              timed: false,
            });
          }
        })
        .on((v) => v === TabType.DEFECATION, () => {
          // for (let i = 0; i < this.defecationReports.length; i++) {
          for (const dr of this.defecationReports) {
            const date = new Date(dayjs(dr.recordedAt).format('YYYY-MM-DD HH:mm:ss'));
            const state = when(dr.state)
              .on((v) => v === DefecationStatus.hard, () => '硬い')
              .on((v) => v === DefecationStatus.normal, () => '普通')
              .on((v) => v === DefecationStatus.soft, () => '軟らかい')
              .otherwise(() => '');
            events.push({
              name: `${state}`,
              start: date,
              end: date,
              color: 'primary',
              timed: false,
            });
          }
        })
        .on((v) => v === TabType.MILK, () => {
          // for (let i = 0; i < this.milkReports.length; i++) {
          for (const mr of this.milkReports) {
            const date = new Date(dayjs(mr.recordedAt).format('YYYY-MM-DD HH:mm:ss'));
            events.push({
              name: `${mr.amount}cc`,
              start: date,
              end: date,
              color: 'primary',
              timed: false,
            });
          }
        })
        .on((v) => v === TabType.TEMPERATURE, () => {
          // for (let i = 0; i < this.temperatureReports.length; i++) {
          for (const tr of this.temperatureReports) {
            const date = new Date(dayjs(tr.recordedAt).format('YYYY-MM-DD HH:mm:ss'));
            events.push({
              name: `${tr.temperature}℃`,
              start: date,
              end: date,
              color: 'primary',
              timed: false,
            });
          }
        })
        .otherwise(() => {});
      this.events = events;
    },
    minuteFormat(iso: string) {
      return dayjs(iso).format('HH:mm');
    },
    getDefecationState(state: DefecationStatusType): string {
      return when(state)
        .on((v) => v === DefecationStatus.hard, () => '硬い')
        .on((v) => v === DefecationStatus.normal, () => '普通')
        .on((v) => v === DefecationStatus.soft, () => '軟らかい')
        .otherwise(() => '');
    },
  },

  async mounted() {
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
      await Promise.all([
        this.fetchReportList(),
        dailyReportModule.fetchReport({
          childId: this.childId,
          date: this.selectedDate,
        }),
      ]);
    } else {
      this.routerBack();
    }
  },
});
