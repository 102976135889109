import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiPayment from '@/api/ApiPayment';
import {
  PaymentMethodCardResponse,
  PaymentMethodCardListResponse,
  PaymentMethodResponse,
  PaymentMethodListResponse,
} from '@API/src/component/home/payment/types';
import {
  FetchPaymentMethodRequest,
  PutPaymentMethodRequest,
  PostPaymentMethodCardRequest,
  DeletePaymentMethodCardRequest,
  FetchPaymentMethodCardRequest,
} from '@/model/Payment';

export interface PaymentState {
  payments: PaymentMethodListResponse;
  cards: PaymentMethodCardListResponse;
}

@Module({ dynamic: true, store, name: 'payment', namespaced: true })
class PaymentModule extends VuexModule implements PaymentState {

  public payments: PaymentMethodListResponse = [];
  public cards: PaymentMethodCardListResponse = [];

  @Action({ rawError: true })
  public async fetchPaymentMethod(request: FetchPaymentMethodRequest) {
    try {
      const response: PaymentMethodListResponse = await ApiPayment.fetchPaymentMethod(request);
      this.setPayments(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putPaymentMethod(request: PutPaymentMethodRequest) {
    try {
      const response: PaymentMethodListResponse = await ApiPayment.putPaymentMethod(request);
      this.setPayments(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setPayments(response: PaymentMethodListResponse) {
    this.payments = response;
  }

  @Action({ rawError: true })
  public async postPaymentCardMethod(request: PostPaymentMethodCardRequest) {
    try {
      const response: PaymentMethodCardListResponse = await ApiPayment.postPaymentMethodCard(request);
      this.setCards(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deletePaymentCardMethod(request: DeletePaymentMethodCardRequest) {
    try {
      const response: PaymentMethodCardListResponse = await ApiPayment.deletePaymentMethodCard(request);
      this.setCards(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async fetchPaymentCardMethod(request: FetchPaymentMethodCardRequest) {
    try {
      const response: PaymentMethodCardListResponse = await ApiPayment.fetchPaymentMethodCard(request);
      this.setCards(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setCards(response: PaymentMethodCardListResponse) {
    this.cards = response;
  }

}

export const paymentModule = getModule(PaymentModule);
