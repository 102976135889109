import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiGrowth from '@/api/ApiGrowth';

import {
  GrowthResponse,
} from '@API/src/component/home/growth/types';
import {
  NurseryGrowthPercentileResponse,
} from '@API/src/component/nursery/growth/types';
import {
  FetchGrowthRequest,
  FetchGrowthPercentileRequest,
} from '@/model/Growth';

export interface GrowthState {
  growths: GrowthResponse[];
  percentile: NurseryGrowthPercentileResponse[];
}

@Module({ dynamic: true, store, name: 'growth', namespaced: true })
class GrowthModule extends VuexModule implements GrowthState {

  public growths: GrowthResponse[] = [];
  public percentile: NurseryGrowthPercentileResponse[] = [];

  @Action({ rawError: true })
  public async fetchGrowth(request: FetchGrowthRequest) {
    try {
      const response: GrowthResponse[] = await ApiGrowth.fetchGrowth(request);
      this.setGrowth(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setGrowth(growths: GrowthResponse[]) {
    this.growths = growths;
  }

  @Action({ rawError: true })
  public async fetchGrowthPercentile(request: FetchGrowthPercentileRequest) {
    try {
      const response: NurseryGrowthPercentileResponse[] = await ApiGrowth.fetchGrowthPercentile(request);
      this.setPercentile(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setPercentile(growths: NurseryGrowthPercentileResponse[]) {
    this.percentile = growths;
  }

}

export const growthModule = getModule(GrowthModule);
