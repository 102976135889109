import ApiClient from '@/api/ApiClient';
import {
  FeedListResponse,
  FeedResponse,
} from '@API/src/component/home/feed/types';
import {
  FetchFeedsRequest,
  PostFeedReadRequest,
} from '@/model/Feed';
import {
  isTypeFeedResponse,
  isTypeFeedListResponse,
} from '@/model/TypeGuard/Feed';

export default class ApiFeed {

  public static async fetchFeed(
    request: FetchFeedsRequest,
  ): Promise<FeedListResponse> {
    try {
      const response = await ApiClient.get(`/feed`, request);
      if (isTypeFeedListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postFeed(
    request: PostFeedReadRequest,
  ): Promise<FeedResponse> {
    try {
      const response = await ApiClient.post(`/feed/${request.feedId}/read`, request);
      if (isTypeFeedResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
