import ApiClient from '@/api/ApiClient';
import {
  isTypeYearlyEventBulkResponseForHome,
} from '@/model/TypeGuard/Events';
import {
  YearlyEventBulkResponseForHome,
  YearlyEventIncludingChildrenResponseForHome,
} from '@API/src/component/home/yearlyEvent/types';
import {
  FetchEventsRequest,
} from '@/model/Events';

export default class ApiEvents {

  public static async fetchEvents(request: FetchEventsRequest): Promise<YearlyEventBulkResponseForHome> {
    try {
      const response = await ApiClient.get(`/events`, request);
      if (isTypeYearlyEventBulkResponseForHome(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
