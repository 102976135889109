
import Vue from 'vue';
import { Toast } from '@/service/SweetAlert';
import rules from '@/service/ValidRules';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';

// model
import { VForm } from '@/model/Vuetify/VForm';
import { PostFamilyByIdRequest } from '@/model/Family';
import { Me } from '@/model/Others/Me';
import { InviteInfo, ParentMeResponse } from 'chaild-entity/lib';

interface DataType {
  parentTypes: Array<{
    text: string;
    value: string;
  }>;
  email: string;
  parentType: string;
  btnLoading: boolean;
}

export default Vue.extend({
  computed: {
    family() {
      return familyModule.family;
    },
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).post_family_by_id_form;
    },
    me() {
      return meModule.me;
    },
    inviteInfo(): InviteInfo[] {
      if (this.me && this.family) {
        const familyId = this.family.familyId;
        const selectedFamilyIndex = this.me.families.findIndex(
          (f) => f.familyId === familyId,
        );
        const selectedFamily = this.me.families[selectedFamilyIndex];
        return selectedFamily.inviteHistory;
      }
      return [];
    },
  },

  data: (): DataType => ({
    parentTypes: [
      {
        text: '母',
        value: 'mother',
      },
      {
        text: '父',
        value: 'father',
      },
      {
        text: '祖母',
        value: 'grandma',
      },
      {
        text: '祖父',
        value: 'granpa',
      },
      {
        text: '叔父',
        value: 'uncle',
      },
      {
        text: '叔母',
        value: 'aunt',
      },
      // {
      //   text: '姉・妹',
      //   value: 'sister',
      // },
      // {
      //   text: '兄・弟',
      //   value: 'brother',
      // },
      {
        text: 'その他',
        value: 'other',
      },
    ],
    email: '',
    parentType: '',
    btnLoading: false,
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting/family', () => {});
    },
    async postFamilyById() {
      if (this.form.validate() && this.family) {
        this.btnLoading = true;
        try {
          const response = await familyModule.postFamilyById({
            familyId: String(this.family.familyId),
            email: this.email,
            parentType: this.parentType,
          });
          meModule.fetchMe();
          Toast.fire({
            icon: 'success',
            title: '招待が完了しました',
          });
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
        }
      }
    },
  },

  mounted() {
    meModule.fetchMe();
  },
});
