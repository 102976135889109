
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import { auth0Module } from '@/store/entityModules/auth0';
import { parentModule } from '@/store/entityModules/parent';

// model
import { VForm } from '@/model/Vuetify/VForm';
import { PostHomeAbsenceRequest } from '@/model/Chat';

interface DataType {
  comment: string | null;
  btnLoading: boolean;
  isActive:boolean;
}

export default Vue.extend({
  name: 'DeleteModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: undefined,
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).delete_form;
    },
    allowMinDate(): string {
      return dayjs().format('YYYY-MM-DD');
    },

  },

  watch: {
    isOpen: {
      async handler(val: boolean) {
        if (val) {
          this.comment = null;
        }
      },
      deep: true,
    },
  },

  data: (): DataType => ({
    comment: '',
    btnLoading: false,
    isActive:false,
  }),

  methods: {
    close() {
      this.$emit('close');
      this.isActive = false;
    },
    changeTrue() {
      this.isActive = true;
    },
    changeFalse() {
      this.isActive = false;
    },
    del(){
      this.$emit('close');
      this.$emit('del');
      this.isActive = false;
      auth0Module.logout();
    },
  },

  async mounted() {},
});
