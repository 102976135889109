
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { Toast } from '@/service/SweetAlert';
import rules from '@/service/ValidRules';

import { familyModule } from '@/store/entityModules/family';

import { VForm } from '@/model/Vuetify/VForm';

interface DataType {
  familyName: string | null;
  postalCode: string | null;
  prefecture: string | null;
  address: string | null;
  building: string | null;
  btnLoading: boolean | null;
}

export default Vue.extend({
  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).put_me_form;
    },
    family() {
      return familyModule.family;
    },
  },

  data: (): DataType => ({
    familyName: null,
    postalCode: null,
    prefecture: null,
    address: null,
    building: null,
    btnLoading: false,
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting/family', () => {});
    },
    async putFamilyById() {
      if (this.form.validate() && this.family) {
        try {
          this.btnLoading = true;
          const response = await familyModule.putFamilyById({
            familyId: String(this.family.familyId),
            familyName: this.familyName,
            postalCode: this.postalCode,
            prefecture: this.prefecture,
            address: this.address,
            building: this.building,
            parents: this.family.parents.map((p) => {
              return {
                parentId: String(p.parentId),
                parentType: p.parentType,
              };
            }),
          });
          this.btnLoading = false;
          await familyModule.fetchFamilyById({
            familyId: this.family.familyId,
          });
          Toast.fire({
            icon: 'success',
            title: '登録が完了しました',
          });
        } catch (error) {
          this.btnLoading = false;
        }
      }
    },
  },

  async mounted() {
    if (this.family) {
      this.familyName = this.family.familyName;
      this.postalCode = this.family.postalCode;
      this.prefecture = this.family.prefecture;
      this.address = this.family.address;
      this.building = this.family.building;
    }
  },
});
