
import Vue from 'vue';
import { VueLoading } from 'vue-loading-template';

import { auth0Module } from '@/store/entityModules/auth0';

export default Vue.extend({
  components: {
    VueLoading,
  },
});
