import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiEvents from '@/api/ApiEvents';
import {
  FetchEventsRequest,
} from '@/model/Events';
import {
  YearlyEventBulkResponseForHome,
  YearlyEventIncludingChildrenResponseForHome,
} from '@API/src/component/home/yearlyEvent/types';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

export interface EventsState {
  events: YearlyEventIncludingChildrenResponseForHome[];
  total: number;
}

@Module({ dynamic: true, store, name: 'events', namespaced: true })
class EventsModule extends VuexModule implements EventsState {

  public events: YearlyEventIncludingChildrenResponseForHome[] = [];
  public total: number = 0;

  public get eventsIncludedDate(): YearlyEventIncludingChildrenResponseForHome[] {
    let arr: YearlyEventIncludingChildrenResponseForHome[] = [];
    // eventのchildrenをdate毎にまとめる
    this.events.map((e) => {
      const date = e.date;
      const index = arr.findIndex((a) => a.date === date);
      if (index === -1) {
        arr.push(e);
      } else {
        arr[index].eventName = `${arr[index].eventName}\n${e.eventName}`;
        arr[index].children.concat(e.children);
      }
    });
    // 卒園・退園済みを省く
    arr.map((a) => {
      a.children = a.children.filter((c) => {
        // 卒園日or退園日の早い方を選出
        let leaveDate: string | null = null;
        if (c.graduationDate && c.quitDate) {
          if (dayjs(c.graduationDate).isAfter(c.quitDate)) {
            leaveDate = c.quitDate;
          } else {
            leaveDate = c.graduationDate;
          }
        } else if (c.graduationDate) {
          leaveDate = c.graduationDate;
        } else if (c.quitDate) {
          leaveDate = c.quitDate;
        }
        // 卒退園日以降の生徒を省く
        if (leaveDate) {
          if (dayjs(a.date).isBefore(leaveDate)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    });
    // 核当園児のいないイベントを削る
    arr = arr.filter((a) => {
      return a.children.length > 0;
    }).sort((a, b) => {
      // 昇順に並び替え
      const aTime = dayjs(a.date);
      const bTime = dayjs(b.date);
      if (dayjs(aTime).isBefore(bTime)) { return -1; }
      if (dayjs(aTime).isAfter(bTime)) { return 1; }
      return 0;
    });
    return arr;
  }

  @Action({ rawError: true })
  public async fetchEvents(request: FetchEventsRequest) {
    try {
      const response = await ApiEvents.fetchEvents(request);
      this.setEvents(response.yearlyEvents);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setEvents(events: YearlyEventIncludingChildrenResponseForHome[]) {
    this.events = events;
  }
}

export const eventsModule = getModule(EventsModule);
