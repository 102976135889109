
import Vue from 'vue';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import { dailyReportModule } from '@/store/entityModules/dailyReport';

import { BreakfastResponse } from '@API/src/component/home/breakfast/types';
import {
  SwipeList,
  SwipeOut,
} from 'vue-swipe-actions';
import { Toast } from '@/service/SweetAlert';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { SwipeListModel } from '@/model/SwipeList/SwipeList';
import BreakfastModal from '@/components/modal/BreakfastModal.vue';

interface DataType {
  isBreakfastDialog: boolean;
}

export default Vue.extend({

  components: {
    SwipeOut,
    SwipeList,
    BreakfastModal,
  },

  props: {
    breakfasts: {
      type: Array,
      default: () => ([]),
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
  },

  computed: {
    swipeList(): SwipeListModel {
      return (this.$refs as any).breakfast_swipe_list;
    },
  },

  data: (): DataType =>({
    isBreakfastDialog: false,
  }),

  methods: {
    minuteFormat(iso: string) {
      return dayjs(iso).format('HH:mm');
    },
    revealFirstRight(index, revealed) {
      if (!revealed) {
        this.swipeList.revealRight(index);
      } else {
        this.swipeList.closeActions(index);
      }
    },
    revealFirstLeft() {
      this.swipeList.revealLeft(0);
    },
    closeFirst() {
      this.swipeList.closeActions(0);
    },
    closeAll() {
      this.swipeList.closeActions();
    },
    async deleteItem(e: BreakfastResponse) {
      Swal.fire({
        title: '記録を削除しますか?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF3F3A',
        cancelButtonColor: '#BDBDBD',
        confirmButtonText: '削除',
        cancelButtonText: 'キャンセル',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.showLoading();
          await dailyReportModule.deleteBreakfast({ breakfastId: e.breakfastId });
          Toast.fire({
            icon: 'success',
            title: '削除が完了しました',
          });
        }
      });
    },
    edited() {
      Toast.fire({
        icon: 'success',
        title: '変更が完了しました',
      });
      this.$emit('edited');
    },
    itemClick(e) {},
    fbClick(e) {},
    sbClick(e) {},
  },

});
