
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import { VueLoading } from 'vue-loading-template';

import { resourceModule } from '@/store/entityModules/resource';
import { chatModule } from '@/store/entityModules/chat';

import { HomeAttachmentResponse } from '@API/src/component/home/attachment/types';
import { HomeChatResponse } from 'chaild-entity/lib';

interface DataType {
  chatId: number;
  childId: number;
  childName: string;
  chat: HomeChatResponse | null;
  fetchLoading: boolean;
  moreFetchLoading: boolean;
  isAttachmentUrls: boolean;
  imgLoading: boolean;
  isImgDialog: boolean;
  notPdfImgSrc: string;
  showingUrls: string[];
}

export default Vue.extend({
  name: 'ReferenceDetail',
  components: {
    VueLoading,
  },

  data: (): DataType => ({
    chatId: NaN,
    childId: NaN,
    childName: '',
    chat: null,
    fetchLoading: false,
    moreFetchLoading: false,
    imgLoading: false,
    isAttachmentUrls: false,
    isImgDialog: false,
    notPdfImgSrc: '',
    showingUrls: [],
  }),

  computed: {},

  methods: {
    routerBack() {
      // this.$router.replace('/record', () => {});
      this.$router.replace({
        path: '/references',
        name: 'References',
        query: {
          childId: String(this.childId),
          childName: this.childName,
        },
      });
    },

    dayFormat(date: string) {
      return dayjs(date).format('YYYY/MM/DD');
    },

    async fetchChat() {
      this.fetchLoading = true;
      const response = await chatModule.fetchChat({
        chatId: this.chatId,
      });
      this.chat = response;
      this.fetchLoading = false;
    },

    insertStr(str, index, insert) {
      return str.slice(0, index) + insert + str.slice(index, str.length);
    },
    formatAmzIso(dateStr: string): string {
      let formatted = dateStr;
      formatted = this.insertStr(formatted, 4, '-');
      formatted = this.insertStr(formatted, 7, '-');
      formatted = this.insertStr(formatted, 13, ':');
      formatted = this.insertStr(formatted, 16, ':');
      return formatted;
    },
    isPresignedUrlExpired(url: string): boolean {
      try {
        const arr = url.split(/[=&]/);
        if (arr.length >= 12) {
          const XAmzDate = dayjs(this.formatAmzIso(arr[5])).locale('ja');
          const XAmzExpires = Number(arr[7]);
          const ExpirationTime = dayjs(XAmzDate).add(XAmzExpires, 'seconds');
          const Now = dayjs();
          const isExpired = Now.isAfter(ExpirationTime);
          return isExpired;
        } else {
          return true;
        }
      } catch (error) {
        return true;
      }
    },
    isPdf(objectKey: string) {
      const arr = objectKey.split('.');
      const ext = arr[arr.length - 1];
      if (ext === 'pdf') {
        return true;
      }
      return false;
    },
    async openImg(chat: HomeChatResponse, index: number) {
      let img = chat.attachmentUrls[index].url;
      const isPdf = this.isPdf(chat.attachmentUrls[index].objectKey);
      if (isPdf) {
        const isImgExpired = this.isPresignedUrlExpired(img);
        if (isImgExpired) {
          this.imgLoading = true;
          const objectKey = chat.attachmentUrls[index].objectKey;
          const imgContent = await resourceModule.postResourceDownload({
            objectKey,
          });
          img = imgContent.preSignedUrl;
          this.imgLoading = false;
        }
        const ua = navigator.userAgent;
        const isIOS =
          ua.indexOf('iPhone') >= 0 ||
          ua.indexOf('iPad') >= 0 ||
          Number(navigator.userAgent.indexOf('iPod')) >= 0;
        if (isIOS) {
          if ((window as any).webkit.messageHandlers) {
            (window as any).webkit.messageHandlers.pdf.postMessage(`${[img]}`);
          }
        } else {
          this.isAttachmentUrls = true;
          this.showingUrls = [img];
        }
      } else {
        const isImgExpired = this.isPresignedUrlExpired(
          chat.attachmentUrls[index].url,
        );
        if (isImgExpired) {
          const objectKey = chat.attachmentUrls[index].objectKey;
          const imgContent = await resourceModule.postResourceDownload({
            objectKey,
          });
          img = imgContent.preSignedUrl;
        }
        this.notPdfImgSrc = img;
        this.isImgDialog = true;
      }
    },
    closeChatImg() {
      this.isAttachmentUrls = false;
      this.showingUrls = [];
    },
  },

  async mounted() {
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (
      typeof this.$route.query.childId === 'string' &&
      typeof this.$route.query.chatId === 'string'
    ) {
      this.childId = Number(this.$route.query.childId);
      this.chatId = Number(this.$route.query.chatId);
      this.fetchChat();
    } else {
      this.routerBack();
    }
  },
});
