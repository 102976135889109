
import Vue from 'vue';

interface DataType {
  defaultZeroValue: string;
  hours: string[];
  minutes: string[];
  active: boolean;
  // value: string;
}

enum OnChangeValueType {
  Hour = 'hour',
  Minute = 'minute',
}

export default Vue.extend({
  name: 'TimePicker',

  data: (): DataType => ({
    defaultZeroValue: '00',
    hours: [],
    minutes: [],
    active: false,
  }),

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    splitValue(): string[] {
      return this.value.split(':');
    },
    hourValue(): string {
      return this.splitValue[0] || this.defaultZeroValue;
    },
    minuteValue(): string {
      return this.splitValue[1] || this.defaultZeroValue;
    },
    hourOptions(): string[] {
      const length = 24;
      return Array.from({ length }, (value, index) => index.toString().padStart(2, '0'));
    },
    minutesOptions(): string[] {
      return ['00', '10', '20', '30', '40', '50'];
    },
  },

  methods: {
    generateNumbStringArray(length: number): string[] {
      return Array.from({ length }, (value, index) => index.toString().padStart(2, '0'));
    },
    valueTemplate(hour: string, minute: string): string {
      return `${hour}:${minute}`;
    },
    onChangeValue(value: string, type: OnChangeValueType): void {
      if (type === OnChangeValueType.Hour) {
        this.$emit('input', this.valueTemplate(value, this.minuteValue));
      } else if (type === OnChangeValueType.Minute) {
        this.$emit('input', this.valueTemplate(this.hourValue, value));
        this.active = false;
      }
    },
  },

});
