
import Vue from 'vue';
import JwtDecode from 'jwt-decode';
import LocalDataService from '@/service/LocalDataServices';
import { when } from '@/service/Switch';

import { VueLoading } from 'vue-loading-template';

// store
import { auth0Module } from '@/store/entityModules/auth0';
import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';
import { pusherModule } from '@/store/entityModules/pusher';

// model
import { ErrorType } from '@/api/ApiClient';
import { Authorizer } from '@API/src/common/entity/aws/authorize';
import ApiVersioning from './api/ApiVersioning';

export default Vue.extend({
  name: 'App',

  components: {
    VueLoading,
  },

  data: () => ({
    loading: false,
    bottomNavValue: 0,
    menus: [
      { title: 'ホーム', icon: 'mdi-home', url: '/home', isDisabled: false },
      { title: '記録', icon: 'mdi-pencil', url: '/record', isDisabled: false },
      {
        title: '行事予定',
        icon: 'mdi-calendar',
        url: '/calendar',
        isDisabled: false,
      },
      { title: 'MEMORU', icon: 'mdi-multimedia', url: '/memoru', isDisabled: false },
      { title: '設定', icon: 'mdi-cog', url: '/setting', isDisabled: false },
    ],
  }),

  computed: {
    bottomNavActive() {
      if (
        this.routePath === '' ||
        this.routePath === '/' ||
        this.routePath === '/signin' ||
        this.routePath === '/callback' ||
        this.routePath === '/chat' ||
        this.routePath === '/create-user' ||
        this.routePath === '/create-family' ||
        this.routePath === '/record/day' ||
        this.routePath === '/record/calendar' ||
        this.routePath === '/parent/edit' ||
        this.routePath === '/child/edit' ||
        this.routePath === '/family/edit' ||
        this.routePath === '/maintenance' ||
        this.routePath === '/verify/email' ||
        this.routePath === '/error/email/verify'
      ) {
        return false;
      } else {
        return true;
      }
    },
    routePath(): string {
      return this.$route.path;
    },
  },

  async created() {
    ApiVersioning.checkVersion();

    this.loading = true;
    try {
      await auth0Module.initClient();
      const isAuthenticated = await auth0Module.isAuthenticated();
      if (isAuthenticated) {
        await meModule.fetchMe();
        this.isToFirstStep();
      } else {
        try {
          const response = await auth0Module.getTokenSilently();
          await meModule.fetchMe();
          this.isToFirstStep();
        } catch (error) {
          if (
            this.$route.query &&
            this.$route.query.code &&
            this.$route.query.state
          ) {
            try {
              await auth0Module.handleAuth0();
            } catch (error) {
              window.location.reload();
            }
          } else if (
            this.$route.query.error &&
            this.$route.query.error === 'unauthorized'
          ) {
            this.$router.replace('/error/email/verify', () => {});
          } else {
            await auth0Module.signinProcess();
          }
        }
      }
    } catch (error) {
      switch (error) {
        case ErrorType.FIRSTLOGIN:
          try {
            const getAuth0User = await auth0Module.getAuth0User();
            if (getAuth0User) {
              const postMeResponse = await meModule.postMe({
                firstName: getAuth0User.family_name
                  ? getAuth0User.family_name
                  : null,
                lastName: getAuth0User.given_name
                  ? getAuth0User.given_name
                  : null,
                firstNameKana: null,
                lastNameKana: null,
                imageUrl: getAuth0User.picture ? getAuth0User.picture : null,
                birth: null,
                bloodType: null,
                tel: null,
              });
              this.isCreateFamily();
            }
            await auth0Module.signinProcess();
          } catch {
            //await auth0Module.signinProcess();
            this.loading = false;
            auth0Module.logout();
          }
      }
    } finally {
      this.loading = false;
    }
  },

  mounted() {
    window.receivedChatPush = (chatId) => {
      // do something
    };

    const setFillHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    let vw = window.innerWidth;

    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) {
        // 画面の横幅にサイズ変動がないので処理を終える
        return;
      }

      // 画面の横幅のサイズ変動があった時のみ高さを再計算する
      vw = window.innerWidth;
      setFillHeight();
    });

    // 初期化
    setFillHeight();
  },

  methods: {
    urlReplace(path: string) {
      localStorage.removeItem("selectedDate");
      this.$router.replace(path, () => {});
    },
    async isCreateFamily() {
      if (meModule.me && meModule.me.families.length > 0) {
        const sessionsToken = await auth0Module.getAuth0Token();
        const parentId = String(meModule.me.parentId);
        LocalDataService.setParentId(parentId);
        LocalDataService.setBeamsTokenAndId(`${sessionsToken},${parentId}`);
        await Promise.all([
          familyModule.fetchFamilyById({
            familyId: meModule.me.families[0].familyId,
          }),
          pusherModule.subscribe(),
        ]);
        if (
          this.routePath === '' ||
          this.routePath === '/' ||
          this.routePath === '/signin' ||
          this.routePath === '/callback' ||
          this.routePath === '/create-user' ||
          this.routePath === '/create-family'
        ) {
          this.$router.replace('/home', () => {});
        }
        this.loading = false;
      } else {
        this.$router.replace('/create-family', () => {});
        this.loading = false;
      }
    },
    async isToFirstStep() {
      try {
        const meResponse = await meModule.fetchMe();
        this.isCreateFamily();
      } catch (error) {
        when(error)
          .on(
            (v) => v === ErrorType.FIRSTLOGIN,
            async () => {
              const getAuth0User = await auth0Module.getAuth0User();
              if (getAuth0User) {
                const postMeResponse = await meModule.postMe({
                  firstName: getAuth0User.family_name
                    ? getAuth0User.family_name
                    : null,
                  lastName: getAuth0User.given_name
                    ? getAuth0User.given_name
                    : null,
                  firstNameKana: null,
                  lastNameKana: null,
                  imageUrl: getAuth0User.picture ? getAuth0User.picture : null,
                  birth: null,
                  bloodType: null,
                  tel: null,
                });
                this.isCreateFamily();
              }
            },
          )
          .on(
            (v) => v === ErrorType.NO_TOKEN,
            async () => {
              await auth0Module.signinProcess();
            },
          )
          .otherwise(() => {});
      } finally {
      }
    },

    appUpdate() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            registration.update();
          }
        });
      }
    },
  },
});
