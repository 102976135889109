
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import { VueLoading } from 'vue-loading-template';

import { attachmentModule } from '@/store/entityModules/attachment';
import { resourceModule } from '@/store/entityModules/resource';
import { chatModule } from '@/store/entityModules/chat';

import {
  HomeAttachmentResponse,
} from '@API/src/component/home/attachment/types';

interface DataType {
  childId: number;
  childName: string;
  fetchLoading: boolean;
  moreFetchLoading: boolean;
  isFetchMoreAttachment: boolean;
  isAttachmentUrls: boolean;
  imgLoading: boolean;
  showingUrls: string[];
  showPicker: boolean;
  selectedDate: string;
}

export default Vue.extend({
  components: {
    VueLoading,
  },

  data: (): DataType =>({
    childId: NaN,
    childName: '',
    fetchLoading: false,
    moreFetchLoading: false,
    isFetchMoreAttachment: true,
    imgLoading: false,
    isAttachmentUrls: false,
    showingUrls: [],
    showPicker: false,
    selectedDate: localStorage.selectedDate,
  }),

  computed: {
    groupChats() {
      return chatModule.groupChats;
    },
    groupChatsTotal() {
      return chatModule.groupChatsCount;
    },
  },

  methods: {
    routerBack() {
      localStorage.removeItem("selectedDate");
      this.$router.replace('/record', () => {});
    },

    toDetail(chatId: number) {
      this.$router.push({ path: '/references/detail', query: {
        chatId: String(chatId),
        childId: String(this.childId),
        childName: this.childName,
      } });
    },

    dayFormat(date: string) {
      return dayjs(date).format('YYYY/MM/DD');
    },

    async fetchGroupChats() {
      this.fetchLoading = true;
      chatModule.removeGroupChats();
      await chatModule.fetchGroupChats({
        childId: this.childId,
        limit: 10,
        skip: 0,
        date: this.selectedDate,
      });
      if (this.groupChats.length >= this.groupChatsTotal) {
        this.isFetchMoreAttachment = false;
      }
      this.fetchLoading = false;
    },

    async fetchMoreAttachment() {
      this.moreFetchLoading = true;
      await chatModule.fetchGroupChats({
        childId: this.childId,
        limit: 10,
        skip: this.groupChats.length,
        date: this.selectedDate,
      });
      if (this.groupChats.length >= this.groupChatsTotal) {
        this.isFetchMoreAttachment = false;
      }
      this.moreFetchLoading = false;
    },

    insertStr(str, index, insert) {
      return str.slice(0, index) + insert + str.slice(index, str.length);
    },
    formatAmzIso(dateStr: string): string {
      let formatted = dateStr;
      formatted = this.insertStr(formatted, 4, '-');
      formatted = this.insertStr(formatted, 7, '-');
      formatted = this.insertStr(formatted, 13, ':');
      formatted = this.insertStr(formatted, 16, ':');
      return formatted;
    },
    isPresignedUrlExpired(url: string): boolean {
      try {
        const arr = url.split(/[=&]/);
        if (arr.length >= 12) {
          const XAmzDate = dayjs(this.formatAmzIso(arr[5])).locale('ja');
          const XAmzExpires = Number(arr[7]);
          const ExpirationTime = dayjs(XAmzDate).add(XAmzExpires, 'seconds');
          const Now = dayjs();
          const isExpired = Now.isAfter(ExpirationTime);
          return isExpired;
        } else {
          return true;
        }
      } catch (error) {
        return true;
      }
    },
    async openImg(attachment: HomeAttachmentResponse) {
      let img = attachment.attachmentUrl;
      const isImgExpired = this.isPresignedUrlExpired(img);
      if (isImgExpired) {
        this.imgLoading = true;
        const objectKey = attachment.objectKey;
        const imgContent = await resourceModule.postResourceDownload({
          objectKey,
        });
        img = imgContent.preSignedUrl;
        this.imgLoading = false;
      }
      const ua = navigator.userAgent;
      const isIOS = ua.indexOf('iPhone') >= 0
        || ua.indexOf('iPad') >= 0
        || Number(navigator.userAgent.indexOf('iPod')) >= 0;
      if (isIOS) {
        if ((window as any).webkit.messageHandlers) {
          (window as any).webkit.messageHandlers.pdf.postMessage(`${[img]}`);
        }
      } else {
        this.isAttachmentUrls = true;
        this.showingUrls = [img];
      }
    },
    closeChatImg() {
      this.isAttachmentUrls = false;
      this.showingUrls = [];
    },
    showDatePicker() {
      this.showPicker = true;
    },
    dateSelected(date) {
      localStorage.selectedDate = dayjs(date).format('YYYY-MM');
      this.selectedDate = localStorage.selectedDate;

      if (typeof this.$route.query.childId === 'string') {
        this.childId = Number(this.$route.query.childId);
        this.fetchGroupChats();
      } else {
        this.routerBack();
      }

      this.showPicker = false;
    },
  },

  async mounted() {
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
      this.fetchGroupChats();
    } else {
      this.routerBack();
    }

    if (localStorage.selectedDate) {
      this.selectedDate = localStorage.selectedDate;
    }

  },
  
});
