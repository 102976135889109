
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import { dailyReportModule } from '@/store/entityModules/dailyReport';

// model
import { VForm } from '@/model/Vuetify/VForm';

interface DataType {
  content: string;
  btnLoading: boolean;
}

export default Vue.extend({
  name: 'DinnerModal',

  props: {
    type: {
      type: String,
      default: 'post', // "post" or "edit"
    },
    dinnerId: {
      type: Number,
      default: NaN,
    },
    editContent: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).post_dinner_form;
    },
  },

  watch: {
    isOpen: {
      handler(val: boolean) {
        if (val) {
          this.content = '';
        }
      },
      deep: true,
    },
  },

  data: (): DataType =>({
    content: '',
    btnLoading: false,
  }),

  methods: {
    close() {
      this.$emit('close');
    },
    async clickPost() {
      if (this.form.validate()) {
        this.btnLoading = true;
        try {
          if (this.type === 'post') {
            await dailyReportModule.postDinner({
              childId: this.childId,
              date: this.selectedDate,
              content: this.content,
            });
          } else if (this.type === 'edit') {
            await dailyReportModule.putDinner({
              dinnerId: this.dinnerId,
              content: this.content,
            });
          }
          this.$emit('posted');
          this.$emit('close');
          this.btnLoading = false;
        } catch (error) {
          this.$emit('close');
          this.btnLoading = false;
        }
      }
    },
  },

  mounted() {
    this.content = this.editContent;
  },
});
