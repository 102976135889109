import {
  QrCodeResponse,
  HomeAttendanceSchema,
} from '@API/src/component/home/attendance/types';

// TypeGuard
export const isTypeQrCodeResponse = (
  arg: any,
): arg is QrCodeResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.qrCode === 'string';

export const isTypeHomeAttendanceSchemaResponse = (
  arg: any,
): arg is HomeAttendanceSchema =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.date === 'string';
