import {
  PrivateChannelAuthHomeResponse,
  PushAuthHomeResponse,
} from '@API/src/component/home/pusher/types';

export const isTypePrivateChannelAuthHomeResponse = (
  arg: any,
): arg is PrivateChannelAuthHomeResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.auth === 'string';

export const isTypePushAuthHomeResponse = (
  arg: any,
): arg is PushAuthHomeResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.token === 'string';
