
/* tslint:disable:max-classes-per-file */
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

import { Authorizer } from '@API/src/common/entity/aws/authorize';

const KEY_LOCAL_VERSION = 'CHAILD_LOCAL_VERSION';
const KEY_ACCESS_TOKEN = 'CHAILD_ACCESS_TOKEN';
const KEY_AUTHORIZER = 'CHAILD_AUTHORIZER';
const KEY_CHILD_ID = 'CHAILD_CHILD_ID';
const KEY_CURRENT_PARENT_ID = 'CHAILD_CURRENT_PARENT_ID';
const KEY_BEAMS_TOKEN_AND_ID = 'CHAILD_BEAMS_TOKEN_AND_ID';

class LocalAuthorizer implements Authorizer {
  public given_name?: string;
  public family_name?: string;
  public nickname?: string;
  public name?: string;
  public picture?: string;
  public locale?: string;
  public updated_at: string = '';
  public email: string = '';
  public email_verified?: boolean;
  public exception?: string | undefined;
  public iss: string = '';
  public sub: string = '';
  public aud: string = '';
  public iat: number = 0;
  public exp: number = 0;
  public at_hash: string = '';
  public nonce: string = '';
  public claims: Authorizer | undefined;
}

export default class LocalDataService {

  public static setLocalVersion(version: string) {
    localStorage.setItem(`${KEY_LOCAL_VERSION}`, version);
    return version;
  }

  public static getLocalVersion(): string | null {
    const saved = localStorage.getItem(`${KEY_LOCAL_VERSION}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setAccessToken(accessToken: string) {
    localStorage.setItem(`${KEY_ACCESS_TOKEN}`, accessToken);
    return accessToken;
  }

  public static getAccessToken(): string | null {
    const saved = localStorage.getItem(`${KEY_ACCESS_TOKEN}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setAuthorizer(authorizer: Authorizer) {
    localStorage.setItem(`${KEY_AUTHORIZER}`, JSON.stringify(authorizer));
    return authorizer;
  }

  public static getAuthorizer(): Authorizer {
    const saved = localStorage.getItem(`${KEY_AUTHORIZER}`);
    if (saved) {
      const authorizer = JSON.parse(saved) as Authorizer;
      return authorizer;
    } else {
      return new LocalAuthorizer();
    }
  }

  public static setChildId(childId: string) {
    localStorage.setItem(`${KEY_CHILD_ID}`, childId);
    return childId;
  }

  public static getChildId(): string | null {
    const saved = localStorage.getItem(`${KEY_CHILD_ID}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setParentId(parentId: string) {
    localStorage.setItem(`${KEY_CURRENT_PARENT_ID}`, parentId);
    return parentId;
  }

  public static getParentId(): string | null {
    const saved = localStorage.getItem(`${KEY_CURRENT_PARENT_ID}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setBeamsTokenAndId(tokenAndId: string) {
    localStorage.setItem(`${KEY_BEAMS_TOKEN_AND_ID}`, tokenAndId);
    return tokenAndId;
  }

  public static getBeamsTokenAndId(): string | null {
    const saved = localStorage.getItem(`${KEY_BEAMS_TOKEN_AND_ID}`);
    if (saved) {
      return saved;
    }
    return null;
  }
}
