import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiFeed from '@/api/ApiFeed';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import {
  FeedListResponse,
  FeedResponse,
} from '@API/src/component/home/feed/types';
import {
  FetchFeedsRequest,
  PostFeedReadRequest,
} from '@/model/Feed';

export interface FeedState {
  feeds: FeedResponse[];
}

@Module({ dynamic: true, store, name: 'feed', namespaced: true })
class FeedModule extends VuexModule implements FeedState {

  public feeds: FeedResponse[] = [];

  @Action({ rawError: true })
  public async fetchFeed(request: FetchFeedsRequest) {
    try {
      const response: FeedListResponse = await ApiFeed.fetchFeed(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setFeed(feeds: FeedResponse[]) {
    this.feeds = feeds;
  }

  @Mutation
  public addFeed(feeds: FeedResponse[]) {
    this.feeds.concat(feeds);
  }

  @Action({ rawError: true })
  public async postFeed(request: PostFeedReadRequest) {
    try {
      const response = await ApiFeed.postFeed(request);
      return true;
    } catch (error) {
      throw error;
    }
  }

}

export const feedModule = getModule(FeedModule);
