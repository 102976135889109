import ApiClient from '@/api/ApiClient';
import {
  ParentMeResponse,
} from '@API/src/component/home/me/types';
import {
  PostMeRequest,
  PutMeRequest,
  DelMeRequest,
} from '@/model/Me';
import {
  isTypeParentMeResponse,
} from '@/model/TypeGuard/Me';

export default class ApiMe {

  public static async fetchMe(): Promise<ParentMeResponse> {
    try {
      const response = await ApiClient.get(`/me`, null);
      if (isTypeParentMeResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postMe(
    request: PostMeRequest,
  ): Promise<ParentMeResponse> {
    try {
      const response = await ApiClient.post(`/me`, request);
      if (isTypeParentMeResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async putMe(request: PutMeRequest) {
    try {
      const response = await ApiClient.put(`/me`, request);
      if (isTypeParentMeResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async delMe(request: DelMeRequest) : Promise<ParentMeResponse> {
    try {
      const response = await ApiClient.put(`/me/delete`, request);
      if (isTypeParentMeResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
}
