import ApiClient from '@/api/ApiClient';
import LocalDataService from '@/service/LocalDataServices';
import {
  HomeApplicationVersionResponse,
} from '@API/src/component/home/versioning/types';
import {
  isTypeHomeApplicationVersionResponse,
} from '@/model/TypeGuard/Versioning';

export default class ApiVersioning {
  public static async checkVersion(): Promise<HomeApplicationVersionResponse> {
    try {
      const response = await ApiClient.get(`/current-version`, null, false);
      if (response && isTypeHomeApplicationVersionResponse(response)) {
        const currentVersion = response.currentVersion;
        const localVersion = LocalDataService.getLocalVersion();

        if (currentVersion && localVersion !== currentVersion) {
          LocalDataService.setLocalVersion(currentVersion);
          window.navigator.serviceWorker.getRegistrations()
            .then((registrations) => {
              for (const registration of registrations) {
                registration.unregister();
              }
            });
          window.location.reload();
        }
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
}
