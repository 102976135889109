import { render, staticRenderFns } from "./FamilyCreate.vue?vue&type=template&id=846c0ff0&scoped=true"
import script from "./FamilyCreate.vue?vue&type=script&lang=ts"
export * from "./FamilyCreate.vue?vue&type=script&lang=ts"
import style0 from "./FamilyCreate.vue?vue&type=style&index=0&id=846c0ff0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "846c0ff0",
  null
  
)

export default component.exports