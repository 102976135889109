import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiChat from '@/api/ApiChat';

import {
  HomeChatResponse,
  HomeChatListResponse,
  HomeAbsenceResponse,
  HomePickupResponse,
  ChatChoiceResponse,
  ChatParentChoiceResponse,
  HomeGroupChatResponse,
  HomeGroupChatListResponse,
} from '@API/src/component/home/chat/types';
import {
  FetchChatRequest,
  FetchChatsRequest,
  PostChatRequest,
  FetchHomeAbsenceRequest,
  PostHomeAbsenceRequest,
  FetchChatsChoicesRequest,
  FetchHomePickupRequest,
  PutHomePickupRequest,
  PostChatsConfirmRequest,
  DeleteChatsRequest,
  DeleteAbsenceRequest,
  DeletePickupRequest,
  FetchGroupChatsRequest,
} from '@/model/Chat';

export interface ChatState {
  chats: HomeChatResponse[];
  unReadCount: {
    beforeTheDate: number;
    date: number;
    afterTheDate: number;
  };
  chatAbsence: HomeAbsenceResponse | null;
  parentChoice: ChatParentChoiceResponse[];
  chatPickup: HomePickupResponse | null;
  chatInput: string;
  groupChats: HomeGroupChatResponse[];
  groupChatsCount: number;
}

@Module({ dynamic: true, store, name: 'chat', namespaced: true })
class ChatModule extends VuexModule implements ChatState {

  public chats: HomeChatResponse[] = [];
  public unReadCount: {
    beforeTheDate: number;
    date: number;
    afterTheDate: number;
  } = {
      beforeTheDate: 0,
      date: 0,
      afterTheDate: 0,
    };
  public chatAbsence: HomeAbsenceResponse | null = null;
  public parentChoice: ChatParentChoiceResponse[] = [];
  public chatPickup: HomePickupResponse | null = null;
  public chatInput: string = '';
  public groupChats: HomeGroupChatResponse[] = [];
  public groupChatsCount: number = 0;

  public get chatsSorted(): HomeChatResponse[] {
    return this.chats.sort((c1, c2) => {
      if (c1.createdAt > c2.createdAt) { return 1; } else if (c1.createdAt < c2.createdAt) { return -1; } else { return 0; }
    });
  }

  @Mutation
  public setChatInput(input: string) {
    this.chatInput = input;
  }

  @Action({ rawError: true })
  public async fetchChat(request: FetchChatRequest) {
    try {
      const response = await ApiChat.fetchChat(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async fetchChats(request: FetchChatsRequest) {
    try {
      const response = await ApiChat.fetchChats(request);
      this.setChats(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChats(response: HomeChatListResponse) {
    this.chats = response.chats;
    this.unReadCount = response.unReadCount;
  }

  @Action({ rawError: true })
  public async fetchGroupChats(request: FetchGroupChatsRequest) {
    try {
      const response = await ApiChat.fetchGroupChats(request);
      this.setGroupChats(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setGroupChats(response: HomeGroupChatListResponse) {
    this.groupChats = this.groupChats.concat(response.items);
    this.groupChatsCount = response.totalItemsCount;
  }

  @Mutation
  public removeGroupChats() {
    this.groupChats = [];
    this.groupChatsCount = 0;
  }

  @Action({ rawError: true })
  public async postChats(request: PostChatRequest) {
    try {
      const response = await ApiChat.postChats(request);
      this.addChat(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public addChat(chat: HomeChatResponse) {
    //this.chats.push(chat);
    const index = this.chats.findIndex(c => c.chatId === chat.chatId);
    if (index !== -1) {
      // チャットが既に存在する場合は置き換える
      this.chats.splice(index, 1, chat);
    } else {
      // チャットが存在しない場合は追加する
      this.chats.push(chat);
    }
  }

  @Action({ rawError: true })
  public async fetchHomeAbsence(request: FetchHomeAbsenceRequest) {
    try {
      const response = await ApiChat.fetchHomeAbsence(request);
      this.setChatsAbsence(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatsAbsence(chatAbsence: HomeAbsenceResponse) {
    this.chatAbsence = chatAbsence;
  }

  @Action({ rawError: true })
  public async postHomeAbsence(request: PostHomeAbsenceRequest) {
    try {
      const response = await ApiChat.postHomeAbsence(request);
      // if (request.date) {
      //   const absence = response.find(r => r.date === request.date)
      //   if (absence) this.setChatsAbsence(absence);
      // }
      // this.setChatsAbsence(response)
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async fetchChatsChoices() {
    try {
      const response = await ApiChat.fetchChatsChoices();
      this.setChatsChoices(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatsChoices(chatChoices: ChatChoiceResponse) {
    this.parentChoice = chatChoices.parentIds;
  }

  @Action({ rawError: true })
  public async fetchHomePickup(request: FetchHomePickupRequest) {
    try {
      const response = await ApiChat.fetchChatsPickup(request);
      this.setChatsPickup(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatsPickup(chatsPickup: HomePickupResponse) {
    this.chatPickup = chatsPickup;
  }

  @Action({ rawError: true })
  public async putChatsPickup(request: PutHomePickupRequest) {
    try {
      const response = await ApiChat.putChatsPickup(request);
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postChatsConfirm(request: PostChatsConfirmRequest) {
    try {
      const response = await ApiChat.postChatsConfirm(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChat(request: DeleteChatsRequest) {
    try {
      this.removeChat(request.chatId);
      const response = await ApiChat.deleteChat(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeChat(chatId: number) {
    this.chats = this.chats.filter((c) => {
      if (c.chatId !== chatId) { return c; }
    });
  }

  @Action({ rawError: true })
  public async deleteAbsence(request: DeleteAbsenceRequest) {
    try {
      const response = await ApiChat.deleteAbsence(request);
      this.removeAbsence();
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeAbsence() {
    if (this.chatAbsence) {
      this.chatAbsence.reason = null;
    } else {
      this.chatAbsence = null;
    }
  }

  @Action({ rawError: true })
  public async deletePickup(request: DeletePickupRequest) {
    try {
      const response = await ApiChat.deletePickup(request);
      this.removePickup();
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removePickup() {
    if (this.chatPickup) {
      this.chatPickup.reason = null;
    } else {
      this.chatPickup = null;
    }
  }

}

export const chatModule = getModule(ChatModule);
