import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export interface RouteState {
  prevRoutePath: string | null;
}

@Module({ dynamic: true, store, name: 'route', namespaced: true })
class RouteModule extends VuexModule implements RouteState {
  public prevRoutePath: string | null = null;

  @Mutation
  public setPrevRoutePath(path: string | null) {
    this.prevRoutePath = path;
  }
}

export const routeModule = getModule(RouteModule);
