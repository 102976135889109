
import Vue from 'vue';

import { VueLoading } from 'vue-loading-template';

import { familyModule } from '@/store/entityModules/family';
import { auth0Module } from '@/store/entityModules/auth0';

// model
import { PostFamilyRequest } from '@/model/Family';

import parentTypes from '@/service/parentTypes';

interface DataType {
  postFamilyRequest: PostFamilyRequest;
  btnLoading: boolean;
}

export default Vue.extend({
  components: {
    VueLoading,
  },

  data: () => ({
    loading: true,
    btnLoading: false,
    postFamilyRequest: {
      childCode: '',
      password: '',
      parentType: '',
    },
  }),

  computed: {
    parentTypes() {
      return parentTypes;
    },
  },

  methods: {
    async postFamily() {
      try {
        this.btnLoading = true;
        const response = await familyModule.postFamily(this.postFamilyRequest);
        if (response) {
          this.$router.push('/home', () => {});
          this.btnLoading = false;
        }
      } catch (error) {
        this.btnLoading = false;
      }
    },
    logout() {
      auth0Module.logout();
    },
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
});
