
import Vue from 'vue';
import Swal from 'sweetalert2';
import rules from '@/service/ValidRules';
import { Toast } from '@/service/SweetAlert';

import { familyModule } from '@/store/entityModules/family';

// model
import { VForm } from '@/model/Vuetify/VForm';

export default Vue.extend({

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).search_child_form;
    },
    family() {
      return familyModule.family;
    },
  },

  data: () => ({
    childCode: '',
    password: '',
    btnLoading: false,
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting/family', () => {});
    },
    async searchChild() {
      if (this.form.validate() && this.family) {
        this.btnLoading = true;
        try {
          await familyModule.fetchChildrenSearch({
            childCode: this.childCode,
            password: this.password,
          });
          await familyModule.postFamilyChildRequest({
            familyId: String(this.family.familyId),
            childCode: this.childCode,
            password: this.password,
          });
          Toast.fire({
            icon: 'success',
            title: '登録が完了しました',
          });
          this.routerBack();
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
        }
      }
    },
  },

});
