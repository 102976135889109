
import Vue from 'vue';
export default Vue.extend({
  created() {
    const ua = navigator.userAgent;
    const isIOS =
      ua.indexOf('iPhone') >= 0 ||
      ua.indexOf('iPad') >= 0 ||
      Number(navigator.userAgent.indexOf('iPod')) >= 0;
    if (isIOS) {
      const url = process.env.VUE_APP_IOS_VERIFY_REDIRECT;
      if (url) {
        window.location.href = url;
      }
    } else {
      const url = process.env.VUE_APP_BASE_URL;
      if (url) {
        window.location.href = url;
      }
    }
  },
});
