import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiFamily from '@/api/ApiFamily';
import {
  SearchChildrenResponse,
  ParentResponse,
  ChildResponse,
  FamilyResponse,
} from '@API/src/component/home/family/types';
import {
  FetchChildrenSearchRequest,
  PostFamilyRequest,
  FetchFamilyByIdRequest,
  PostFamilyByIdRequest,
  PutFamilyByIdRequest,
  PostFamilyChildRequest,
} from '@/model/Family';

export interface FamilyState {
  family: FamilyResponse | null;
}

@Module({ dynamic: true, store, name: 'family', namespaced: true })
class FamilyModule extends VuexModule implements FamilyState {

  public family: FamilyResponse | null = null;

  @Action({ rawError: true })
  public async fetchChildrenSearch(request: FetchChildrenSearchRequest) {
    try {
      const response = await ApiFamily.fetchChildrenSearch(request);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async fetchFamilyById(request: FetchFamilyByIdRequest) {
    try {
      const response = await ApiFamily.fetchFamilyById(request);
      this.setFamily(response);
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postFamily(request: PostFamilyRequest) {
    try {
      const response = await ApiFamily.postFamily(request);
      this.setFamily(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postFamilyById(request: PostFamilyByIdRequest) {
    try {
      const response = await ApiFamily.postFamilyById(request);
      this.setFamily(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putFamilyById(request: PutFamilyByIdRequest) {
    try {
      const response = await ApiFamily.putFamilyById(request);
      this.setFamily(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postFamilyChildRequest(request: PostFamilyChildRequest) {
    try {
      const response = await ApiFamily.postFamilyChildRequest(request);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setFamily(family: FamilyResponse) {
    this.family = family;
  }

  @Mutation
  public editChild(child: ChildResponse) {
    if (this.family) {
      this.family.children = this.family.children.map((c) => {
        if (c.childId === child.childId) {
          c.firstName = child.firstName;
          c.lastName = child.lastName;
          c.firstNameKana = child.firstNameKana;
          c.lastNameKana = child.lastNameKana;
          c.imageUrl = child.imageUrl;
          c.nurseryName = child.nurseryName;
          c.graduationDate = child.graduationDate;
          c.quitDate = child.quitDate;
          c.gender = child.gender;
          c.dateOfBirth = child.dateOfBirth;
        }
        return c;
      });
    }
  }
}

export const familyModule = getModule(FamilyModule);
