import { DailyReportResponse } from '@API/src/component/home/dailyReport/types';
import { DefecationResponse } from '@API/src/component/home/defecation/types';
import { TemperatureResponse } from '@API/src/component/home/temperature/types';
import { MilkResponse } from '@API/src/component/home/milk/types';
import { MilkChoicesResponse } from '@API/src/component/nursery/milk/types';
import { SleepResponse } from '@API/src/component/home/sleep/types';
import { DinnerResponse } from '@API/src/component/home/dinner/types';
import { BreakfastResponse } from '@API/src/component/home/breakfast/types';

export const isTypeDailyReportResponse = (
  arg: any,
): arg is DailyReportResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.date === 'string' &&
  typeof arg.home === 'object' &&
  typeof arg.nursery === 'object';

export const isTypeDailyReportsResponse = (
  arg: any,
): arg is DailyReportResponse[] =>
  Array.isArray(arg) &&
  (arg.length === 0 ||isTypeDailyReportResponse(arg[0]));

export const isTypeDefecationResponse = (
  arg: any,
): arg is DefecationResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.defecationId === 'number' &&
  typeof arg.date === 'string' &&
  typeof arg.recordedAt === 'string' &&
  typeof arg.state === 'string' &&
  typeof arg.createdAt === 'string';

export const isTypeDefecationsResponse = (
  arg: any,
): arg is DefecationResponse[] =>
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeDefecationResponse(arg[0]));

export const isTypeTemperatureResponse = (
  arg: any,
): arg is TemperatureResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.temperatureId === 'number' &&
  typeof arg.date === 'string' &&
  typeof arg.recordedAt === 'string' &&
  typeof arg.temperature === 'number' &&
  typeof arg.createdAt === 'string';

export const isTypeTemperaturesResponse = (
  arg: any,
): arg is TemperatureResponse[] =>
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeTemperatureResponse(arg[0]));

export const isTypeMilkResponse = (
  arg: any,
): arg is MilkResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.milkId === 'number' &&
  typeof arg.date === 'string' &&
  typeof arg.recordedAt === 'string' &&
  typeof arg.createdAt === 'string';

export const isTypeMilksResponse = (
  arg: any,
): arg is MilkResponse[] =>
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeMilkResponse(arg[0]));

export const isTypeMilkChoicesResponse = (
  arg: any,
): arg is MilkChoicesResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.amountIds);

export const isTypeSleepResponse = (
  arg: any,
): arg is SleepResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.sleepId === 'number' &&
  typeof arg.date === 'string' &&
  typeof arg.from === 'string' &&
  typeof arg.to === 'string' &&
  typeof arg.createdAt === 'string';

export const isTypeSleepsResponse = (
  arg: any,
): arg is SleepResponse[] =>
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeSleepResponse(arg[0]));

export const isTypeDinnerResponse = (
  arg: any,
): arg is DinnerResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.dinnerId === 'number' &&
  typeof arg.date === 'string' &&
  typeof arg.content === 'string' &&
  typeof arg.createdAt === 'string';

export const isTypeDinnersResponse = (
  arg: any,
): arg is DinnerResponse[] =>
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeDinnerResponse(arg[0]));

export const isTypeBreakfastResponse = (
  arg: any,
): arg is BreakfastResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.breakfastId === 'number' &&
  typeof arg.date === 'string' &&
  typeof arg.content === 'string' &&
  typeof arg.createdAt === 'string';

export const isTypeBreakfastsResponse = (
  arg: any,
): arg is BreakfastResponse[] =>
  Array.isArray(arg) &&
  (arg.length === 0 || isTypeBreakfastResponse(arg[0]));
