import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Calendar from '../views/Calendar.vue';
import Callback from '../views/Callback.vue';
import Chat from '../views/Chat.vue';
import ChildAdd from '../views/ChildAdd.vue';
import ChildEdit from '../views/ChildEdit.vue';
import Children from '../views/Children.vue';
import ChildrenBasic from '../views/ChildrenBasic.vue';
import ChildrenCondition from '../views/ChildrenCondition.vue';
import ChildrenGrowth from '../views/ChildrenGrowth.vue';
import MeCreate from '../views/MeCreate.vue';
import FamilyCreate from '../views/FamilyCreate.vue';
import FamilyEdit from '../views/FamilyEdit.vue';
import Growth from '../views/Growth.vue';
import GrowthSelect from '../views/GrowthSelect.vue';
import Home from '../views/Home.vue';
import Invoice from '../views/Invoice.vue';
import InvoiceSelect from '../views/InvoiceSelect.vue';
import InvoiceDetail from '../views/InvoiceDetail.vue';
import Maintenance from '../views/Maintenance.vue';
import ParentAdd from '../views/ParentAdd.vue';
import ParentEdit from '../views/ParentEdit.vue';
import ParentInfo from '../views/ParentInfo.vue';
import Payment from '../views/Payment.vue';
import PaymentSelect from '../views/PaymentSelect.vue';
import PaymentRegister from '../views/PaymentRegister.vue';
import Record from '../views/Record.vue';
import RecordByDay from '../views/RecordByDay.vue';
import RecordCalendar from '../views/RecordCalendar.vue';
import References from '../views/References.vue';
import ReferenceDetail from '../views/ReferenceDetail.vue';
import Setting from '../views/Setting.vue';
import SettingNotification from '../views/SettingNotification.vue';
import SettingVarious from '../views/SettingVarious.vue';
import SettingFamily from '../views/SettingFamily.vue';
import SignIn from '../views/SignIn.vue';
import VerifyEmail from '../views/VerifyEmail.vue';
import NoEmailVerified from '../views/NoEmailVerified.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/child/add',
    name: 'ChildAdd',
    component: ChildAdd,
  },
  {
    path: '/child/edit',
    name: 'ChildEdit',
    component: ChildEdit,
  },
  {
    path: '/children',
    name: 'Children',
    component: Children,
  },
  {
    path: '/children/basic',
    name: 'ChildrenBasic',
    component: ChildrenBasic,
  },
  {
    path: '/children/condition',
    name: 'ChildrenCondition',
    component: ChildrenCondition,
  },
  {
    path: '/children/growth',
    name: 'ChildrenGrowth',
    component: ChildrenGrowth,
  },
  {
    path: '/create-family',
    name: 'FamilyCreate',
    component: FamilyCreate,
  },
  {
    path: '/family/edit',
    name: 'FamilyEdit',
    component: FamilyEdit,
  },
  {
    path: '/create-user',
    name: 'MeCreate',
    component: MeCreate,
  },
  {
    path: '/growth',
    name: 'Growth',
    component: Growth,
  },
  {
    path: '/growth/select',
    name: 'GrowthSelect',
    component: GrowthSelect,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/invoice',
    name: 'Invoice',
    component: Invoice,
  },
  {
    path: '/invoice/select',
    name: 'InvoiceSelect',
    component: InvoiceSelect,
  },
  {
    path: '/invoice/detail',
    name: 'InvoiceDetail',
    component: InvoiceDetail,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },
  {
    path: '/parent/add',
    name: 'ParentAdd',
    component: ParentAdd,
  },
  {
    path: '/parent/edit',
    name: 'ParentEdit',
    component: ParentEdit,
  },
  {
    path: '/parent/info',
    name: 'ParentInfo',
    component: ParentInfo,
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
  },
  {
    path: '/payment/select',
    name: 'PaymentSelect',
    component: PaymentSelect,
  },
  {
    path: '/payment/register',
    name: 'PaymentRegister',
    component: PaymentRegister,
  },
  {
    path: '/record',
    name: 'Record',
    component: Record,
  },
  {
    path: '/record/day',
    name: 'RecordByDay',
    component: RecordByDay,
  },
  {
    path: '/record/calendar',
    name: 'RecordCalendar',
    component: RecordCalendar,
  },
  {
    path: '/references',
    name: 'References',
    component: References,
  },
  {
    path: '/references/detail',
    name: 'ReferenceDetail',
    component: ReferenceDetail,
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
  },
  {
    path: '/setting/family',
    name: 'SettingFamily',
    component: SettingFamily,
  },
  {
    path: '/setting/notification',
    name: 'SettingNotification',
    component: SettingNotification,
  },
  {
    path: '/setting/various',
    name: 'SettingVarious',
    component: SettingVarious,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback,
  },
  {
    path: '/verify/email',
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  {
    path: '/error/email/verify',
    name: 'NoEmailVerified',
    component: NoEmailVerified,
  },
  {
    path: '',
    redirect: {
      name: 'SignIn',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  const maintenanceMode = process.env.VUE_APP_MANTENANCE_MODE;
  const toQuery = to.query;
  const fromQuery = from.query;
  const toPath = to.path;

  const isMaintenanceMode = maintenanceMode && maintenanceMode === 'true';
  const toMagicWord = toQuery.vadar && toQuery.vadar === 'anakin';
  const fromMagicWord = fromQuery.vadar && fromQuery.vadar === 'anakin';

  if (isMaintenanceMode) {
    if (toMagicWord) {
      if (toPath === '/maintenance') {
        next('/home');
        return;
      }
      next();
      return;
    }

    if (fromMagicWord) {
      // ここで to のクエリーに from のクエリーを渡す
      const query = Object.assign(to.query, { vadar: 'anakin' });
      next({ name: to.name || '', params: to.params, query });
      return;
    }

    // should go to maintenance
    if (toPath === '/maintenance') {
      next();
      return;
    }
    next('/maintenance');
    return;
  }

  // maintenanceMode = false
  if (toPath === '/maintenance') {
    next('/home');
    return;
  }

  if (toPath === '/memoru') {
    next('/home')
    const prefix = process.env.VUE_APP_API_URL?.split('//')?.[1]?.split('-')?.[0];
    const url =
      prefix === 'dev'
        ? 'https://dev-memoru.netlify.app'
        : prefix === 'stg'
          ? 'https://stg-memoru.netlify.app'
          : 'https://app-memoru.netlify.app';
    window.location.href = url;
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  if (
    to.path === '/signin'
  ) {
    next();
  } else {
    next();
    return;
  }

});


export default router;
