import { render, staticRenderFns } from "./TemperatureList.vue?vue&type=template&id=2255e93a&scoped=true"
import script from "./TemperatureList.vue?vue&type=script&lang=ts"
export * from "./TemperatureList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2255e93a",
  null
  
)

export default component.exports