import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiAttendance from '@/api/ApiAttendance';

import {
  QrCodeResponse,
  HomeAttendanceSchema,
} from '@API/src/component/home/attendance/types';
import {
  PostQrRequest,
  FetchAttendanceRequest,
} from '@/model/Attendance';

export interface AttendanceState {
  qrCode: string;
  attendance: HomeAttendanceSchema | null;
}

@Module({ dynamic: true, store, name: 'attendance', namespaced: true })
class AttendanceModule extends VuexModule implements AttendanceState {

  public qrCode: string = '';
  public attendance: HomeAttendanceSchema | null = null;

  @Action({ rawError: true })
  public async postQr(request: PostQrRequest) {
    try {
      const response: QrCodeResponse = await ApiAttendance.postQr(request);
      this.setQrCode(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setQrCode(qrCode: QrCodeResponse) {
    this.qrCode = qrCode.qrCode;
  }

  @Action({ rawError: true })
  public async fetchAttendance(request: FetchAttendanceRequest) {
    try {
      const response: HomeAttendanceSchema = await ApiAttendance.fetchAttendance(request);
      this.setAttendance(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAttendance(attendance: HomeAttendanceSchema) {
    this.attendance = attendance;
  }
}

export const attendanceModule = getModule(AttendanceModule);
