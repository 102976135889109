import {
  ParentMeResponse,
} from '@API/src/component/home/me/types';

export const isTypeParentMeResponse = (
  arg: any,
): arg is ParentMeResponse =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.parentId === 'number' &&
  typeof arg.email === 'string' &&
  typeof arg.imageUrl === 'string';
