
import Vue from 'vue';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';

export default Vue.extend({

  computed: {
    children() {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
  },

  data: () => ({
    items: [
      {
        title: 'チャット',
        badgeNum: 0,
        to: 'Chat',
        path: '/chat',
      },
      {
        title: '今日の記録',
        badgeNum: 0,
        to: 'RecordByDay',
        path: '/record/day',
      },
      {
        title: 'お知らせ一覧/資料室',
        badgeNum: 0,
        to: 'References',
        path: '/references',
      },
    ],
  }),

  methods: {
    to(path: string, name: string, childId: string, childName: string) {
      this.$router.push({ path, name, query: {
        childId,
        childName,
      } });
    },
  },
});
