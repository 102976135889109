
import Vue from 'vue';
import { when } from '@/service/Switch';

enum MenuType {
  CHILDREN = 'children',
  PARENTINFO = 'parentinfo',
  REFERENCES = 'references',
  GROWTH = 'growth',
  INVOICE = 'invoice',
  SETTING = 'setting',
  INQUIRY = 'inquiry',
  HELP = 'help',
  // LOGOUT = 'logout',
}

interface Menu {
  type: MenuType;
  img: string;
  title: string;
  path: string;
}

interface DataType {
  menus: Menu[];
}

export default Vue.extend({

  data: (): DataType => ({
    menus: [
      // {
      //   type: MenuType.CHILDREN,
      //   img: require('@/assets/children.png'),
      //   title: '児童票',
      //   path: '/children',
      // },
      // {
      //   type: MenuType.PARENTINFO,
      //   img: require('@/assets/parent.png'),
      //   title: '保護者票',
      //   path: '/parent/info',
      // },
      // {
      //   type: MenuType.REFERENCES,
      //   img: require('@/assets/parent.png'),
      //   title: '資料室',
      //   path: '/references',
      // },
      {
        type: MenuType.GROWTH,
        img: require('@/assets/growth.png'),
        title: '成長記録',
        path: '/growth/select',
      },
      {
        type: MenuType.INVOICE,
        img: require('@/assets/invoice.png'),
        title: '請求書',
        path: '/invoice/select',
      },
      {
        type: MenuType.SETTING,
        img: require('@/assets/cog.png'),
        title: '各種設定',
        path: '/setting/various',
      },
      // {
      //   type: MenuType.INQUIRY,
      //   img: 'https://via.placeholder.com/240x180?text=Sample+Image+1',
      //   title: 'お問い合わせ',
      //   path: '/inquiry',
      // },
      // {
      //   type: MenuType.HELP,
      //   img: 'https://via.placeholder.com/240x180?text=Sample+Image+1',
      //   title: 'ヘルプ',
      //   path: '/help',
      // },
      // {
      //   type: MenuType.LOGOUT,
      //   img: 'https://via.placeholder.com/240x180?text=Sample+Image+1',
      //   title: 'ログアウト',
      //   path: '/children',
      // },
    ],
  }),

  methods: {
    clickMenu(menu: Menu) {
      this.$router.push(menu.path);
    },
  },
});
