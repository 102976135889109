
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';
// import VueHighlights, { autoLink, autoHighlight } from 'vue-highlights';
import VRuntimeTemplate from 'v-runtime-template';

import { isSafeAreaDisplay } from '@/service/isSafeAreaDisplay';

import { VueLoading } from 'vue-loading-template';
import AbsenceModal from '@/components/modal/AbsenceModal.vue';
import PickupModal from '@/components/modal/PickupModal.vue';
import ChatMenu from '@/components/organisms/ChatMenu.vue';

import { routeModule } from '@/store/entityModules/route';
import { meModule } from '@/store/entityModules/me';
import { chatModule } from '@/store/entityModules/chat';
import { familyModule } from '@/store/entityModules/family';
import { resourceModule } from '@/store/entityModules/resource';

// model
import {
  FetchChatsRequest,
  PostChatRequest,
  FetchHomeAbsenceRequest,
  PostHomeAbsenceRequest,
  FetchChatsChoicesRequest,
  FetchHomePickupRequest,
  PutHomePickupRequest,
  PostChatsConfirmRequest,
} from '@/model/Chat';
import {
  HomeChatResponse,
  HomeAbsenceResponse,
  HomePickupResponse,
  ChatChoiceResponse,
} from '@API/src/component/home/chat/types';

import Vue2TouchEvents from 'vue2-touch-events';
import { Toast } from '../service/SweetAlert';

Vue.use(Vue2TouchEvents);
// Vue.component(VueHighlights.name, VueHighlights);

interface DataType {
  childId: number;
  childName: string;
  selectedDate: string;
  selectedChat: HomeChatResponse | null;
  tabs: any;
  prevBadgeNum: number;
  nextBadgeNum: number;
  fetchLoading: boolean;
  postLoading: boolean;
  imgLoading: boolean;
  isAbsenceDialog: boolean;
  deleteAbsenceLoading: boolean;
  isPickupDialog: boolean;
  deletePickupLoading: boolean;
  isChatMenu: boolean;
  chatMenuX: number;
  chatMenuY: number;
  isAttachmentUrls: boolean;
  isImgDialog: boolean;
  notPdfImgSrc: string;
  showingUrls: Array<{
    url: string;
    objectKey: string;
  }>;
  chatWriting: string;
  showPicker: boolean;
  selectedCalendarModal: boolean;
}

enum Reason {
  fever = 'fever', // 発熱
  personal = 'personal', // 家庭の事情
  infection = 'infection',
  others = 'others', // その他（コメントの入力が必要）
  infStrep = 'infStrep', // 溶連菌感染症
  infMycoplasma = 'infMycoplasma', // マイコプラズマ肺炎
  infHFMD = 'infHFMD', // 手足口病
  infSlappedCheek = 'infSlappedCheek', // 伝染性紅斑（りんご病）
  infStomach = 'infStomach', // ウイルス性胃腸炎（ノロ、ロタ、アデノ等）
  infHerpangina = 'infHerpangina', // ヘルパンギーナ
  infRS = 'infRS', // RSウィルス感染症
  infZoster = 'infZoster', // 帯状疱疹
  infRoseola = 'infRoseola', // 突発性発しん
  infImpetigo = 'infImpetigo', // 伝染性膿痂疹（とびひ）
}

export default Vue.extend({
  components: {
    VueLoading,
    VRuntimeTemplate,
    AbsenceModal,
    PickupModal,
    ChatMenu,
  },

  data: (): DataType => ({
    childId: 0,
    childName: '',
    selectedDate: dayjs().format('YYYY-MM-DD'),
    selectedChat: null,
    tabs: null,
    prevBadgeNum: 0,
    nextBadgeNum: 0,
    fetchLoading: false,
    postLoading: false,
    imgLoading: false,
    isAbsenceDialog: false,
    deleteAbsenceLoading: false,
    isPickupDialog: false,
    deletePickupLoading: false,
    isChatMenu: false,
    chatMenuX: 0,
    chatMenuY: 0,
    isAttachmentUrls: false,
    isImgDialog: false,
    notPdfImgSrc: '',
    showingUrls: [],
    chatWriting: '',
    showPicker: false,
    selectedCalendarModal: false,
  }),

  computed: {
    isChatEnabled() {
      if (familyModule.family) {
        const children = familyModule.family.children;
        const child = children.find((c) => {
          if (c.childId === this.childId) {
            return c;
          }
        });
        if (!!child && child.nurserySettings.chat.isEnabled) {
          return child.nurserySettings.chat.isEnabled;
        }
      }
      return false;
    },
    isQuit() {
      if (familyModule.family) {
        const children = familyModule.family.children;
        const child = children.find((c) => {
          if (c.childId === this.childId) {
            return c;
          }
        });
        if (!!child && child.quitDate) {
          return dayjs(child.quitDate).isBefore(dayjs());
        }
      }
      return false;
    },
    appUrl(): string {
      if (process.env.VUE_APP_BASE_URL) {
        return process.env.VUE_APP_BASE_URL;
      }
      return '';
    },
    isSafeArea() {
      return isSafeAreaDisplay();
    },
    rules() {
      return rules;
    },
    me() {
      return meModule.me;
    },
    chatInput: {
      get(): string {
        return chatModule.chatInput;
      },
      set(value: string) {
        chatModule.setChatInput(value);
      },
    },
    chats(): HomeChatResponse[] {
      return chatModule.chatsSorted;
    },
    absence() {
      return chatModule.chatAbsence;
    },
    pickup() {
      return chatModule.chatPickup;
    },
    unReadCount() {
      return chatModule.unReadCount;
    },
    year(): string {
      return dayjs(this.selectedDate).format('YYYY');
    },
    today(): string {
      return dayjs(this.selectedDate).format('M月D日');
    },
    dayOfTheWeek(): string {
      return dayjs(this.selectedDate).format('ddd');
    },
    iframeHeight() {
      return window.innerHeight - 56;
    },
  },

  watch: {
    chats: {
      async handler(val: boolean) {
        if (val) {
          this.$nextTick(() => {
            //pusherからchatsの更新があった時のみ（fetchLoading以外）画面更新
            if (!this.fetchLoading) {
              console.log("fetchChats")
              setTimeout(() => {
                this.fetchChats()
              }, 1000);
            }
            this.chatsScrollToBottom();
          });
        }
      },
      deep: true,
    },
    chatInput(newVal) {
    this.chatWriting = newVal;
    localStorage.setItem(this.childId + 'chatWriting', this.chatWriting);
    },
  },

  beforeRouteEnter(to, from, next) {
    routeModule.setPrevRoutePath(from.path);
    next();
  },

  methods: {
    autoLink(text: string) {
      const urlArr = text.match(/(https?:\/\/[^\s]*)/g);
      if (urlArr && urlArr.length) {
        const replaced = text.replace(
          /(https?:\/\/[^\s]*)/g,
          `<a class='highlights' @click='urlClicked("$1")'>$1</a>`,
        );
        return `
          <p>${replaced}</p>
        `;
      } else {
        return `
          <p>${text}</p>
        `;
      }
    },
    urlClicked(url: string) {
      const ua = navigator.userAgent;
      const isIOS =
        ua.indexOf('iPhone') >= 0 ||
        ua.indexOf('iPad') >= 0 ||
        Number(navigator.userAgent.indexOf('iPod')) >= 0;
      if (isIOS) {
        if ((window as any).webkit.messageHandlers) {
          (window as any).webkit.messageHandlers.openUrl.postMessage(`${url}`);
        }
      } else {
        window.open(url);
      }
    },
    routerBack() {
      if (routeModule.prevRoutePath) {
        this.$router.replace(routeModule.prevRoutePath, () => {});
        routeModule.setPrevRoutePath(null);
      } else {
        this.$router.replace('/record', () => {});
      }
    },
    toRecordByDay(
      path: string,
      name: string,
      childId: string,
      childName: string,
    ) {
      this.$router.push({
        path: '/record/day',
        query: {
          childId: String(this.childId),
          childName: this.childName,
        },
      });
    },
    toNextDate() {
      this.selectedDate = dayjs(this.selectedDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');
      this.fetchChats();
    },
    toPrevDate() {
      this.selectedDate = dayjs(this.selectedDate)
        .add(-1, 'day')
        .format('YYYY-MM-DD');
      this.fetchChats();
    },
    async fetchChats() {
      this.fetchLoading = true;
      const fetchChatsFunc = chatModule.fetchChats({
        childId: this.childId,
        date: this.selectedDate,
      });
      const fetchAbsenceFunc = chatModule.fetchHomeAbsence({
        childId: this.childId,
        date: this.selectedDate,
      });
      const fetchPickupFunc = chatModule.fetchHomePickup({
        childId: this.childId,
        date: this.selectedDate,
      });
      await Promise.all([fetchChatsFunc, fetchAbsenceFunc, fetchPickupFunc]);
      this.$nextTick(() => {
        this.fetchLoading = false;
        setTimeout(() => {
          this.chatsScrollToBottom();
        }, 350);
        const filteredChats = this.chats.filter((c) => {
          if (c.from !== null) {
            return c;
          }
        });
        const chatIds = filteredChats.map((f) => {
          return f.chatId;
        });
        if (chatIds.length) {
          chatModule.postChatsConfirm({ chatIds });
        }
      });
    },
    async postChats() {
      if (this.chatInput && this.childId) {
        this.postLoading = true;
        await chatModule.postChats({
          childId: this.childId,
          date: dayjs(this.selectedDate).format('YYYY-MM-DD'),
          body: this.chatInput,
        });
        this.postLoading = false;
        this.chatInput = '';
        this.$nextTick(() => {
          this.chatsScrollToBottom();
        });
      }
      localStorage.removeItem(this.childId + 'chatWriting');
    },
    async deleteChat() {
      this.isChatMenu = false;
      if (this.selectedChat) {
        const response = await chatModule.deleteChat({
          chatId: this.selectedChat.chatId,
        });
        this.selectedChat = null;
        Toast.fire({
          icon: 'success',
          title: '削除が完了しました',
        });
      }
    },
    async deleteAbsence() {
      this.deleteAbsenceLoading = true;
      await chatModule.deleteAbsence({
        childId: this.childId,
        date: dayjs(this.selectedDate).format('YYYY-MM-DD'),
      });
      this.deleteAbsenceLoading = false;
      Toast.fire({
        icon: 'success',
        title: '削除が完了しました',
      });
    },
    async deletePickup() {
      this.deletePickupLoading = true;
      await chatModule.deletePickup({
        childId: this.childId,
        date: dayjs(this.selectedDate).format('YYYY-MM-DD'),
      });
      this.deletePickupLoading = false;
      Toast.fire({
        icon: 'success',
        title: '削除が完了しました',
      });
    },
    formatMinutes(time: string): string {
      const created = dayjs(time).format('YYYY-MM-DD');
      const selectedDate = dayjs(this.selectedDate).format('YYYY-MM-DD');
      if (created === selectedDate) {
        return dayjs(time).format('HH:mm');
      } else {
        return dayjs(time).format(`MM/DD HH:mm`);
      }
    },
    chatsScrollToBottom() {
      const selectedElement: Element =
        document.getElementsByClassName('wrapper')[0];
      selectedElement.scrollTop = selectedElement.scrollHeight;
    },
    showChatMenu(chat: HomeChatResponse) {
      return (direction: TouchEvent, event) => {
        if (chat.from === null) {
          this.chatMenuX = direction.changedTouches[0].clientX - 60;
          this.chatMenuY = direction.changedTouches[0].clientY - 40;
          this.selectedChat = chat;
          this.isChatMenu = !this.isChatMenu;
        }
      };
    },
    insertStr(str, index, insert) {
      return str.slice(0, index) + insert + str.slice(index, str.length);
    },
    formatAmzIso(dateStr: string): string {
      let formatted = dateStr;
      formatted = this.insertStr(formatted, 4, '-');
      formatted = this.insertStr(formatted, 7, '-');
      formatted = this.insertStr(formatted, 13, ':');
      formatted = this.insertStr(formatted, 16, ':');
      return formatted;
    },
    isPresignedUrlExpired(url: string): boolean {
      try {
        const expiresFromIndex = url.indexOf('?Expires=') + 9;
        const expiresEndIndex = url.indexOf('&Key-Pair-Id=');
        const expires = Number(
          url.substring(expiresFromIndex, expiresEndIndex),
        );
        if (expires) {
          const Now = dayjs();
          const isExpired = Now.isAfter(dayjs.unix(expires));
          return isExpired;
        } else {
          return true;
        }
      } catch (error) {
        return true;
      }
    },
    isPdf(objectKey: string) {
      const arr = objectKey.split('.');
      const ext = arr[arr.length - 1];
      if (ext === 'pdf') {
        return true;
      }
      return false;
    },
    async openChatImg(chat: HomeChatResponse, index: number) {
      let img = chat.attachmentUrls[index].url;
      const isPdf = this.isPdf(chat.attachmentUrls[index].objectKey);
      if (isPdf) {
        const isImgExpired = this.isPresignedUrlExpired(
          chat.attachmentUrls[index].url,
        );
        if (isImgExpired) {
          this.imgLoading = true;
          const objectKey = chat.attachmentUrls[index].objectKey;
          const imgContent = await resourceModule.postResourceDownload({
            objectKey,
          });
          img = imgContent.preSignedUrl;
          this.imgLoading = false;
        }
        const ua = navigator.userAgent;
        const isIOS =
          ua.indexOf('iPhone') >= 0 ||
          ua.indexOf('iPad') >= 0 ||
          Number(navigator.userAgent.indexOf('iPod')) >= 0;
        if (isIOS) {
          if ((window as any).webkit.messageHandlers) {
            (window as any).webkit.messageHandlers.pdf.postMessage(`${[img]}`);
          }
        } else {
          this.isAttachmentUrls = true;
          this.showingUrls = [
            {
              url: img,
              objectKey: chat.attachmentUrls[index].objectKey,
            },
          ];
        }
      } else {
        // let img = chat.attachmentUrls[0].url;
        const isImgExpired = this.isPresignedUrlExpired(
          chat.attachmentUrls[index].url,
        );
        if (isImgExpired) {
          const objectKey = chat.attachmentUrls[index].objectKey;
          const imgContent = await resourceModule.postResourceDownload({
            objectKey,
          });
          img = imgContent.preSignedUrl;
        }
        this.notPdfImgSrc = img;
        this.isImgDialog = true;
      }
    },
    formatReason(reason: Reason) {
      switch (reason) {
        case Reason.fever:
          return '発熱';
        case Reason.personal:
          return '家庭の事情';
        case Reason.infection:
          return '感染症';
        case Reason.others:
          return 'その他';
        case Reason.infStrep:
          return '溶連菌感染症';
        case Reason.infMycoplasma:
          return 'マイコプラズマ肺炎';
        case Reason.infHFMD:
          return '手足口病';
        case Reason.infSlappedCheek:
          return '伝染性紅斑（りんご病）';
        case Reason.infStomach:
          return 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）';
        case Reason.infHerpangina:
          return 'ヘルパンギーナ';
        case Reason.infRS:
          return 'RSウィルス感染症';
        case Reason.infZoster:
          return '帯状疱疹';
        case Reason.infRoseola:
          return '突発性発しん';
        case Reason.infImpetigo:
          return '伝染性膿痂疹（とびひ）';
      }
    },
    closeChatImg() {
      this.isAttachmentUrls = false;
      this.showingUrls = [];
    },
    showCalendarModal() {
      this.selectedCalendarModal = true;
    },    
    dateSelected(date) {      
      this.selectedDate = dayjs(date).format('YYYY-MM-DD');
      console.log(this.selectedDate);

      this.selectedCalendarModal = false;
      this.fetchChats();      
    },
    closeCalendarModal(){
      this.selectedCalendarModal = false;
    }
  },

  async mounted() {
    if (typeof this.$route.query.date === 'string') {
      this.selectedDate = dayjs(this.$route.query.date).format('YYYY-MM-DD');
    }
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
      this.fetchChats();
    } else {
      this.routerBack();
    }
    // ページが読み込まれたときにローカルストレージからchatWritingを読み込む
    console.log(this.childId)
    const savedChatWriting = localStorage.getItem(this.childId + 'chatWriting');
    console.log(this.childId + 'chatWriting')
    if (savedChatWriting !== null) {
      this.chatWriting = savedChatWriting;
      this.chatInput = this.chatWriting; // チャット入力エリアを更新
    }
  },
});
