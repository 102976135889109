import ApiClient from '@/api/ApiClient';
import {
  PrivateChannelAuthHomeResponse,
  PushAuthHomeResponse,
} from '@API/src/component/home/pusher/types';
import {
  FetchPrivateChannelAuthRequest,
  FetchPushAuthRequest,
} from '@/model/Pusher';
import {
  isTypePrivateChannelAuthHomeResponse,
  isTypePushAuthHomeResponse,
} from '@/model/TypeGuard/Pusher';

export default class ApiPusher {

  public static async publishPrivateChanel(
    request: FetchPrivateChannelAuthRequest,
  ): Promise<PrivateChannelAuthHomeResponse> {
    try {
      const response = await ApiClient.get(`/private-channel/auth`, request);
      if (isTypePrivateChannelAuthHomeResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchPushAuth(
    request: FetchPushAuthRequest,
  ): Promise<PushAuthHomeResponse> {
    try {
      const response = await ApiClient.get(`/push/auth`, request);
      if (isTypePushAuthHomeResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
