
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import { VueLoading } from 'vue-loading-template';

import { GChart } from 'vue-google-charts';

import { growthModule } from '@/store/entityModules/growth';
import { familyModule } from '@/store/entityModules/family';

import { GrowthResponse } from '@API/src/component/home/growth/types';
import { ChildResponse } from 'chaild-entity/lib';

interface DataType {
  tabs: any;
  tabTypes: string[];
  selectedNendo: string;
  childId: number;
  childName: string;
  fetchLoading: boolean;
  showDetail: boolean;
  selectedGrowth: GrowthResponse | null;
  colorPrimary: string;
  colorChartGray: string;
  colorChartArea: string;
}

export default Vue.extend({
  components: {
    VueLoading,
    GChart,
  },

  computed: {
    family() {
      return familyModule.family;
    },
    selectedChild(): ChildResponse | null {
      if (familyModule.family) {
        const children = familyModule.family.children;
        const child = children.find((c) => c.childId === this.childId);
        if (!!child) {
          return child;
        }
      }
      return null;
    },
    growths() {
      return growthModule.growths;
    },
    percentile() {
      return growthModule.percentile;
    },
    heightChartData() {
      if (!growthModule.growths) {
        return [];
      }

      // const growthRecord = {...growthModule.growths};
      const numbers = [...Array(12)].map((emp, i) => (i + 4) % 12);
      // const chartData: Array<Array<string | number | null>> = [['月', 'パーセンタイル値 3%', 'パーセンタイル値 10%', 'パーセンタイル値 25%', 'パーセンタイル値 50%', 'パーセンタイル値 75%', 'パーセンタイル値 90%', 'パーセンタイル値 97%', '身長']];
      const chartData: Array<Array<string | number | null>> = [
        ['月', 'パーセンタイル値 3%', 'パーセンタイル値 97%', '身長'],
      ];

      numbers.map((m) => {
        // const aYear: string = dayjs().format('YYYY');
        const aYear: string = this.selectedNendo;
        const month = m === 0 ? 12 : m;
        const col: Array<string | number | null> = [`${month}月`];
        const find = growthModule.growths.find((r) => r.month === month);
        if (find) {
          // const year = month < 4 ? Number(aYear) : Number(aYear) - 1;
          const year = month < 4 ? Number(aYear) + 1 : Number(aYear);
          const percenTiles = this.getHeightPercentile(
            growthModule.growths,
            year,
            month,
          );
          if (percenTiles['3'] && percenTiles['97']) {
            col.push(percenTiles['3']);
            col.push(percenTiles['97'] - percenTiles['3']);
          } else {
            col.push(null);
            col.push(null);
          }

          const value = find ? find.height : null;
          col.push(value);
          chartData.push(col);
        } else {
          col.push(null);
          col.push(null);
          col.push(null);
          chartData.push(col);
        }
      });

      return chartData;
    },
    weightChartData() {
      if (!growthModule.growths) {
        return [];
      }

      // const growthRecord = {...this.growthRecord};
      const numbers = [...Array(12)].map((emp, i) => (i + 4) % 12);
      const chartData: Array<Array<string | number | null>> = [
        ['月', 'パーセンタイル値 3%', 'パーセンタイル値 97%', '体重'],
      ];

      numbers.map((m) => {
        // const aYear: string = dayjs().format('YYYY');
        const aYear: string = this.selectedNendo;
        const month = m === 0 ? 12 : m;
        const col: Array<string | number | null> = [`${month}月`];
        const find = growthModule.growths.find((r) => r.month === month);
        if (find) {
          // const year = month < 4 ? Number(aYear) : Number(aYear) - 1;
          const year = month < 4 ? Number(aYear) + 1 : Number(aYear);
          const percenTiles = this.getWeightPercentile(
            growthModule.growths,
            year,
            month,
          );
          if (percenTiles['3'] && percenTiles['97']) {
            col.push(percenTiles['3']);
            col.push(percenTiles['97'] - percenTiles['3']);
          } else {
            col.push(null);
            col.push(null);
          }

          const value = find ? find.weight : null;
          col.push(value);
          chartData.push(col);
        } else {
          col.push(null);
          col.push(null);
          col.push(null);
          chartData.push(col);
        }
      });

      return chartData;
    },
    nendoOptions(): string[] {
      const nendoArray: string[] = [];
      let i = 0;
      const month = dayjs().format('MM');
      if (month === '01' || month === '02' || month === '03') {
        i++;
      }
      for (i; i < 6; i++) {
        const nendo = dayjs()
          .add(i * -1, 'year')
          .format('YYYY');
        nendoArray.push(nendo);
      }
      return nendoArray;
    },
  },

  data: (): DataType => ({
    // tabs: null,
    tabs: 'memory',
    tabTypes: ['memory', 'graph'],
    selectedNendo: '',
    childId: NaN,
    childName: '',
    fetchLoading: false,
    showDetail: false,
    selectedGrowth: null,
    colorPrimary: '#029ec0',
    colorChartGray: '#ccc',
    colorChartArea: '#67c4d9',
  }),

  methods: {
    routerBack() {
      this.$router.replace('/growth/select', () => {});
    },
    toggleDetail(growth: GrowthResponse | null) {
      if (growth !== null) {
        this.selectedGrowth = growth;
        this.showDetail = true;
      } else {
        this.showDetail = false;
      }
    },
    getHeightPercentile(
      record: GrowthResponse[] | null,
      year,
      month,
    ): {
      3: null | number;
      10: null | number;
      25: null | number;
      50: null | number;
      75: null | number;
      90: null | number;
      97: null | number;
    } {
      if (record && this.selectedChild && growthModule.percentile) {
        const birthDay = dayjs(this.selectedChild.dateOfBirth);
        const months = dayjs(`${year}-${month}`).diff(birthDay, 'month');
        const gender = this.selectedChild.gender;
        const percentileValues = growthModule.percentile.filter((pm) => {
          if (
            pm.sex === gender &&
            pm.month === months &&
            pm.valueType === 'height'
          ) {
            return true;
          }
          return false;
        });
        if (percentileValues.length === 7) {
          percentileValues.sort((pv1, pv2) => {
            return pv1.percentile - pv2.percentile;
          });
          return {
            3: percentileValues[0].value,
            10: percentileValues[1].value,
            25: percentileValues[2].value,
            50: percentileValues[3].value,
            75: percentileValues[4].value,
            90: percentileValues[5].value,
            97: percentileValues[6].value,
          };
        }
      }
      return {
        3: null,
        10: null,
        25: null,
        50: null,
        75: null,
        90: null,
        97: null,
      };
    },
    getWeightPercentile(
      record: GrowthResponse[] | null,
      year,
      month,
    ): {
      3: null | number;
      10: null | number;
      25: null | number;
      50: null | number;
      75: null | number;
      90: null | number;
      97: null | number;
    } {
      if (record && this.selectedChild && growthModule.percentile) {
        const birthDay = dayjs(this.selectedChild.dateOfBirth);
        const months = dayjs(`${year}-${month}`).diff(birthDay, 'month');
        const gender = this.selectedChild.gender;
        const percentileValues = growthModule.percentile.filter((pm) => {
          if (
            pm.sex === gender &&
            pm.month === months &&
            pm.valueType === 'weight'
          ) {
            return true;
          }
          return false;
        });
        if (percentileValues.length === 7) {
          percentileValues.sort((pv1, pv2) => {
            return pv1.percentile - pv2.percentile;
          });
          return {
            3: percentileValues[0].value,
            10: percentileValues[1].value,
            25: percentileValues[2].value,
            50: percentileValues[3].value,
            75: percentileValues[4].value,
            90: percentileValues[5].value,
            97: percentileValues[6].value,
          };
        }
      }
      return {
        3: null,
        10: null,
        25: null,
        50: null,
        75: null,
        90: null,
        97: null,
      };
    },
    async changeNendo() {
      this.fetchLoading = true;
      await growthModule.fetchGrowth({
        childId: String(this.childId),
        year: this.selectedNendo,
      });
      this.fetchLoading = false;
    },
  },

  async mounted() {
    this.selectedNendo = this.nendoOptions[0];
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'number' && this.family) {
      this.childId = this.$route.query.childId;
      this.fetchLoading = true;
      await Promise.all([
        growthModule.fetchGrowth({
          childId: String(this.childId),
          year: null,
        }),
        growthModule.fetchGrowthPercentile({
          familyId: this.family.familyId,
        }),
      ]);
      this.fetchLoading = false;
    } else {
      this.$router.replace('/growth/select', () => {});
    }
  },
});
