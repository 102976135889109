
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { when } from '@/service/Switch';

import { VueLoading } from 'vue-loading-template';
import { VCalendar } from '@/model/Vuetify/VCalendar';

import { eventsModule } from '@/store/entityModules/events';
import { familyModule } from '@/store/entityModules/family';

import { ChildResponse } from '@API/src/component/home/family/types';
import {
  DefecationStatus,
  DefecationStatusType,
} from '../../node_modules/chaild-entity/src/component/home/defecation/types';

interface Event {
  name: string;
  date: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
  children: ChildResponse[];
}

interface DataType {
  selectedDate: string;
  selectedEvents: Event[];
  scrollInvoked: number;
  isCalendar: boolean;
  fetchLoading: boolean;
}

export default Vue.extend({

  data: (): DataType =>({
    selectedDate: dayjs().format('YYYY-MM-DD'),
    selectedEvents: [],
    scrollInvoked: 0,
    isCalendar: true,
    fetchLoading: false,
  }),

  computed: {
    calendar(): VCalendar {
      return (this.$refs as any).event_calendar;
    },
    events() {
      return eventsModule.eventsIncludedDate;
    },
    children() {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
    now(): string {
      return dayjs(this.selectedDate).format('YYYY-MM-DD');
    },
    year(): string {
      return dayjs(this.selectedDate).format('YYYY');
    },
    month(): string {
      return dayjs(this.selectedDate).format('M月');
    },
    today(): string {
      return dayjs(this.selectedDate).format('M月D日');
    },
    dayOfTheWeek(): string {
      return dayjs(this.selectedDate).format('ddd');
    },
    dateFormatted(): string {
      return dayjs(this.selectedDate).format('YYYY年MM月DD日（ddd）');
    },
  },

  methods: {
    onScroll(e) {
      this.scrollInvoked = e.target.scrollTop;
      if (this.scrollInvoked > 0) {
        this.isCalendar = false;
      } else {
        this.isCalendar = true;
      }
    },
    toNextMonth() {
      this.selectedDate = dayjs(this.selectedDate).add(1, 'month').format('YYYY-MM-DD');
      this.fetchEvents();
      // tslint:disable-next-line
      this.calendar.next();
    },
    toPrevMonth() {
      this.selectedDate = dayjs(this.selectedDate).add(-1, 'month').format('YYYY-MM-DD');
      this.fetchEvents();
      // tslint:disable-next-line
      this.calendar.prev();
    },
    minuteFormat(iso: string) {
      return dayjs(iso).format('HH:mm');
    },
    dateFormat(iso: string) {
      return dayjs(iso).format('D');
    },
    dayFormat(iso: string) {
      return dayjs(iso).format('ddd');
    },
    getDefecationState(state: DefecationStatusType): string {
      return when(state)
        .on((v) => v === DefecationStatus.hard, () => '硬い')
        .on((v) => v === DefecationStatus.normal, () => '普通')
        .on((v) => v === DefecationStatus.soft, () => '軟らかい')
        .otherwise(() => '');
    },
    async fetchEvents() {
      this.fetchLoading = true;
      const fromDate = dayjs(this.selectedDate).startOf('month');
      const endDate = dayjs(this.selectedDate).endOf('month');
      await eventsModule.fetchEvents({
        childId: null,
        from: dayjs(fromDate).format('YYYY-MM-DD'),
        to: dayjs(endDate).format('YYYY-MM-DD'),
      });
      this.fetchLoading = false;
    },
    showEvent({ nativeEvent, event }) {
      this.selectedEvents.push(event);
    },
    formatSelectedEvents(): string[] {
      const events: string[] = [];
      if (this.selectedEvents.length) {
        this.selectedDate = dayjs(this.selectedEvents[0].start).format('YYYY-MM-DD');
        for (const s of this.selectedEvents) {
          if (
            dayjs(s.start).format('hh:mm') === '12:00' &&
            dayjs(s.end).format('hh:mm') === '12:00'
          ) {
            events.push(`${s.name}`);
          } else if (s.start && s.end) {
            const from = dayjs(s.start).format('hh:mm');
            const to = dayjs(s.end).format('hh:mm');
            events.push(`${from}-${to} ${s.name}`);
          }
        }
        return events;
      }
      return [];
    },
    viewDay({ date }) {
      this.selectedDate = dayjs(date).format('YYYY-MM-DD');
    },
    getEventNum(date: string) {
      const arr = this.events.filter((e) => {
        if (e.date === date) { return e; }
      });
      return arr;
    },
  },

  async mounted() {
    this.fetchEvents();
  },
});
