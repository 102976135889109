
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import TimePicker from '@/components/molecules/TimePicker.vue';
import { chatModule } from '@/store/entityModules/chat';

// model
import { VForm } from '@/model/Vuetify/VForm';
import {
  PutHomePickupRequest,
} from '@/model/Chat';

interface DataType {
  tabs: any;
  tabTypes: string[];
  dropOffDate: string;
  pickupDate: string;
  dropOffById: number;
  dropOffTime: string;
  pickupById: number;
  pickupTime: string;
  dropOffReason: string;
  pickupReason: string;
  fetchLoading: boolean;
  isDropOffDatePicker: boolean;
  isPickupDatePicker: boolean;
  isDropOffTimePicker: boolean;
  isPickupTimePicker: boolean;
  btnLoading: boolean;
}

export default Vue.extend({
  name: 'PickupModal',
  components: {
    TimePicker,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
  },

  computed: {
    rules() {
      return rules;
    },
    dropOffForm(): VForm {
      return (this.$refs as any).post_dropoff_form;
    },
    pickupForm(): VForm {
      return (this.$refs as any).post_pickup_form;
    },
    allowMinDate(): string {
      return dayjs().format('YYYY-MM-DD');
    },
    parentChoices() {
      return chatModule.parentChoice;
    },
  },

  watch: {
    isOpen: {
      async handler(val: boolean) {
        if (val) {
          this.dropOffDate = '';
          this.pickupDate = '';
          this.dropOffById = NaN;
          this.dropOffTime = dayjs().format('HH:00');
          this.pickupById = NaN;
          this.pickupTime = dayjs().format('HH:00');
          this.dropOffReason = '';
          this.pickupReason = '';
          if (this.parentChoices.length === 0) {
            this.fetchLoading = true;
            await chatModule.fetchChatsChoices();
            this.fetchLoading = false;
          }
        }
      },
      deep: true,
    },
  },

  data: (): DataType =>({
    tabs: null,
    tabTypes: ['dropOff', 'pickup'],
    dropOffDate: '',
    pickupDate: '',
    dropOffById: NaN,
    dropOffTime: dayjs().format('HH:00'),
    pickupById: NaN,
    pickupTime: dayjs().format('HH:00'),
    dropOffReason: '',
    pickupReason: '',
    fetchLoading: false,
    isDropOffDatePicker: false,
    isPickupDatePicker: false,
    isDropOffTimePicker: false,
    isPickupTimePicker: false,
    btnLoading: false,
  }),

  methods: {
    allowedStep: (m) => m % 5 === 0,
    close() {
      this.$emit('close');
    },
    async clickPost() {
      if (this.tabs === 'dropOff') {
        if (this.dropOffForm.validate()) {
          this.btnLoading = true;
          try {
            const dropOffHour = this.dropOffTime.split(':')[0];
            const dropOffMinutes = this.dropOffTime.split(':')[1];
            const dropOffTime = dayjs(this.dropOffDate).hour(Number(dropOffHour)).minute(Number(dropOffMinutes));
            await chatModule.putChatsPickup({
              childId: this.childId,
              date: this.dropOffDate,
              dropOffById: this.dropOffById,
              dropOffTime: dayjs(dropOffTime).toISOString(),
              pickupById: null,
              pickupTime: null,
              reason: this.dropOffReason,
            });
            this.$emit('posted');
            this.$emit('close');
            this.btnLoading = false;
          } catch (error) {
            this.$emit('close');
            this.btnLoading = false;
          }
        }
      } else if (this.tabs === 'pickup') {
        if (this.pickupForm.validate()) {
          this.btnLoading = true;
          try {
            const pickupHour = this.pickupTime.split(':')[0];
            const pickupMinutes = this.pickupTime.split(':')[1];
            const pickupTime = dayjs(this.pickupDate).hour(Number(pickupHour)).minute(Number(pickupMinutes));
            await chatModule.putChatsPickup({
              childId: this.childId,
              date: this.pickupDate,
              dropOffById: null,
              dropOffTime: null,
              pickupById: this.pickupById,
              pickupTime: dayjs(pickupTime).toISOString(),
              reason: this.pickupReason,
            });
            this.$emit('posted');
            this.$emit('close');
            this.btnLoading = false;
          } catch (error) {
            this.$emit('close');
            this.btnLoading = false;
          }
        }
      }
    },
  },

  async mounted() {
    if (this.parentChoices.length === 0) {
      this.fetchLoading = true;
      await chatModule.fetchChatsChoices();
      this.fetchLoading = false;
    }
  },

});
