const parentTypes = [
  {
    text: '母',
    value: 'mother',
  },
  {
    text: '父',
    value: 'father',
  },
  {
    text: '祖母',
    value: 'grandma',
  },
  {
    text: '祖父',
    value: 'granpa',
  },
  {
    text: '叔父',
    value: 'uncle',
  },
  {
    text: '叔母',
    value: 'aunt',
  },
  // {
  //   text: '姉・妹',
  //   value: 'sister',
  // },
  // {
  //   text: '兄・弟',
  //   value: 'brother',
  // },
  {
    text: 'その他',
    value: 'other',
  },
];

export default parentTypes;
