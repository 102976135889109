
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { Toast } from '@/service/SweetAlert';

import { childrenModule } from '@/store/entityModules/children';

import { VueLoading } from 'vue-loading-template';
import ChildrenField from '@/components/organisms/ChildrenField.vue';

import { VForm } from '@/model/Vuetify/VForm';
import { ChildInfoFormat } from 'chaild-entity/src/component/nursery/child/types';

interface DataType {
  childId: number;
  childName: string;
  fetchLoading: boolean;
  btnLoading: boolean;
  answers: Array<{
    key: string;
    value: any | null;
  }>;
}

export default Vue.extend({

  components: {
    VueLoading,
    ChildrenField,
  },

  computed: {
    form(): VForm {
      return (this.$refs as any).post_childrenbasic_form;
    },
    formats() {
      return childrenModule.basicInfoFormats;
    },
  },

  data: (): DataType =>({
    childId: NaN,
    childName: '',
    fetchLoading: false,
    btnLoading: false,
    answers: [],
  }),

  methods: {
    routerBack() {
      this.$router.replace('/children', () => {});
    },
    async clickPost() {
      if (this.form.validate()) {
        this.btnLoading = true;
        try {
          let answers = this.answers;
          answers = answers.map((a) => {
            if (Array.isArray(a.value)) {
              a.value = a.value.filter(Boolean);
            }
            return a;
          });
          const response = await childrenModule.postChildrenInfo({
            childId: String(this.childId),
            answers,
          });
          this.btnLoading = false;
          Toast.fire({
            icon: 'success',
            title: '登録が完了しました',
          });
        } catch (error) {
          this.btnLoading = false;
        }
      }
    },
    inputValue({ format, value }: {
      format: ChildInfoFormat;
      value: string;
    }) {
      this.answers.map((a) => {
        if (a.key === format.key) {
          a.value = value;
        }
      });
    },
    initInfo() {
      if (childrenModule.childInfo) {
        childrenModule.childInfo.basicInfo.map((info) => {
          this.answers.map((answer) => {
            if (answer.key === info.key) {
              answer.value = info.value;
            }
          });
        });
      }
    },
  },

  async mounted() {
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
      this.fetchLoading = true;
      await Promise.all([
        childrenModule.fetchChildrenInfoFormat(),
        childrenModule.fetchChildrenInfo({
          childId: String(this.childId),
        }),
      ]);
      for (const f of childrenModule.basicInfoFormats) {
        this.answers.push({
          key: f.key,
          value: null,
        });
      }
      this.initInfo();
      this.fetchLoading = false;
    } else {
      this.routerBack();
    }
  },

});
