import ApiClient from '@/api/ApiClient';
import {
  HomeChildInfoFormatResponse,
  HomeChildInfoResponse,
} from '@API/src/component/home/child/types';
import {
  ChildResponse,
} from '@API/src/component/home/family/types';
import {
  FetchChildrenInfoRequest,
  PostChildrenInfoRequest,
  PutChildrenRequest,
} from '@/model/Children';
import {
  isTypeHomeChildInfoFormatResponse,
  isTypeHomeChildInfoResponse,
  isTypeChildResponseResponse,
} from '@/model/TypeGuard/Children';

export default class ApiChildren {

  public static async fetchChildrenInfoFormat(): Promise<HomeChildInfoFormatResponse> {
    try {
      const response = await ApiClient.get(`/children-info-format`, null);
      if (isTypeHomeChildInfoFormatResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchChildrenInfo(
    request: FetchChildrenInfoRequest,
  ): Promise<HomeChildInfoResponse> {
    try {
      const response = await ApiClient.get(`/children-info/${request.childId}`, request);
      if (isTypeHomeChildInfoResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postChildrenInfo(
    request: PostChildrenInfoRequest,
  ): Promise<HomeChildInfoResponse> {
    try {
      const response = await ApiClient.post(`/children-info/${request.childId}`, request);
      if (isTypeHomeChildInfoResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async putChildren(
    request: PutChildrenRequest,
  ): Promise<ChildResponse> {
    try {
      const response = await ApiClient.put(`/children/${request.childId}`, request);
      if (isTypeChildResponseResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
