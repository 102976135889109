import ApiClient from '@/api/ApiClient';
import {
  isTypePaymentMethodListResponse,
  isTypePaymentMethodCardListResponse,
} from '@/model/TypeGuard/Payment';
import {
  PaymentMethodCardResponse,
  PaymentMethodCardListResponse,
  PaymentMethodResponse,
  PaymentMethodListResponse,
} from '@API/src/component/home/payment/types';
import {
  FetchPaymentMethodRequest,
  PutPaymentMethodRequest,
  PostPaymentMethodCardRequest,
  DeletePaymentMethodCardRequest,
  FetchPaymentMethodCardRequest,
} from '@/model/Payment';

export default class ApiAttachment {

  public static async fetchPaymentMethod(request: FetchPaymentMethodRequest): Promise<PaymentMethodListResponse> {
    try {
      const response = await ApiClient.get(`/payment-method`, request);
      if (isTypePaymentMethodListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async putPaymentMethod(request: PutPaymentMethodRequest): Promise<PaymentMethodListResponse> {
    try {
      const response = await ApiClient.put(`/payment-method`, request);
      if (isTypePaymentMethodListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postPaymentMethodCard(request: PostPaymentMethodCardRequest): Promise<PaymentMethodCardListResponse> {
    try {
      const response = await ApiClient.post(`/payment-method/card`, request);
      if (isTypePaymentMethodCardListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async deletePaymentMethodCard(request: DeletePaymentMethodCardRequest): Promise<PaymentMethodCardListResponse> {
    try {
      const response = await ApiClient.delete(`/payment-method/card`, request);
      if (isTypePaymentMethodCardListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchPaymentMethodCard(request: FetchPaymentMethodCardRequest): Promise<PaymentMethodCardListResponse> {
    try {
      const response = await ApiClient.get(`/payment-method/card`, request);
      if (isTypePaymentMethodCardListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
