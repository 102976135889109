
import Vue, { onUpdated } from 'vue';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import ApiVersioning from '@/api/ApiVersioning';
import { when } from '@/service/Switch';

import { meModule } from '@/store/entityModules/me';
import { attendanceModule } from '@/store/entityModules/attendance';
import { familyModule } from '@/store/entityModules/family';
import { feedModule } from '@/store/entityModules/feed';
import { pusherModule } from '@/store/entityModules/pusher';
import { auth0Module } from '@/store/entityModules/auth0';
import { VueLoading } from 'vue-loading-template';

// components
import ChildrenHeader from '@/components/organisms/childrenHeader.vue';
import AbsenceModal from '@/components/modal/AbsenceModal.vue';
import PickupModal from '@/components/modal/PickupModal.vue';

// model
import {
  FetchFeedsRequest,
  PostFeedReadRequest,
} from '@/model/Feed';
import { FeedResponse } from '@API/src/component/home/feed/types';
import LocalDataService from '../service/LocalDataServices';

enum ModalType {
  INFORMATION = 'information',
  ABSENCE = 'absence',
  PICKUP = 'pickup',
}

enum FeedType {
  ABSENT_CONFIRMED = 'absentConfirmed',
  PICKUP_CONFIRMED = 'pickupConfirmed',
  CHAT_RECEIVED = 'chatReceived',
  EVENT_CREATED = 'eventCreated',
  EVENT_UPDATED = 'eventUpdated',
  EVENT_DELETED = 'eventDeleted',
}

interface DataType {
  selectedChildId: number;
  selectedChildName: string;
  fromDate: string;
  toDate: string;
  fab: boolean;
  isSelectChildDialog: boolean;
  modalType: ModalType;
  isAttendanceDialog: boolean;
  isAbsenceDialog: boolean;
  isPickupDialog: boolean;
  isFetchMoreFeed: boolean;
  isNewInformation: boolean;
  fetchLoading: boolean;
}

export default Vue.extend({
  name: 'Home',
  components: {
    ChildrenHeader,
    AbsenceModal,
    PickupModal,
    VueLoading,
  },

  data: (): DataType => ({
    selectedChildId: NaN,
    selectedChildName: '',
    fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
    fab: false,
    isSelectChildDialog: false,
    modalType: ModalType.ABSENCE,
    isAttendanceDialog: false,
    isAbsenceDialog: false,
    isPickupDialog: false,
    isFetchMoreFeed: false,
    isNewInformation: false, 
    fetchLoading: false,
  }),

  computed: {
    children() {
      if (familyModule.family) {
        return familyModule.family.children;
      }
      return [];
    },
    selectChildOpt() {
      if (familyModule.family) {
        const children = familyModule.family.children;
        const opt = children.filter((c) => {
          let leaveDate: string | null = null;
          if (c.graduationDate && c.quitDate) {
            if (dayjs(c.graduationDate).isAfter(c.quitDate)) {
              leaveDate = c.quitDate;
            } else {
              leaveDate = c.graduationDate;
            }
          } else if (c.graduationDate) {
            leaveDate = c.graduationDate;
          } else if (c.quitDate) {
            leaveDate = c.quitDate;
          }
          const isQuit = !!leaveDate && dayjs(leaveDate).isBefore(dayjs());
          if (!isQuit) {
            return c;
          }
        });
        return opt;
      }
      return [];
    },
    feeds() {
      const feed = feedModule.feeds;
      if (this.selectedChildId) {
        return feed.filter((f) => {
          return f.child.childId === this.selectedChildId;
        });
      } else {
        return feedModule.feeds;
      }
    },
    feedsByDate() {
      const arr: Array<{
        [key: string]: FeedResponse[];
      }> = [];
      for (let i = 0; i < 100; i++) {
        const obj: {[key: string]: FeedResponse[]} = {};
        const date = dayjs().add((-1 * i), 'd').format('YYYY-MM-DD');
        obj[`${date}`] = this.feeds.filter((f) => {
          if (
            f.createdAt &&
            dayjs(f.createdAt).format('YYYY-MM-DD') === date
          ) { return f; }
        });
        arr.push(obj);
      }
      return arr;
    },
    today(): string {
      return dayjs().locale('ja').format('M月D日');
    },
    qrCode(): string {
      return attendanceModule.qrCode;
    },
    me() {
      return meModule.me;
    },
  },

  methods: {
    async fetchFeed(
      childId: number | null = null,
    ) {
      this.isFetchMoreFeed = true;
      const response = await feedModule.fetchFeed({
        childId,
        fromDate: this.fromDate,
        toDate: this.toDate,
      });
      feedModule.setFeed(response.feeds);
      this.isFetchMoreFeed = false;
      const user = meModule.me
      if(user) {
            console.log(user.isNewinfo)
            this.isNewInformation = user.isNewinfo
      }
    },

    async fetchMoreFeed() {
      const currentFromDate = this.fromDate;
      this.toDate = dayjs(currentFromDate).add(-1, 'd').format('YYYY-MM-DD');
      this.fromDate = dayjs(this.toDate).add(-100, 'd').format('YYYY-MM-DD');
      this.isFetchMoreFeed = true;
      const response = await feedModule.fetchFeed({
        childId: null,
        fromDate: this.fromDate,
        toDate: this.toDate,
      });
      feedModule.addFeed(response.feeds);
      this.isFetchMoreFeed = false;
    },

    selectChild(childId: number) {
      if (this.selectedChildId === childId) {
        this.selectedChildId = NaN;
      } else {
        this.selectedChildId = childId;
      }
    },

    async beforeOpenModal(modalType: ModalType) {
      this.modalType = modalType;
      // this.isSelectChildDialog = true
      // return
      if (this.children.length === 1) {
        this.selectedChildId = this.children[0].childId;
        this.openModal();
      } else if (this.modalType === 'information') {
          this.fetchLoading = true;
          this.isNewInformation = false;
          await this.putMe()
          await this.fetchFeed()
          this.fetchLoading = false;
          this.externalLink()
      } else {
        this.isSelectChildDialog = true;
      }
    },

    openModal(childId: number | null = null, childName: string = '') {
      if (childId) {
        this.selectedChildId = childId;
        this.selectedChildName = childName;
      }
      when(this.modalType)
        .on((v) => v === 'information', async () => {
          this.fetchLoading = true;
          this.isNewInformation = false;
          await this.putMe()
          await this.fetchFeed()
          this.fetchLoading = false;
          this.externalLink()
        })
        .on((v) => v === 'absence', () => {
          this.isSelectChildDialog = false;
          this.isAbsenceDialog = true;
        })
        .on((v) => v === 'pickup', () => {
          this.isSelectChildDialog = false;
          this.isPickupDialog = true;
        });
    },

    externalLink() {
      const url = 'https://ccs-home-manual.super.site/%e3%83%aa%e3%83%aa%e3%83%bc%e3%82%b9%e6%83%85%e5%a0%b1'
      window.location.href = url
    },
    async putMe() {
      if (this.me) {
        try {
          await meModule.putMe({
            firstName: this.me.firstName,
            lastName: this.me.lastName,
            firstNameKana: this.me.firstNameKana,
            lastNameKana: this.me.lastNameKana,
            imageUrl: this.me.imageUrl,
            birth: this.me.birth,
            bloodType: this.me.bloodType,
            tel: this.me.tel,
            notifications: [],
            isNewinfo: this.isNewInformation,
          });
        } catch {}
      }
    },

    clickFeed(feed: FeedResponse) {
      feedModule.postFeed({ feedId: feed.feedId });
      when(feed.feedType)
        .on((v) => v === FeedType.ABSENT_CONFIRMED || v === FeedType.PICKUP_CONFIRMED || v === FeedType.CHAT_RECEIVED, () => {
          const date = feed.date;
          const createdAt = feed.createdAt;
          this.$router.push({ path: '/chat', name: 'Chat', query: {
            childId: String(feed.child.childId),
            childName: feed.child.lastName + feed.child.firstName,
            date: date? date : createdAt,
          }});
        })
        .on((v) => v === FeedType.EVENT_CREATED || v === FeedType.EVENT_UPDATED, () => {
          this.$router.push({ path: '/calendar', query: {
            childId: String(feed.child.childId),
            childName: feed.child.lastName + feed.child.firstName,
          }});
        });
    },

    formatMinutes(time: string): string {
      return dayjs(time).format('HH:mm');
    },

    async initBeams() {
      try {
        const sessionAndToken = LocalDataService.getBeamsTokenAndId();
        if ((window as any).webkit && (window as any).webkit.messageHandlers !== undefined) {
          (window as any).webkit.messageHandlers.pusher.postMessage(`${sessionAndToken}`);
        }
        /*  Pusher */
        const url = process.env.VUE_APP_API_URL;
        const instanceId = process.env.VUE_APP_BEAMS_INSTANCE_ID;
        const apiKey = process.env.VUE_APP_X_API_KEY;
        const accessToken = await auth0Module.getAuth0Token();
        const user = meModule.me

        if (instanceId && apiKey && user) {
          const currentUserId = `${user.parentId}`;

          const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
            url: url + '/push/auth',
            queryParams: {
              user_id: currentUserId,
            },
            headers: {
              'Authorization' : `Bearer ${accessToken}`,
              'Content-Type': 'application/json; charset=utf-8',
              'x-api-key': apiKey,
            },
          });
          window.navigator.serviceWorker.ready.then(async (serviceWorkerRegistration) => {
            const beamsClient = new PusherPushNotifications.Client({
              instanceId,
              serviceWorkerRegistration,
            });
            beamsClient
              .start()
              .then(() => beamsClient.getDeviceId())
              .then((deviceId) => {
                console.log('Device ID:', deviceId);
              })
              .then(() => beamsClient.getDeviceInterests())
              .then(async (interests) => {
                await Promise.all(
                  interests.map((interest) => {
                    // 同じ端末で違うアカウントでログインしていた場合、そのsubscribeが残っているため、解除する
                    if (interest !== `FAM-${currentUserId}` && interest !== `debug-FAM-${currentUserId}`) {
                      beamsClient.removeDeviceInterest(interest);
                    }
                  }),
                );
                if (!interests.includes(`FAM-${currentUserId}`)) {
                  await beamsClient.addDeviceInterest(`FAM-${currentUserId}`);
                }
                if (!interests.includes(`debug-FAM-${currentUserId}`)) {
                  // debug console用のsubscribe
                  await beamsClient.addDeviceInterest(`debug-FAM-${currentUserId}`);
                }
              })
              .then(() => beamsClient.getDeviceInterests())
              .then((interest) => {
                console.log('PUSHER BEAMS Interest:', interest);
              })
              .then(() => beamsClient.setUserId(currentUserId, beamsTokenProvider))
              .then(() => beamsClient.getUserId())
              .then((userId) => {
                console.log('PUSHER BEAMS userId:', userId);
              })
              .catch((error) => {
                console.log('error', error);
              });
            });

        }
      } catch (e) {}
    },
  },

  async mounted() {
    ApiVersioning.checkVersion();
    this.fetchFeed();
    this.initBeams();
  },
});
