import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiInvoice from '@/api/ApiInvoice';
import {
  FetchInvoicesRequest,
} from '@/model/Invoice';
import {
  InvoiceInfo,
  InvoiceResponse,
} from '@API/src/component/home/invoice/types';

export interface InvoiceState {
  selectedChildId: number | null;
  selectedInvoice: InvoiceInfo | null;
  invoices: InvoiceInfo[];
  total: number;
}

@Module({ dynamic: true, store, name: 'invoice', namespaced: true })
class InvoiceModule extends VuexModule implements InvoiceState {

  public selectedChildId: number | null = null;
  public selectedInvoice: InvoiceInfo | null = null;
  public invoices: InvoiceInfo[] = [];
  public total: number = 0;

  @Action({ rawError: true })
  public async fetchInvoices(request: FetchInvoicesRequest) {
    try {
      const response = await ApiInvoice.fetchInvoices(request);
      this.setInvoices(response.invoices);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setInvoices(invoices: InvoiceInfo[]) {
    this.invoices = invoices;
  }

  @Mutation
  public selectInvoice(invoice: InvoiceInfo) {
    this.selectedInvoice = invoice;
  }
}

export const invoiceModule = getModule(InvoiceModule);
