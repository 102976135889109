
import Vue from 'vue';

export default Vue.extend({
  name: 'maintenance',

  data: () => ({
    logoImg: require('@/assets/logo.png'),
  }),

  computed: {
    maintenanceMessage(): string {
      const message = process.env.VUE_APP_MANTENANCE_MESSAGE;
      if (message) {
        return message;
      }
      return '';
    },
  },
});
