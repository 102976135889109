
import Vue from 'vue';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { when } from '@/service/Switch';

import { dailyReportModule } from '@/store/entityModules/dailyReport';

import {
  DefecationStatus,
  DefecationStatusType,
} from '../../../../node_modules/chaild-entity/src/component/home/defecation/types';
import { DefecationResponse } from '@API/src/component/home/defecation/types';
import {
  SwipeList,
  SwipeOut,
} from 'vue-swipe-actions';
import { Toast } from '@/service/SweetAlert';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { SwipeListModel } from '@/model/SwipeList/SwipeList';

interface DataType {
  loading: false;
}

export default Vue.extend({

  components: {
    SwipeOut,
    SwipeList,
  },

  props: {
    defecations: {
      type: Array,
      default: () => ([]),
    },
  },

  computed: {
    swipeList(): SwipeListModel {
      return (this.$refs as any).defecation_swipe_list;
    },
  },

  data: (): DataType =>({
    loading: false,
  }),

  methods: {
    minuteFormat(iso: string) {
      return dayjs(iso).format('HH:mm');
    },
    revealFirstRight(index, revealed) {
      if (!revealed) {
        this.swipeList.revealRight(index);
      } else {
        this.swipeList.closeActions(index);
      }
    },
    revealFirstLeft() {
      this.swipeList.revealLeft(0);
    },
    closeFirst() {
      this.swipeList.closeActions(0);
    },
    closeAll() {
      this.swipeList.closeActions();
    },
    getDefecationState(state: DefecationStatusType): string {
      return when(state)
        .on((v) => v === DefecationStatus.hard, () => '硬い')
        .on((v) => v === DefecationStatus.normal, () => '普通')
        .on((v) => v === DefecationStatus.soft, () => '軟らかい')
        .otherwise(() => '');
    },
    async deleteItem(e: DefecationResponse) {
      Swal.fire({
        title: '記録を削除しますか?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF3F3A',
        cancelButtonColor: '#BDBDBD',
        confirmButtonText: '削除',
        cancelButtonText: 'キャンセル',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.showLoading();
          await dailyReportModule.deleteDefecation({ defecationId: e.defecationId });
          Toast.fire({
            icon: 'success',
            title: '削除が完了しました',
          });
        }
      });
    },
    itemClick(e) {},
    fbClick(e) {},
    sbClick(e) {},
  },

});
