
import Vue from 'vue';

interface DataType {
  show: boolean;
}

export default Vue.extend({
  name: 'chatMenu',

  data(): DataType {
    return {
      show: true,
    };
  },

  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
});
