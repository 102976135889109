import { auth0Module } from '@/store/entityModules/auth0';
import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiPusher from '@/api/ApiPusher';
import dayjs from 'dayjs';

import {
  PrivateChannelAuthResponse,
  PushAuthResponse,
} from '@API/lib';
import LocalDataService from '@/service/LocalDataServices';
import Pusher from 'pusher-js';

import { chatModule } from '@/store/entityModules/chat';
import { feedModule } from '@/store/entityModules/feed';
import {
  HomeChatResponse,
} from '@API/src/component/home/chat/types';
import {
  isHomeChatResponse,
} from '@/model/TypeGuard/Chat';

export interface PusherState {
}

const apiKey = process.env.VUE_APP_PUSHER_KEY;
const url = process.env.VUE_APP_X_API_KEY;
const instanceId = process.env.VUE_APP_BEAMS_INSTANCE_ID;

@Module({ dynamic: true, store, name: 'pusher', namespaced: true })
class PusherModule extends VuexModule implements PusherState {

  @Action
  public async subscribe() {
    // Pusher.logToConsole = true; // 👈 Enable pusher logging - don't include this in production

    const authorizer = (ch, options) => {
      return {
        authorize: (socketId, callback) => {
          ApiPusher
            .publishPrivateChanel({
              user_id: meModule.me? String(meModule.me.parentId) : '',
              socketId,
            })
            .then((res) => {
              return res;
            })
            .then((data) => {
              callback(null, data);
            })
            .catch((err) => {
              callback(new Error(`Error calling auth endpoint: ${err}`), {
                auth: '',
              });
            });
        },
      };
    };
    console.log('authorizer', authorizer);
    const accessToken = await auth0Module.getAuth0Token();
    if (apiKey && accessToken) {
      const pusher: any = new Pusher(apiKey, {
        cluster: 'ap3',
        authorizer,
        authEndpoint: url + '/private-channel/auth',
        auth: {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=utf-8',
            'x-api-key': apiKey,
          },
        },
      });
      console.log('pusher', pusher);

      const account = meModule.me;
      console.log('account', account);
      if (account) {
        const channel = pusher.subscribe(`private-home-${account.parentId}`);
        // const channel = pusher.subscribe(`private-home-`);
        console.log('PUSHER channel', channel);
        channel.bind('pusher:subscription_succeeded', (data) => {
          console.log('pusher:subscription_succeeded', data);
        });

        // 欠席連絡が確認されたとき
        // eventName: absentConfirmed
        channel.bind('absentConfirmed', (data) => {
          console.log('absentConfirmed', data);
          if (isHomeChatResponse(data)) {
            chatModule.addChat(data);
            feedModule.fetchFeed({
              childId: null,
              fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
              toDate:dayjs().format('YYYY-MM-DD'),
            });
          }
        });

        // 送迎変更が確認されたとき
        // eventName: pickupConfirmed
        channel.bind('pickupConfirmed', (data) => {
          console.log('pickupConfirmed', data);
          if (isHomeChatResponse(data)) {
            chatModule.addChat(data);
            feedModule.fetchFeed({
              childId: null,
              fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
              toDate:dayjs().format('YYYY-MM-DD'),
            });
          }
        });

        // チャットを受け取ったとき
        // eventName: chatReceived
        channel.bind('chatReceived', (data) => {
          console.log('chatReceived', data);
          if (isHomeChatResponse(data)) {
            chatModule.addChat(data);
            feedModule.fetchFeed({
              childId: null,
              fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
              toDate:dayjs().format('YYYY-MM-DD'),
            });
          }
        });

        // チャットが削除された時
        // eventName: chatDeleted
        channel.bind('chatDeleted', (data) => {
          console.log('chatDeleted', data);
          if (isHomeChatResponse(data)) {
            chatModule.removeChat(data.chatId);
            feedModule.fetchFeed({
              childId: null,
              fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
              toDate:dayjs().format('YYYY-MM-DD'),
            });
          }
        });

        // 年間行事が登録された時
        // eventName: eventCreated
        channel.bind('eventCreated', (data) => {
          console.log('eventCreated', data);
          feedModule.fetchFeed({
            childId: null,
            fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
            toDate:dayjs().format('YYYY-MM-DD'),
          });
        });

        // 年間行事が更新された時
        // eventName: eventUpdated
        channel.bind('eventUpdated', (data) => {
          console.log('eventUpdated', data);
          feedModule.fetchFeed({
            childId: null,
            fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
            toDate:dayjs().format('YYYY-MM-DD'),
          });
        });

        // 年間行事が削除された時
        // eventName: eventDeleted
        channel.bind('eventDeleted', (data) => {
          console.log('eventDeleted', data);
          feedModule.fetchFeed({
            childId: null,
            fromDate: dayjs().add(-100, 'd').format('YYYY-MM-DD'),
            toDate:dayjs().format('YYYY-MM-DD'),
          });
        });

      }
    }
  }
}


export const pusherModule = getModule(PusherModule);
