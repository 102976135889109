
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import TimePicker from '@/components/molecules/TimePicker.vue';
import { dailyReportModule } from '@/store/entityModules/dailyReport';

// model
import { VForm } from '@/model/Vuetify/VForm';

interface DataType {
  from: string;
  to: string;
  isFromTimePicker: boolean;
  isToTimePicker: boolean;
  btnLoading: boolean;
}

export default Vue.extend({
  name: 'SleepModal',
  components: {
    TimePicker,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {

      return (this.$refs as any).post_sleep_form;
    },
  },

  watch: {
    isOpen: {
      handler(val: boolean) {
        if (val) {
          this.from = dayjs().format('HH:00');
          this.to = dayjs().format('HH:00');
        }
      },
      deep: true,
    },
  },

  data: (): DataType =>({
    from: dayjs().format('HH:00'),
    to: dayjs().format('HH:00'),
    isFromTimePicker: false,
    isToTimePicker: false,
    btnLoading: false,
  }),

  methods: {
    allowedStep: (m) => m % 5 === 0,
    close() {
      this.$emit('close');
    },
    async clickPost() {
      if (this.form.validate()) {
        this.btnLoading = true;
        try {
          const fromHour = this.from.split(':')[0];
          const fromMinutes = this.from.split(':')[1];
          const toHour = this.to.split(':')[0];
          const toMinutes = this.to.split(':')[1];
          await dailyReportModule.postSleep({
            childId: this.childId,
            date: this.selectedDate,
            from: String(dayjs().hour(Number(fromHour)).minute(Number(fromMinutes))),
            to: String(dayjs().hour(Number(toHour)).minute(Number(toMinutes))),
          });
          this.$emit('posted');
          this.$emit('close');
          this.btnLoading = false;
        } catch (error) {
          this.$emit('close');
          this.btnLoading = false;
        }
      }
    },
  },
});
