
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import Swal from 'sweetalert2';
import { Toast } from '@/service/SweetAlert';
import rules from '@/service/ValidRules';
import { VueLoading } from 'vue-loading-template';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';
import { resourceModule } from '@/store/entityModules/resource';

import ChildrenField from '@/components/organisms/ChildrenField.vue';

import { VForm } from '@/model/Vuetify/VForm';
import { ChildInfoFormat } from 'chaild-entity/src/component/nursery/child/types';

import parentTypes from '@/service/parentTypes';
import { ParentResponse } from 'chaild-entity/lib';

import { ParentMeResponse } from '@API/src/component/home/me/types';
import DeleteModal from '@/components/modal/DeleteModal.vue';

interface DataType {
  birthDate: string | null;
  isBirthPicker: boolean;
  imageFile: File | null;
  pickedImg: string;
  isDrag: string;
  bloodTypeOptions: Array<{ text: string; value: string | null }>;
  firstName: string | null;
  lastName: string | null;
  firstNameKana: string | null;
  lastNameKana: string | null;
  imageUrl: string | null;
  birth: string | null;
  bloodType: string | null;
  tel: string | null;
  parentType: string;
  btnLoading: boolean;
  loading: boolean;
  isDeleteDialog: boolean;
  isNewinfo: boolean;
}

export default Vue.extend({
  components: {
    ChildrenField,
    VueLoading,
    DeleteModal,
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).put_me_form;
    },
    me() {
      return meModule.me;
    },
    family() {
      return familyModule.family;
    },
    parentInfo(): ParentResponse | null {
      if (this.me && this.family) {
        const index = this.family.parents.findIndex((p) => {
          if (this.me && p.parentId === this.me.parentId) {
            return true;
          }
        });
        const f = this.family.parents[index];
        return f;
      }
      return null;
    },
    parentTypes() {
      return parentTypes;
    },
  },

  watch: {
    isBirthPicker(val) {
      setTimeout(() => ((this.$refs as any).picker.activePicker = 'YEAR'));
    },
  },

  data: (): DataType => ({
    birthDate: null,
    isBirthPicker: false,
    imageFile: null,
    pickedImg: '',
    isDrag: '',
    bloodTypeOptions: [
      {
        text: 'A型',
        value: 'a',
      },
      {
        text: 'B型',
        value: 'b',
      },
      {
        text: 'O型',
        value: 'o',
      },
      {
        text: 'AB型',
        value: 'ab',
      },
      {
        text: '不明',
        value: null,
      },
    ],
    firstName: '',
    lastName: '',
    firstNameKana: null,
    lastNameKana: null,
    imageUrl: null,
    birth: null,
    bloodType: null,
    tel: null,
    parentType: '',
    btnLoading: false,
    loading: false,
    isDeleteDialog: false,
    isNewinfo: false,
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting/family', () => {});
    },
    onImagePicked(uploadImageUrl: File | null) {
      this.imageFile = uploadImageUrl;
    },
    pickFile() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.click();
    },
    checkDrag(event: any, key: string, status: boolean) {
      if (status && event.dataTransfer.types === 'text/plain') {
        return false;
      }
      this.isDrag = status ? key : '';
    },
    async onDrop(event: any, key = '', image = {}) {
      this.isDrag = '';
      const fileList = event.target.files
        ? event.target.files
        : event.dataTransfer.files;
      if (fileList.length === 0) {
        return false;
      }
      const file = fileList[0];
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        this.pickedImg = fr.result as string;
      });
      const response = await resourceModule.postResource({
        imageType: 'profile',
        contentType: file.contentType,
        file,
        childId: null,
      });
      if (response) {
        const url = response.split('?')[0];
        this.imageUrl = url;
        this.imageFile = file;
      }
    },
    saveBirthDate(date) {
      (this.$refs as any).birthPickerMenu.save(date);
      this.birth = dayjs(date).format('YYYY-MM-DD');
    },
    async putMe() {
      if (this.form.validate()) {
        try {
          const response = await meModule.putMe({
            firstName: this.firstName,
            lastName: this.lastName,
            firstNameKana: this.firstNameKana,
            lastNameKana: this.lastNameKana,
            imageUrl: this.imageUrl,
            birth: this.birth,
            bloodType: this.bloodType,
            tel: this.tel,
            notifications: [],
            isNewinfo: this.isNewinfo,
          });
          return response;
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    async delMe() {
      try {
        const response = await meModule.delMe({
          notifications: [],
        });
        return response;
      } catch (error) {
        return false;
      }
    },
    async putFamilyById() {
      if (this.form.validate() && this.family) {
        const editedParent = {
          parentId: '',
          parentType: this.parentType,
        };
        if (this.parentInfo) {
          editedParent.parentId = String(this.parentInfo.parentId);
        }
        try {
          const response = await familyModule.putFamilyById({
            familyId: String(this.family.familyId),
            parents: [editedParent],
          });
          return response;
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    async del(){
      try {
        await Promise.all([this.delMe(), this.putFamilyById()]);
      } catch (error) {
      }
    },
    async put() {
      this.btnLoading = true;
      if (this.form.validate()) {
        try {
          await Promise.all([this.putMe(), this.putFamilyById()]);
          Toast.fire({
            icon: 'success',
            title: '登録が完了しました',
          });
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
        }
      } else {
        this.btnLoading = false;
      }
    },
  },

  async mounted() {
    const familyId = this.me?.families[0].familyId;
    if (familyId) {
      this.loading = true;
      await familyModule.fetchFamilyById({ familyId });
      this.loading = false;
    }
    if (this.me) {
      this.firstName = this.me.firstName;
      this.lastName = this.me.lastName;
      this.firstNameKana = this.me.firstNameKana;
      this.lastNameKana = this.me.lastNameKana;
      this.imageUrl = this.me.imageUrl;
      this.pickedImg = this.me.imageUrl;
      this.birth = this.me.birth;
      this.bloodType = this.me.bloodType;
      this.tel = this.me.tel;
      this.isNewinfo = this.me.isNewinfo;
    }
    if (this.me && this.family && this.parentInfo) {
      this.parentType = this.parentInfo.parentType;
    }
  },
});
