import ApiClient from '@/api/ApiClient';
import {
  QrCodeResponse,
  HomeAttendanceSchema,
} from '@API/src/component/home/attendance/types';
import {
  PostQrRequest,
  FetchAttendanceRequest,
} from '@/model/Attendance';
import {
  isTypeQrCodeResponse,
  isTypeHomeAttendanceSchemaResponse,
} from '@/model/TypeGuard/Attendance';

export default class ApiAttendance {

  public static async postQr(
    request: PostQrRequest,
  ): Promise<QrCodeResponse> {
    try {
      const response = await ApiClient.post(`/children/${request.childId}/attendance/qr`, request);
      if (isTypeQrCodeResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchAttendance(
    request: FetchAttendanceRequest,
  ): Promise<HomeAttendanceSchema> {
    try {
      const response = await ApiClient.get(`/children/${request.childId}/attendance`, request);
      if (isTypeHomeAttendanceSchemaResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

}
