import {
  YearlyEventBulkResponseForHome,
  YearlyEventIncludingChildrenResponseForHome,
} from '@API/src/component/home/yearlyEvent/types';


export const isTypeYearlyEventIncludingChildrenResponseForHome = (
  arg: any,
): arg is YearlyEventIncludingChildrenResponseForHome =>
  arg !== null &&
  typeof arg === 'object' &&
  typeof arg.eventId === 'number' &&
  typeof arg.nurseryId === 'number' &&
  typeof arg.eventName === 'string' &&
  typeof arg.date === 'string' &&
  typeof arg.createdAt === 'string';

export const isTypeYearlyEventBulkResponseForHome = (
  arg: any,
): arg is YearlyEventBulkResponseForHome =>
  arg !== null &&
  typeof arg === 'object' &&
  Array.isArray(arg.yearlyEvents) &&
  (arg.yearlyEvents.length === 0 || isTypeYearlyEventIncludingChildrenResponseForHome(arg.yearlyEvents[0]));
