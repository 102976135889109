
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import { dailyReportModule } from '@/store/entityModules/dailyReport';

import { VForm } from '@/model/Vuetify/VForm';

interface DataType {
  stateOfChild: string;
  draftLoading: boolean;
  sendLoading: boolean;
}

export default Vue.extend({
  name: 'StateOfChildForm',

  props: {
    isDraft: {
      type: Boolean,
      default: true,
    },
    body: {
      type: String,
      default: '',
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).put_report_form;
    },
  },

  watch: {
    body: {
      handler(val: string) {
        if (val) {
          this.stateOfChild = this.body;
        }
      },
      deep: true,
    },
  },

  data: (): DataType => ({
    stateOfChild: '',
    draftLoading: false,
    sendLoading: false,
  }),

  methods: {
    async clickPost({ isDraft }: { isDraft: boolean }) {
      if (this.form.validate() && this.stateOfChild) {
        if (isDraft) {
          this.draftLoading = true;
        } else {
          this.sendLoading = true;
        }
        try {
          await dailyReportModule.putReport({
            childId: this.childId,
            date: this.selectedDate,
            stateOfChild: {
              isDraft: isDraft,
              body: this.stateOfChild,
            },
          });
          this.$emit('posted');
          this.draftLoading = false;
          this.sendLoading = false;
        } catch (error) {
          this.draftLoading = false;
          this.sendLoading = false;
        }
      }
    },
  },

  mounted() {
    this.stateOfChild = this.body;
  },
});
