
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { Toast } from '@/service/SweetAlert';

import { parentModule } from '@/store/entityModules/parent';

import { VueLoading } from 'vue-loading-template';
import ChildrenField from '@/components/organisms/ChildrenField.vue';

import { VForm } from '@/model/Vuetify/VForm';
import { ChildInfoFormat } from 'chaild-entity/src/component/nursery/child/types';

interface DataType {
  childId: number;
  childName: string;
  fetchLoading: boolean;
  btnLoading: boolean;
  answers: Array<{
    key: string;
    value: any | null;
  }>;
}

export default Vue.extend({

  components: {
    VueLoading,
    ChildrenField,
  },

  computed: {
    form(): VForm {
      return (this.$refs as any).post_parentinfo_form;
    },
    formats() {
      return parentModule.formats;
    },
  },

  data: (): DataType => ({
    childId: NaN,
    childName: '',
    fetchLoading: false,
    btnLoading: false,
    answers: [],
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting', () => {});
    },
    async clickPost() {
      if (this.form.validate()) {
        this.btnLoading = true;
        try {
          let answers = this.answers;
          answers = answers.map((a) => {
            if (Array.isArray(a.value)) {
              a.value = a.value.filter(Boolean);
            }
            return a;
          });
          const response = await parentModule.postParentInfo({
            answers,
          });
          this.btnLoading = false;
          Toast.fire({
            icon: 'success',
            title: '保存が完了しました',
          });
        } catch (error) {
          this.btnLoading = false;
        }
      }
    },
    inputValue({ format, value }: {
      format: ChildInfoFormat;
      value: string;
    }) {
      this.answers.map((a) => {
        if (a.key === format.key) {
          a.value = value;
        }
      });
    },
    initInfo() {
      if (parentModule.parentInfo) {
        parentModule.parentInfo.map((info) => {
          this.answers.map((answer) => {
            if (answer.key === info.key) {
              answer.value = info.value;
            }
          });
        });
      }
    },
  },

  async mounted() {
    // if (parentModule.formats.length === 0 && !parentModule.parentInfo) {
    //   this.fetchLoading = true;
    //   await Promise.all([
    //     parentModule.fetchParentInfoFormat(),
    //     parentModule.fetchParentInfo(),
    //   ]);
    //   this.fetchLoading = false;
    // } else if (parentModule.formats.length === 0) {
    //   this.fetchLoading = true;
    //   await parentModule.fetchParentInfoFormat();
    //   this.fetchLoading = false;
    // } else if (!parentModule.parentInfo) {
    //   this.fetchLoading = true;
    //   await parentModule.fetchParentInfo();
    //   this.fetchLoading = false;
    // }
    this.fetchLoading = true;
    await Promise.all([
      parentModule.fetchParentInfoFormat(),
      parentModule.fetchParentInfo(),
    ]);
    for (const f of parentModule.formats) {
      this.answers.push({
        key: f.key,
        value: null,
      });
    }
    this.fetchLoading = false;
    this.initInfo();
  },
});
