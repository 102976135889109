
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import { VueLoading } from 'vue-loading-template';

import { meModule } from '@/store/entityModules/me';
import { familyModule } from '@/store/entityModules/family';
import { invoiceModule } from '@/store/entityModules/invoice';

interface DataType {
  childId: number;
  childName: string;
  fetchLoading: boolean;
  selectedNendo: string;
}

export default Vue.extend({
  components: {
    VueLoading,
  },

  data: (): DataType => ({
    childId: NaN,
    childName: '',
    fetchLoading: false,
    selectedNendo: '',
  }),

  computed: {
    nendoOptions(): string[] {
      const nendoArray: string[] = [];
      let i = 0;
      const month = dayjs().format('MM');
      if (month === '01' || month === '02' || month === '03') {
        i++;
      }
      for (i; i < 6; i++) {
        const nendo = dayjs()
          .add(i * -1, 'year')
          .format('YYYY');
        nendoArray.push(nendo);
      }
      return nendoArray;
    },
    invoices() {
      return invoiceModule.invoices;
    },
    selectedChildImg(): string | null {
      if (familyModule.family) {
        const children = familyModule.family.children;
        const child = children.find((c) => c.childId === this.childId);
        if (!!child) {
          return child.imageUrl;
        }
      }
      return null;
    },
  },

  methods: {
    routerBack() {
      this.$router.replace('/invoice/select', () => {});
    },
    toDetail(index: number) {
      invoiceModule.selectInvoice(invoiceModule.invoices[index]);
      this.$router.push({ path: '/invoice/detail', name: 'InvoiceDetail' });
    },
    createYearMonth({ year, month }: { year: number; month: number }) {
      if (month < 4) {
        return `${year + 1}年${month}月`;
      } else {
        return `${year}年${month}月`;
      }
    },
    async changeNendo() {
      this.fetchLoading = true;
      await invoiceModule.fetchInvoices({
        childId: this.childId,
        year: Number(this.selectedNendo),
      });
      this.fetchLoading = false;
    },
  },

  async mounted() {
    this.selectedNendo = this.nendoOptions[0];
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'number') {
      this.childId = this.$route.query.childId;
      this.fetchLoading = true;
      await invoiceModule.fetchInvoices({ childId: this.childId, year: null });
      this.fetchLoading = false;
    }
    if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
      this.fetchLoading = true;
      await invoiceModule.fetchInvoices({ childId: this.childId, year: null });
      this.fetchLoading = false;
    }
    if (!this.childId || !this.childName) {
      this.$router.replace('/invoice/select', () => {});
    }
  },
});
