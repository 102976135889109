
import Vue from 'vue';
import { when } from '@/service/Switch';
import { Toast } from '@/service/SweetAlert';

import { meModule } from '@/store/entityModules/me';

interface DataType {
  notifications: Array<{
    parentId: number;
    key: string;
    label: string;
    value: number;
  }>;
  options: Array<{
    text: string;
    value: number;
  }>;
  btnLoading: boolean;
}

export default Vue.extend({

  computed: {
    me() {
      return meModule.me;
    },
  },

  data: (): DataType =>({
    notifications: [],
    options: [
      {
        text: '両方',
        value: 0,
      },
      {
        text: 'PUSHのみ',
        value: 1,
      },
      {
        text: 'メールのみ',
        value: 2,
      },
      {
        text: '通知なし',
        value: 3,
      },
    ],
    btnLoading: false,
  }),

  methods: {
    routerBack() {
      this.$router.replace('/setting/various', () => {});
    },
    async putMe() {
      if (this.me) {
        this.btnLoading = true;
        try {
          await meModule.putMe({
            firstName: this.me.firstName,
            lastName: this.me.lastName,
            firstNameKana: this.me.firstNameKana,
            lastNameKana: this.me.lastNameKana,
            imageUrl: this.me.imageUrl,
            birth: this.me.birth,
            bloodType: this.me.bloodType,
            tel: this.me.tel,
            notifications: this.notifications,
            isNewinfo: this.me.isNewinfo,
          });
          this.btnLoading = false;
          Toast.fire({
            icon: 'success',
            title: '登録が完了しました',
          });
        } catch {
          this.btnLoading = false;
        }
      }
    },
  },

  mounted() {
    if (this.me) {
      const parentId = this.me.parentId;
      const keys = Object.keys(this.me.notifications);
      keys.map((k) => {
        let label = '';
        when(k)
          .on((v) => v === 'absentConfirmed', async () => {
            label = '欠席連絡';
          })
          .on((v) => v === 'pickupConfirmed', async () => {
            label = '送迎変更';
          })
          .on((v) => v === 'chatReceived', async () => {
            label = 'チャット';
          })
          .on((v) => v === 'eventCreated', async () => {
            label = '年間行事の登録';
          })
          .on((v) => v === 'eventUpdated', async () => {
            label = '年間行事の更新';
          })
          .on((v) => v === 'growthUpdated', async () => {
            label = '成長記録の更新';
          })
          .on((v) => v === 'invoiceSettled', async () => {
            label = '請求書発行';
          })
          .on((v) => v === 'creditSettled', async () => {
            label = 'クレジットカードへの請求';
          })
          .on((v) => v === 'creditFailed', async () => {
            label = 'クレジットカードへの請求失敗';
          });
        if (this.me && label) {
          this.notifications.push({
            parentId,
            key: k,
            label,
            value: this.me.notifications[k],
          });
        }
      });
    }
  },

});
